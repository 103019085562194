import React from 'react';
import '../../css/questions/questions.css';
import {useState} from 'react';
import AWS from 'aws-sdk';
import { AWS_ACCESS_KEY_ID_CORE, AWS_SECRET_ACCESS_KEY_CORE, AWS_BUCKET_CORE } from '../../settings/aws/index.js';
import { upload } from '../../utils/aws/s3';
import Dynamicore, { SERVICES } from '../../services/dynamicore';
//=================
const REGION ='us-west-2';

AWS.config.update({
    accessKeyId: AWS_ACCESS_KEY_ID_CORE,
    secretAccessKey: AWS_SECRET_ACCESS_KEY_CORE
})

const myBucket = new AWS.S3({
    params: { Bucket: AWS_BUCKET_CORE},
    region: REGION,
})


const UploadButton = (props) =>{

    const {
        settings: {
          route = 'tmp/',
        } = {},
      } = props;
      let { value = [] } = props;
    const {clientId} = props;
    const nameDocument = props;
    const imagestate = props;

    //const [progress , setProgress] = useState(0);
    const [selected, setSelected] = useState(false);
    //const [documents, setDocuments] = useState(value || []);
    const [image, setImage] = useState(false);

    const hiddenFileInput = React.useRef(null);

    const handleClick = e => {
      hiddenFileInput.current.click();
    };

    const handleFileInput = async (e) => {
      setSelected(true);
      setImage(true);
      imagestate = image;
      const newnameDocument = nameDocument.nameDocument;
      const file = e.target.files[0];
      const ext = (file.name.split('.').pop() || '').toLowerCase();
      
      const s3File = await upload(
        `${'company/3047/people/'}${clientId+'/'}${new Date().getTime()}.${String(Math.random()).substring(
          2,
          7,
        )}${ext ? `.${ext.toLowerCase()}` : ''}`,
        file,
      );
      const people = await Dynamicore(SERVICES.PEOPLE).read( 
        {
          id: clientId,
        }
      );
      await Dynamicore(SERVICES.PEOPLE).update( 
        {
          ...people,
          pii: {
            ...people.pii,
            [newnameDocument]: [{
               url: s3File.Location,
               name: newnameDocument,
               active: true,
               uploaded: new Date().getTime(),
              }]
          },
        }
      );
    }

    const handleDelete = (e) => {
      e.target.value = null;
      hiddenFileInput.current.value = null;
      setSelected(false);
      /*const newnameDocument = nameDocument.nameDocument;
      const file = e.target.files[0];
      const ext = (file.name.split('.').pop() || '').toLowerCase();

      const s3File = await upload(
        `${'company/3047/people/'}${clientId+'/'}${new Date().getTime()}.${String(Math.random()).substring(
          2,
          7,
        )}${ext ? `.${ext.toLowerCase()}` : ''}`,
        file,
      );
      const people = await Dynamicore(SERVICES.PEOPLE).read( 
        {
          id: clientId,
        }
      );
      await Dynamicore(SERVICES.PEOPLE).remove( 
        {
          ...people,
          pii: {
            ...people.pii,
            [newnameDocument]: [{
               url: s3File.Location,
               name: newnameDocument,
               active: true,
               uploaded: new Date().getTime(),
              }]
          },
        }
      );
      setSelected(false);*/
    }

    return(
        <div>
            <div className='button-end mt-1'>
                <input 
                    multiple
                    class="d-none" 
                    ref={hiddenFileInput} 
                    type='file' 
                    name='files' 
                    onChange={handleFileInput}
                />
                {selected ? ( 
                    <button className="card-button-sm fs-7 py-2" onClick={handleDelete}>
                    Eliminar
                    </button>
                 ) : ( 
                     <div>
                    <button className="card-button-sm fs-7 py-2" onClick={handleClick}>
                    Seleccionar
                    </button>
                    </div>
                 ) }
                
            </div>
        </div>
    )
}

export default UploadButton;