import { stringify } from 'querystring';

import axios from '../../utils/axios';
import { processResponse } from './functions/response';

let resource;

/**
 * Create a resource
 *
 * @param {object} payload
 * @returns
 */
//async function create(payload) {
//  const response = await axios.post(resource, payload);
//  const { data: { data = [] } } = response;
//  const [values] = Array.isArray(data) ? data : [data];
//
//  return values;
//}
async function create(payload, responseAsGet = true) {
  const response = await axios.post(resource, payload);
  const { data: { data = [], total = 0 } } = response;

  if (responseAsGet) {
    console.log('entra a responseAsGet', responseAsGet);
    return {
      values: data,
      total,
    };
  } else {
    const [values] = Array.isArray(data) ? data : [data];

    return values;
  }
}

/**
 * Read resources
 *
 * @param {object} filters
 * @param {boolean} sort
 * @returns
 */
async function read(filters = {}, sort = false) {
  const qs = stringify(filters);
  const response = await axios.get(qs ? `${resource}?${qs}` : resource);

  return processResponse(response, filters, sort);
}

/**
 * Update a resource
 *
 * @param {object} payload
 * @returns
 */
async function update(payload) {
  const response = await axios.put(resource, payload);
  const { data: { data = [] } } = response;
  const [values] = Array.isArray(data) ? data : [data];

  return values;
}

/**
 * Delete a resource
 *
 * @param {object} filters
 * @param {boolean} inBody
 * @returns
 */
async function remove(filters = {}, inBody = false) {
  const qs = stringify(filters);
  const response = inBody
    ? await axios.delete(resource, { data: filters })
    : await axios.delete(qs ? `${resource}?${qs}` : resource);
  const { data: { data } } = response;
  const [values] = Array.isArray(data) ? data : [data];

  return values;
}

/**
 *
 * @param {string} service
 * @param {object} params
 * @returns
 */
export const Dynamicore = (service, params = {}) => {
  Object
    .entries(params)
    .forEach(function ([param, value]) {
      service = service.replace(`:${param}`, value);
    });

  resource = service;

  return {
    create,
    read,
    update,
    remove,
  };
};
