import React from 'react';
import {useEffect, useState} from 'react';
//import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  //LinearProgress,
  //Table,
  //TableHead,
  //TableBody,
  TableCell,
  //TableRow,
  Typography,
  //makeStyles,
  //TableContainer,
  //Paper,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import useStyles from './styles';
import Dynamicore, { SERVICES } from '../../../../services/dynamicore';
//import { getUserInfo } from '../../../../functions/index.js';
import { getUserInfo } from '../../../../services/dynamicore/processes/index.js';
import { numberFormat } from '../../../../utils/formater.js';

//import Label from '../../../../components/Label/Label.jsx';


function CellSkeleton({ value, children }) {
  const hasValue = value || value === 0;

  return (<TableCell>
    {hasValue
      ? <Typography variant="body2" color="textSecondary">
        {children || value}
      </Typography>
      : <Skeleton animation="wave" variant="text" width={250} />
    }
  </TableCell>);
};

//function createData( nombre_factura, aforo, fecha_emision, plazo_dias, mora) {
//  return { nombre_factura, aforo, fecha_emision, plazo_dias, mora };
//}

function DetailsCardView({ account }) {
  const classes = useStyles();
  const { t } = useTranslation();

  //console.log('account en details: ->', account);

  const [data, setData] = useState({
    no_cuenta: '',
    client_id: '',
    fecha_creacion: '',
    producto: '',
    estatus: '',
  });

  //const rows = [
  //  createData(10001, '10%', '12/09/20', 90, '5%')
  //];

  const getData = async function () {
    try {
      const userInfo = await getUserInfo();
      //console.log('userInfo: ', userInfo);
      const accountId = userInfo.account;
      const userId = userInfo.user;
      const [clientId] = userInfo.objects.clients;
      const accounts = await Dynamicore(SERVICES.ACCOUNTS).read({id: accountId, client: clientId});
      //console.log('accounts: ->', accounts);
      const createAt = accounts.created_at;
      const product = accounts.identifier;
      const status = accounts.status;
      //Asignar valores
      setData({
        no_cuenta: accountId,
        client_id: userId,
        fecha_creacion: createAt,
        producto: product,
        estatus: status,
      });

    } catch (error) {
      console.log('error :->', error);
    }
  }

  const getStatus = (status) => {
    //console.log('estatus en get status',status)
    if(status === 'approved'){
      return 'aprobado'
    } else if (status === 'pending') {
      return 'pendiente';
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Grid container spacing={1} className={classes.container_amount_principal} id='container_amount_principal'>
        <Grid item lg={3} md={3} xs={12}>
          <Card className={classes.cardAmount}>
            <CardHeader
              title={(<Typography
                children="Monto financiado"
                variant="caption"
              />)}
              className={classes.cont_header_amount_title_principal}
            />
            <CardContent
              className={classes.amount_pos_card}
              children={<Typography
                className={classes.amount_pos_principal}
                children={numberFormat(account?.amount, '$' || 0)}
                variant="h3"
              />}
            />
          </Card>
        </Grid>
          <Grid item lg={3} md={3} xs={12}>
            <Card className={classes.cardAmount}>
              <CardHeader
                title={(<Typography
                  children="Tasa de interes"
                  variant="caption"
                />)}
                className={classes.cont_header_amount_title_principal}
              />
              <CardContent
                className={classes.amount_pos_card}
                children={<Typography
                  className={classes.amount_pos_principal}
                  children={account?.config?.interest_rate + '%' || 0}
                  variant="h3"
                />}
              />
            </Card>
          </Grid>
          <Grid item lg={3} md={3} xs={12}>
            <Card className={classes.cardAmount}>
              <CardHeader
                title={(<Typography
                  children="Fecha de pago"
                  variant="caption"
                />)}
                className={classes.cont_header_amount_title_principal}
              />
              <CardContent
                className={classes.amount_pos_card}
                children={<Typography
                  className={classes.amount_pos_principal}
                  children={account?.properties?.disbursed_date}
                  variant="h3"
                />}
              />
            </Card>
          </Grid>
          <Grid item lg={3} md={3} xs={12}>
            <Card className={classes.cardAmountStatus}>
              <CardHeader
                title={(<Typography
                  children="Estatus"
                  variant="caption"
                />)}
                className={classes.cont_header_amount_title_principal}
              />
              <CardHeader
                className={classes.pay_credit}
                id={'pay_creditCardHeader'}
                title={(<Typography
                  //children={t(account.status)}
                  children={ getStatus(account.status) }
                  variant="caption"
                />)}
              />
            </Card>
          </Grid>
      </Grid>

      {/* Grid de tabla */}
      {/* 
      <Grid container spacing={4} className={classes.container_table} id={'container_table'} >
        <Grid item md={12} xs={12}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableTitle}>Nombre de la factura</TableCell>
                <TableCell className={classes.tableTitle}>Aforo</TableCell>
                <TableCell className={classes.tableTitle}>Fecha Emision</TableCell>
                <TableCell className={classes.tableTitle}>Plazo</TableCell>
                <TableCell className={classes.tableTitle}>Mora</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.nombre_factura}>
                  <TableCell component="th" scope="row" className={classes.tableValues}>
                    {row.nombre_factura}
                  </TableCell>
                  <TableCell className={classes.tableValues}>{row.aforo}</TableCell>
                  <TableCell className={classes.tableValues}>{row.fecha_emision}</TableCell>
                  <TableCell className={classes.tableValues}>{row.plazo_dias}</TableCell>
                  <TableCell className={classes.tableValues}>{row.mora}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      */}
      {/* Fin Grid de tabla */}
      
    </>
  );
}

CellSkeleton.propTypes = {
  value: PropTypes.any,
  children: PropTypes.node,
};

DetailsCardView.propTypes = {
  account: PropTypes.object.isRequired,
};

export default DetailsCardView;
