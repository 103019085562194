import React, {useState, useEffect} from 'react';
import {Column, Columns, Container} from 'bloomer';
import {TitleSection} from '../../global/GlobalStyles';
import {ContFaqs} from './styles';
import {AccordionMyS} from '../../components/accordion/accordion.js';
import Header from '../../components/header/header.jsx';

const arFaqs = [
  {
    icon: null,
    dataFirst: '¿Qué es el factoraje financiero?',
    dataSecond: 'El factoraje financiero es un método de financiamiento a corto plazo, donde las empresas pueden obtener el adelanto de sus facturas por cobrar y así convertirlas en efectivo.',
    dataThird:'Una de las ventajas del factoraje financiero es que suele ser un proceso rápido y ágil a diferencia de los créditos bancarios.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Cómo funciona el factoraje financiero?',
    dataSecond: 'Primero, una empresa emite una factura a su cliente por una venta realizada. Usualmente, los clientes solicitan a sus proveedores largos plazos de pago post entrega, sobre todo cuando los volúmenes de compra son altos.',
    dataThird: 'Dado esto, la empresa solicita el adelanto de su factura a una empresa de factoraje financiero, quien le propondrá una tasa de comisión por el monto total de la factura. Si la empresa acepta, la empresa de factoraje le hace el adelanto de la factura, hasta un 90% del total.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Qué tipos de factoraje existen?',
    dataSecond: 'Factoraje con recurso: Es nuestro producto principal. Liberfin, como entidad financiera, es responsable de la gestión del cobro al solicitante y de adelantar oportunamente el anticipo de pago. En el factoraje con recurso, la entidad financiera se encuentra protegida en caso de incumplir el pago mediante un contrato y pagare, pues la empresa solicitante es quien debe asumir la responsabilidad del mismo. Resumiendo, El cliente es el encargado de asumir los riesgos por falta de pago. Es más económico. Requiere un contrato y pagaré como respaldo de la deuda. ',
    dataThird: 'Factoraje sin recurso: En el factoraje sin recurso, la empresa solicitante no queda obligada a responder por el pago en caso de que la entidad deudora no realice dicha transacción a tiempo. Es decir, la empresa de factoraje absorbe el riesgo y la responsabilidad de los cobros, gracias a la garantída de un seguro de crédito comercial involucrado. Al momento en el que la empresa solicitante decide vender y ceder a la entidad financiera su factura, también se ceden los derechos de cobranza, deslindando su responsabilidad de realizar esta labor. La entidad financiera adelanta hasta el 90% del valor de la factura, brindando liquidez inmediata. El cliente del solicitante paga el total de la factura al terminar el plazo acordado en el contrato a una cuenta perteneciente a la entidad financiera y posteriormente esta hace devolución del porcentaje restante menos un fee por el servicio al solicitante. Resumiendo, Cuenta con un seguro que responde por el riesgo en caso de incumplimiento. Es más caro debido a que el riesgo de impago lo absorbe la entidad financiera y por la contratación de un seguro El solicitante cede los derechos de cobranza a la entidad financiera debiendo notificar a sus y hacer las gestiones administrativas correspondientes.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Cual es la diferencia entre el factoraje y un crédito PYME?',
    dataSecond: 'La principal diferencia entre el factoraje financiero y un crédito pyme podrída ser la funcionalidad que tienen. Pues por un lado el factoraje es una alternativa para obtener liquidez, mientras que el crédito PYME funciona tanto para liquidez como para inversión.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Cómo Liberfin se diferencía de las demás soluciones?',
    dataSecond: 'Nuestro proceso es 100% digital y rápido. La plataforma te lleva de la mano durante la solicitud de financiamiento logrando darte respuesta en menos de 24 horas.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Qué necesito para solicitar factoraje?',
    dataSecond: 'Solo necesitamos la factura a descontar, RFC y CIEC de la empresa/persona física. Si eres persona física, tu identificación, comprobante de domicilio no mayor a 2 meses, caratula de estado de cuenta bancario y firma de contrato y pagaré. Si es persona moral, identificación del representante legal, poder notarial, acta constitutiva, comprobante de domicilio no mayor a 2 meses, carátula de estado de cuenta bancario, firma de contrato por parte de la empresa y firma de pagaré por parte del accionista mayoritario de la misma.',
    show: false,
    status: true,
  },
];
export const AskView = () => {
  const [faqs, setFaqs] = useState(arFaqs);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
    <Header />
    <ContFaqs id={'faqs'}>
      <Container isFluid={true}>
        <Columns className={'full-height'} isMultiline={true} id="'column-title">
          <Column className={'no-display-mobile'} isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 2}}>
            &nbsp;
          </Column>
          <Column className={'text-center'} isSize={{mobile: 12, tablet: 12, desktop: 10, widescreen: 8}}>
            <Columns className={'full-height'} isMultiline={true}>
              <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
                <TitleSection className={'animate__animated animate__fadeInUp'}>Preguntas <br/><span>Frecuentes</span></TitleSection>
              </Column>
            </Columns>
          </Column>
          <Column className={'no-display-mobile'} isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 2}}>
            &nbsp;
          </Column>
        </Columns>
        <Columns className={'full-height'} isMultiline={true} id='multi-columns'>
          <Column className={'no-display-mobile'} isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 1}}>
            &nbsp;
          </Column>
          <Column className={'center-column mt-3'} isSize={{mobile: 12, tablet: 12, desktop: 10, widescreen: 10}} id='column-accordion'>
            <Columns className={'full-height animate__animated animate__fadeInDown'} isMultiline={true}>
              <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
                <AccordionMyS data={faqs} />
              </Column>
            </Columns>
          </Column>
          <Column className={'no-display-mobile'} isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 1}}>
            &nbsp;
          </Column>
        </Columns>
      </Container>
    </ContFaqs>
    </>
  )
}
