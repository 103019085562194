import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: '70%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      borderRadius: '30px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3, 4, 4),
      '& #signUpForm input': {
        fontFamily: 'Poppins, sans-serif',
      },
      '& #signUpForm label': {
        backgroundColor: '#FFF !important',
        paddingLeft: '3px !important',
        paddingRight: '3px !important',
      },
      '& #signUpForm fieldset': {
        top: '0px !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      }
    },
    fontTitle: {
      color: '#090F37',
      fontFamily: 'Poppins, sans-serif',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '36px',
      marginBottom: '-10px',
      textAlign: 'center !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
        textAlign: 'center',
      }
    },
    circularProgress: {
      color: '#361FF5',
      position: 'absolute',
      width: '70px',
      height: '70px',
    },
  }));

export default useStyles;