import Dynamicore, { SERVICES } from '../services/dynamicore';

async function getUserinfo() {
  //console.log("Entramos a la funcion getUserinfo");
  const { values: userInfo } = await Dynamicore(SERVICES.USERS_GET_INFO).read();
  userInfo.objects.clients = [];
  (userInfo?.json_rol?.Statement || []).forEach(({ Action, Resource }) => {
    if (Action.includes('client:select')) {
      userInfo
        .objects
        .clients
        .push(Resource.replace(`dcore:${userInfo.company}:client:`, ''));
    }
  });

  return userInfo;
};

export default getUserinfo;
