import { makeStyles } from '@material-ui/core';

//const colorPrincipal = '#361FF5 !important';

export default makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '70%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent !important',
    borderRadius: '30px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 4),
    '& #signUpForm input': {
      fontFamily: 'Poppins, sans-serif',
    },
    '& #signUpForm label': {
      backgroundColor: '#FFF !important',
      paddingLeft: '3px !important',
      paddingRight: '3px !important',
    },
    '& #signUpForm fieldset': {
      top: '0px !important',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  contPageFactoring: {
    height: '100%',
    marginBottom: '300px',
  },
  title: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '36px !important',
    fontStyle: 'normal',
    fontWeight: '700 !important',
    lineHeight: '39px',
    color: '#090F37',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px !important',
      fontWeight: '500 !important',
      lineHeight: '28px',
    },
  },
  subtitle: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '18px !important',
    fontStyle: 'normal',
    fontWeight: '400 !important',
    lineHeight: '28px',
    color: '#090F37',
  },
  subtitleOpacity: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '18px !important',
    fontStyle: 'normal',
    fontWeight: '400 !important',
    lineHeight: '28px',
    color: '#090F37',
    opacity: '0.4',
  },
  searchInput: {
    marginBottom: '10px !important',
    width: '100%',
  },
  contFactura: {
    border: '1px solid #361FF5 !important',
    borderRadius: '10px !important',
    marginBottom: '10px !important',
  },
  titleModal: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '24px !important',
    fontStyle: 'normal',
    fontWeight: '700 !important',
    lineHeight: '28px',
    color: '#090F37',
  },
  error: {
    color: 'red',
    fontSize: '14px',
    fontFamily: 'Poppins, sans-serif !important',
  },
  factureContainer: {
    border: '2px solid #361FF5 !important',
    borderRadius: '10px !important',
    marginBottom: '8px !important',
  },
  contFacturas: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
  },
  consult: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  consultText: {
    marginTop: '40px',
    display: 'block',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  circularProgress: {
    color: '#361FF5',
    position: 'absolute',
    width: '70px',
    height: '70px',
  }
}));