import React from 'react';
import '../../css/success/success.css';
import { useNavigate } from 'react-router-dom';
import DashHeader from '../../components/header/dash_header.jsx';
import Aproved from '../../img/aprovedImg.png';
import ButtonBlue from '../../components/buttons/button_blue.js';

function AprovedView() {
  const navigate = useNavigate();

  const handleClick = async () => {
    navigate('/dashboard');
  }
    return(
      <>
      <DashHeader />
      <div className="mb-5">
        <section id="sectionrefused" className='mt-md-1 pt-md-1'>
          <div className="row align-items-center text-center mb-5">
            <div className='col-sm-12 col-md-12 col-lg-12'>
              <div className="text-center align-items-center ps-md-3 pe-md-3 ps-lg-5 pe-lg-5 w-50 mx-auto">
                <h4 className="rechazado mb-5">¡Felicidades! 🥳
                <span className='color-blue'>Tu crédito fue aprobado.!</span></h4>
                <p className="sub_titulo text-center">
                  Tienes un crédito aprobado por la cantidad de $128,394.00.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center text-center">
            <div className='col-sm-12 col-md-12 col-lg-12'>
              <img src={Aproved} width="350" height="auto" alt="Refused" className='mt-0'/>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 text-center">
              <ButtonBlue onClick={handleClick} 
                styleClass={'btn btn-blue btn-lg text-white fs-header-9 btn-login width-button-validar'} 
                spanClass={'span_icon'} 
                iconColor={'icon-button-blue'} 
                value={'Continuar'} 
              />
            </div>
          </div>
        </section>
      </div>
      </>
    );
}

export default AprovedView;