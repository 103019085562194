const Rates = [
  {
    id: 1,
    comp: 1,
    rate: 15,
  },
  {
    id: 2,
    comp: 0.990,
    rate: 15.1,
  },
  {
    id: 3,
    comp: 0.980,
    rate: 15.2,
  },
  {
    id: 4,
    comp: 0.970,
    rate: 15.3, 
  },
  {
    id: 5,
    comp: 0.960,
    rate: 15.4,
  },
  {
    id: 6,
    comp: 0.950,
    rate: 15.5,
  },
  {
    id: 7,
    comp: 0.940,
    rate: 15.6,
  },
  {
    id: 8,
    comp: 0.930,
    rate: 15.7,
  },
  {
    id: 9,
    comp: 0.920,
    rate: 15.8,
  },
  {
    id: 10,
    comp: 0.910,
    rate: 15.9,
  },
  {
    id: 11,
    comp: 0.900,
    rate: 16,
  },
  {
    id: 12,
    comp: 0.890,
    rate: 16.3,
  },
  {
    id: 13,
    comp: 0.880,
    rate: 16.5,
  },
  {
    id: 14,
    comp: 0.870,
    rate: 16.8,
  },
  {
    id: 15,
    comp: 0.860,
    rate: 17,
  },
  {
    id: 16,
    comp: 0.850,
    rate: 17.3,
  },
  {
    id: 17,
    comp: 0.840,
    rate: 17.5,
  },
  {
    id: 18,
    comp: 0.830,
    rate: 17.8,
  },
  {
    id: 19,
    comp: 0.820,
    rate: 18,
  },
  {
    id: 20,
    comp: 0.810,
    rate: 18.3,
  },
  {
    id: 21,
    comp: 0.800,
    rate: 18.5,
  },
  {
    id: 22,
    comp: 0.790,
    rate: 19,
  },
  {
    id: 23,
    comp: 0.780,
    rate: 19.5,
  },
  {
    id: 24,
    comp: 0.770,
    rate: 20,
  },
  {
    id: 25,
    comp: 0.760,
    rate: 20.5,
  },
  {
    id: 26,
    comp: 0.750,
    rate: 21,
  },
  {
    id: 27,
    comp: 0.740,
    rate: 21.5,
  },
  {
    id: 28,
    comp: 0.730,
    rate: 22,
  },
  {
    id: 29,
    comp: 0.720,
    rate: 22.5,
  },
  {
    id: 30,
    comp: 0.710,
    rate: 23,
  },
  {
    id: 31,
    comp: 0.700,
    rate: 23.5,
  },
  {
    id: 32,
    comp: 0.690,
    rate: 24.3,
  },
  {
    id: 33,
    comp: 0.680,
    rate: 25,
  },
  {
    id: 34,
    comp: 0.670,
    rate: 25.8,
  },
  {
    id: 35,
    comp: 0.660,
    rate: 26.5,
  },
  {
    id: 36,
    comp: 0.650,
    rate: 27.3,
  },
  {
    id: 37,
    comp: 0.640,
    rate: 28,
  },
]

export default Rates;
