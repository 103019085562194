import { useEffect, useState } from 'react';
//import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  CardHeader,
  Divider,
  Grid,
  Typography,
  Tab,
  Tabs,
} from '@material-ui/core';
import DetailsCardView from './DetailsView/DetailsView.jsx';
import EspecificationsView from './EspecificationView/EspecificationView.jsx';
import PropietiesView from './PropietiesView/PropietiesView.jsx';
import useStyles from './styles.js';
import DashHeader from '../../../components/header/dash_header.jsx';
import ButtonGroup from '../../../components/ButtonGroup/index.jsx';
import { getUserInfo } from '../../../functions/index.js';
import getPeople from '../../../services/dynamicore/processes/getPeople.js';
import Dynamicore, { SERVICES } from '../../../services/dynamicore/index.js';
//import Header from '../../../components/header';
import Page from '../../../components/page/Page.jsx';

//import PaymentModal from '../PaymentModal/index.jsx';
import { TABS } from './constants';
//import AmortizationsTab from './Tabs/Amortizations';
//import GeneralTab from './Tabs/General';
//import MovementsTab from './Tabs/Movements';

function DashboardDetailsView({ title }) {
  const classes = useStyles();
  //const { statementid } = useParams();

  const [currentTab, setCurrentTab] = useState([...TABS].shift().value);
  const [state, setState] = useState();
  const [currentView, setCurrentView] = useState(0);
  const [data, setData] = useState({
    monto: 0,
  });

  const getData = async () => {
    
    try {
      const userInfo = await getUserInfo();
      //const accountId = userInfo.account;
      const [clientId] = userInfo.objects.clients;
      //console.log('clientId: ->', clientId);
      const people = await getPeople(clientId);

      const { json_rol: { Statement = [] } } = userInfo;
      const resources = {};
      Statement.forEach(({ Resource = '', Effect = '' } = {}) => {
        const [, , type, id] = Resource.split(':');

        if (!resources[type]) {
          resources[type] = [];
        }

        if (Effect === 'Allow') {
          resources[type].push(parseInt(id));
        }
      });
      const [accountId = null] = resources.account || [];

      const accounts = await Dynamicore(SERVICES.ACCOUNTS)
      .read({
        id: accountId,
        client: clientId,
      });
      //console.log('accounts: ->', accounts);

      
      //Asignar valores
      setState(accounts);
      setData({monto: people.pii.valor_de_la_factura});

    } catch (error) {
      console.log('error :->', error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (!state) {
    return null;
  }

  return (
  <>
    <DashHeader />
    <div className={classes.contPagec} id={'ContPageDetails'}>
      <div className="container ps-lx-5 pe-xl-5 ps-lg-5 pe-lg-5 ps-md-1 pe-md-1 mb-5">
        <Page title={title}>
          {/*<PaymentModal
            setState={setModal}
            {...modal}
          />*/}
          {/*<Header
            breadcrumb={false}
            title={title}
            actions={[
              {
                color: 'primary',
                fn: () => setModal({
                  open: true,
                }),
                label: 'Pagar crédito',
              },
            ]}
          />
          */}
          <Grid container spacing={4}>
            <Grid item md={12} xs={12}>
              <CardHeader
                title={(<Typography
                  children="Detalles del crédito"
                  variant="caption"
                />)}
                className={classes.container_title}
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <Tabs
              indicatorColor='primary'
              className={classes.tabs_colors}
              onChange={(event, value) => setCurrentTab(value)}
              scrollButtons="auto"
              value={currentTab}
              variant="scrollable"
            >
              {TABS.map(({ label, value }, index) => (
                <Tab key={index} label={label} value={value} />
              ))}
            </Tabs>
          </Box>
          <Divider />
          <Box mt={2} className={classes.boxbuttongroup} id={'boxbuttongroup'}>
            {{
              //general: <GeneralTab accountId={accountId} />,
              general: 
                <div>
                  <ButtonGroup
                    actions={[
                    {
                      label: 'Detalles generales',
                      value: 0,
                      visible: true,
                    },
                    {
                      label: 'Especificaciones',
                      value: 1,
                      visible: true,
                      //visible: account?.properties
                      //  && Object.keys(account.properties).length !== 0
                      //  && account.properties.constructor === Object,
                    },
                    {
                      label: 'Propiedades',
                      value: 2,
                      visible: true,
                      //visible: account?.config
                      //  && Object.keys(account.config).length !== 0
                      //  && account.config.constructor === Object,
                    },
                    ].filter(item => item.visible)}
                    onChange={setCurrentView}
                    pb={3}
                    pt={2}
                    value={currentView}
                    width={160}
                  />
                  {/** Fin boton group, a partir de aqui se ponen las vistas dentro del buton group*/}
                  {currentView === 0 && <DetailsCardView account={state} />}
                  {currentView === 1 && <EspecificationsView properties={state.properties} />}
                  {currentView === 2 && <PropietiesView config={state.config}/>}
                </div>,
              //amortizations: <AmortizationsTab accountId={accountId} />,
              //amortizations: 
              //  <div>
              //    Vista de amortizaciones
              //  </div>,
              //movements: <MovementsTab accountId={accountId} />,
              //movements: <div>MovementsTab</div>,
            }[currentTab]}
          </Box>
        </Page>
      </div>
    </div>
  </>
  );
}

DashboardDetailsView.defaultProps = {
  title: 'Detalles del crédito',
};

DashboardDetailsView.propTypes = {
  title: PropTypes.string,
};

export default DashboardDetailsView;
