import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

function InputCurrency(props) {
  const { name, inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={({ value }) => {
        onChange({
          target: { name, value },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$ "
    />
  );
}

InputCurrency.propTypes = {
  name: PropTypes.string,
  inputRef: PropTypes.any,
  onChange: PropTypes.func,
};

export default InputCurrency;
