import React from 'react';
import '../../../css/section.css';
import Mimage from '../../../img/TabletIndex.png';
import { Link, animateScroll as scroll } from "react-scroll";
const Section = () =>{
    return(
        <section className="section1">
            <div className="container ps-4 pe-4 position-relative">
                <div className="row position-absolute top-0 start-0 padding-mobile mx-auto">
                    <div className="col-lg-7 col-xl-6 offset-xl-1 mt-xl-5 mt-lg-4 mt-md-3 mt-sm-5 align-items-center">
                       <div className="col-padding ps-0 gradient margin-mobile pt-5 ">
                            <span className="presentation">Libertad</span>
                            <br/>
                            <span className="presentation">Financiera</span>
                            <br/>
                            <span className="sub-presentation">para tu negocio</span>
                            <p className="pt-4 pe-lg-5 pe-sm-5">Obtén liquídez para tu negocio en cuestión de minutos anticipando hasta el 90% del valor de tus cuentas por cobrar.</p>
                    
                            <Link 
                                to="section_f"
                                offset={-75}
                                duration={300}
                                activeClass="active"
                                spy={true}
                                smooth={true} >
                                <a class="btn btn-blue text-white btn-lg mt-3 mb-3 fs-6 btn-finan">
                                    Solicita financiamiento
                                    <svg className="ms-3" width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.688628 14.82L6.50863 8.99997L0.688628 3.17997C0.103628 2.59497 0.103628 1.64997 0.688628 1.06497C1.27363 0.479971 2.21863 0.479971 2.80363 1.06497L9.68863 7.94997C10.2736 8.53497 10.2736 9.47997 9.68863 10.065L2.80363 16.95C2.21863 17.535 1.27363 17.535 0.688628 16.95C0.118628 16.365 0.103628 15.405 0.688628 14.82Z" fill="#F7F7F7"/>
                                    </svg>  
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row img-mobile p-0">
                    <div className="col-lg-9 offset-lg-3">
                        <img className="margin-1199 imgSize" src={Mimage} alt='section1' />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section;