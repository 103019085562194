import { makeStyles } from '@material-ui/core';

const colorPrincipal = '#361FF5';

const useStyles = makeStyles((theme) => ({
  contPage: {
    height: '100%',
    marginBottom: '500px !important',
  },
  contWlcome: {
    textAlign: 'left !important',
    AlignItems: 'left !important',
  },
  contWlcome1: {
    textAlign: 'right !important',
    AlignItems: 'right !important',
  },
  creditStatus: {
    paddingTop: '20px',
  },
  notAprob: {
    color: '#090F37',
    fontSize: '22px',
    fontFamily: 'Poppins, sans-serif !important',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    marginBottom: '10px',
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  imgGreatings: {
    width: '34px !important',
    height: '34px',
  },
  link: {
    color: '#FFF',
    cursor: 'pointer',
    '&:hover': {
      color: '#FFF',
    }
  },
  fontGreatings: {
    color: '#090F37',
    fontSize: '36px',
    fontFamily: 'Poppins, sans-serif !important',
    fontStyle: 'normal',
    fontWeight: '700 !important',
    marginRight: '3%',
  },
  fontGreatings2: {
    color: ({color})=> colorPrincipal,
    fontSize: '28px',
    fontFamily: 'Poppins, sans-serif !important',
    fontStyle: 'normal',
    fontWeight: '400 !important',
    lineHeight: '28px',
  },
  btnSoli: {
    backgroundColor: ({backgroundColor})=> colorPrincipal,
    color: '#FFF',
    borderRadius: '5px',
    width: 'auto',
    height: 'auto',
    padding: '15px 25px',
    fontFamily: 'Poppins, sans-serif !important',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    fontSize: '14px',
    lineHeight: '16px',
    border: 'none',
  },
  amountPayable: {
    color: '#8C8C8C',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '24px !important',
    fontStyle: 'normal',
    fontWeight: 'bold !important',
    lineHeight: '150%',
  },
  amountPayableText: {
    color: '#8C8C8C',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '12px !important',
    fontStyle: 'normal',
    fontWeight: 'bold !important',
    lineHeight: '150%',
  },
  amountPayment: {
    color: '#8C8C8C',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '24px !important',
    fontStyle: 'normal',
    lineHeight: '150%',
  },
  amountPaymentText: {
    color: '#8C8C8C',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '12px !important',
    fontStyle: 'normal',
    lineHeight: '150%',
  },
  amountToPay: {
    color: '#666666',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '24px !important',
    fontStyle: 'normal',
    lineHeight: '150%',
  },
  amountToPayText: {
    color: '#666666',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '12px !important',
    fontStyle: 'normal',
    lineHeight: '150%',
  },
  amount_line: {
    backgroundColor: '#0EB0F1',
    height: '88px',
    margin: '0 auto',
    marginLeft: '0 !important',
    marginRight: '0 !important',
    padding: '0 !important',
    position: 'relative',
    width: '1px',
  },
  amount_neg: {
    color: '#FF0000',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '32px !important',
    fontStyle: 'normal',
    fontWeight: '400 !important',
    lineHeight: '150%',
    padding: '15px 5px',
    textAlign: 'left',
  },
  amount_pay: {
    color: '#666666',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '32px !important',
    fontStyle: 'normal',
    fontWeight: 'bold !important',
    lineHeight: '150%',
    padding: '15px 5px',
    textAlign: 'left',
  },
  amount_pos_card: {
    padding: '0 !important',
  },
  amount_pos_principal: {
    color: ({color})=> colorPrincipal,
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '28px !important',
    lineHeight: '36px',
    fontStyle: 'normal',
    fontWeight: '700 !important',
    paddingLeft: '15px',
  },
  amount_pos: {
    color: ({color})=> colorPrincipal,
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '32px !important',
    fontStyle: 'normal',
    fontWeight: '700 !important',
    textAlign: 'left',
    '& div': {
      color: '#FFF',
      fontStyle: 'bold',
    },
  },
  btn_current_payment: {
    '& span': {
      color: '#FFFFFF',
      cursor: 'pointer',
      fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
      fontSize: '14px !important',
      fontStyle: 'normal',
      fontWeight: 'bold !important',
      lineHeight: '150%',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    alignItems: 'center',
    backgroundColor: '#0EB0F1',
    borderRadius: '18px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0px',
    minWidth: '152px',
    padding: '10px 20px',
    width: 'auto',
  },
  card: {
    borderRadius: '15px',
  },
  cardAmount: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #FFFFFFF',
    borderRadius: '20px',
    boxShadow: '0px 0px 8px rgba(14, 176, 241, 0.25)',
    padding: '10px 20px',
    //textAlign: 'center !important',
    height: '150px',
  },
  cardAmountTitle: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '16px !important',
    fontStyle: 'normal',
    fontWeight: '400 !important',
    lineHeight: '25px',
    color: '#090F37',
    paddingLeft: '5px',
  },
  cardAmountInteres: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #FFFFFFF',
    borderRadius: '20px',
    boxShadow: '0px 0px 8px rgba(14, 176, 241, 0.25)',
    padding: 'px 0px',
    textAlign: 'center !important',
  },
  card_content: {
    textAlign: 'center',
  },
  center_vertical: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'left',
    width: '200px',
  },
  contPaymentOfPayments: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cont_header_amount_title_principal: {
    padding: '16px 5px !important',
    verticalAlign: 'middle',
    '& span': {
      color: '#090F37 !important',
      fontFamily: 'Poppins, sans-serif !important',
      fontSize: '18px !important',
      fontStyle: 'normal',
      fontWeight: '400 !important',
      lineHeight: '28px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px !important',
      },
    },
  },
  cont_header_amount_title_principal_interest: {
    '& span': {
      color: '#090F37 !important',
      fontFamily: 'Poppins, sans-serif !important',
      fontSize: '15px !important',
      fontStyle: 'normal',
      fontWeight: '400 !important',
      lineHeight: '26px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px !important',
      },
    },
  },
  cont_header_amount_title: {
    '& span': {
      color: '#4F4F4F !important',
      fontFamily: 'Inter !important',
      fontSize: '14px !important',
      fontStyle: 'normal',
      fontWeight: 'bold !important',
      lineHeight: '150%',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px !important',
      },
      '& b': {
        color: '#000000 !important',
        fontSize: '12px !important',
      }
    },
    padding: '5px 0px',
  },
  cont_img: {
    textAlign: 'left',
    verticalAlign: 'middle !important',
  },
  container_amount_principal: {
    backgroundColor: '#FFFFFF!important',
    margin: '20px 0px 0px 0px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  container_amount: {
    background: '#FFFFFF',
    borderRadius: '16px',
    justifyContent: 'space-between',
    //boxShadow: '0px 0px 8px rgba(14, 176, 241, 0.25);',
    height: '120px',
    margin: '0',
    maxWidth: '100%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '240px',
    },
  },
  cont_databox_principal: {
    backgroundColor: '#361FF5' ,
    borderRadius: '10px',
    textAlign: 'center !important',
  },
  cont_databox: {
    background: '#F8F8F8',
    borderRadius: '10px',
  },
  container_table: {
    margin: '0',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  container_title: {
    '& span': {
      color: ({color})=> colorPrincipal,
      fontFamily: 'Poppins, sans-serif !important',
      fontSize: '36px !important',
      fontStyle: 'normal',
      fontWeight: 'bold !important',
      lineHeight: '39px',
    },
    padding: '5px 0px 15px',
  },
  credit_paid: {
    '& span': {
      alignItems: 'center',
      color: '#0A3AA4',
      display: 'flex',
      fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
      fontSize: '13px !important',
      fontStyle: 'normal',
      fontWeight: 'bold !important',
      justifyContent: 'center',
      lineHeight: '150%',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    alignItems: 'center',
    backgroundColor: 'rgba(10, 58, 164, 0.08)',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    minWidth: '152px',
    padding: '10px 5px',
    width: '150px',
  },
  credit_active: {
    '& span': {
      alignItems: 'center',
      color: '#4caf50',
      display: 'flex',
      fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
      fontSize: '13px !important',
      fontStyle: 'normal',
      fontWeight: 'bold !important',
      justifyContent: 'center',
      lineHeight: '150%',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    alignItems: 'center',
    backgroundColor: 'rgba(76, 175, 80, 0.08)',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    minWidth: '152px',
    padding: '10px 5px',
    width: '150px',
  },
  credit_pending: {
    '& span': {
      alignItems: 'center',
      color: '#ff9800',
      display: 'flex',
      fontFamily: 'Poppins, sans-serif !important',
      fontSize: '14px !important',
      fontStyle: 'normal',
      fontWeight: 'bold !important',
      justifyContent: 'center',
      lineHeight: '150%',
      textAlign: 'center',
      //textTransform: 'uppercase',
    },
    alignItems: 'center',
    backgroundColor: 'rgba(255, 152, 0, 0.08)',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    minWidth: '152px',
    padding: '10px 5px',
    width: '150px',
  },

  datePayment: {
    color: '#8C8C8C',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '12px !important',
    fontStyle: 'normal',
    lineHeight: '150%',
  },
  datePaymentNext: {
    color: '#FF0000',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '12px !important',
    fontStyle: 'normal',
    fontWeight: 'bold !important',
    lineHeight: '150%',
  },
  item_cell_btn_current_payment: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  item_cell_current: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  item_cell_current_right: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  item_cell_right: {
    textAlign: 'right',
  },
  item_row_current: {
    borderRadius: '16px',
    boxShadow: '0px 0px 8px rgba(14, 176, 241, 0.5)',
    height: '110%',
    marginLeft: '-20px',
    minHeight: '120px',
    minWidth: '840px',
    padding: '22px',
    width: '110%',
  },
  item_row_normal: {
    borderBottom: '1px solid rgba(0, 21, 75, 0.25)',
    padding: '22px',
  },
  item_row_past: {
    backgroundColor: '#F8F8F8',
    borderRadius: '16px',
    padding: '22px',
  },
  no_padding_sides: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    width: '1px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  pay_credit: {
    '& span': {
      color: '#0EB0F1',
      cursor: 'pointer',
      fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
      fontSize: '14px !important',
      fontStyle: 'normal',
      fontWeight: 'bold !important',
      lineHeight: '150%',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    alignItems: 'center',
    border: '1px solid #0EB0F1',
    borderRadius: '18px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    minWidth: '152px',
    padding: '5px 10px',
    width: '150px',
  },
  paymentOfPayments: {
    alignItems: 'flex-start',
    color: '#0EB0F1',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '14px !important',
    fontStyle: 'normal',
    fontWeight: 'bold !important',
    justifyContent: 'center',
    lineHeight: '150%',
    textDecoration: 'underline',
  },
  paymentOfPaymentsCurrent: {
    color: '#00154B',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '14px !important',
    fontStyle: 'normal',
    fontWeight: 'bold !important',
    lineHeight: '150%',
    marginBottom: '15px',
  },
  paymentOfPaymentsIcon: {
    marginRight: '5px',
  },
  table: {
    '& .MuiPaper-root ': {
      backgroundColor: 'transparent !important',
      boxShadow: 'none',
    },
    '& .MuiTable-root': {
      '& .MuiTableBody-root .MuiButtonBase-root': {
        '& .MuiButton-label': {
          color: '#FFFFFF',
          fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
          fontSize: '14px !important',
          fontStyle: 'normal',
          fontWeight: 'bold !important',
          lineHeight: '150%',
        },
        backgroundColor: '#0EB0F1 !important',
        borderRadius: '16px',
        height: '40px',
        width: '110px',
      },
      '& .MuiTableBody-root > .MuiTableRow-root': {
        '&:nth-child(even)': {
          backgroundColor: '#FAF7FA',
        },
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        boxShadow: '0px 0px 8px rgba(14, 176, 241, 0.25)',
        height: '56px',
      },
      '& .MuiTableBody-root > .WithStyles(ForwardRef(TableRow))-root-63': {
        backgroundColor: 'transparent !important',
      },
      '& .MuiTableHead-root': {
        '& .MuiTableRow-head': {
          '& .MuiTableCell-head': {
            backgroundColor: 'transparent !important',
            color: '#00154B !important',
            fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
            fontSize: '14px !important',
            fontStyle: 'normal',
            fontWeight: 'bold !important',
            lineHeight: '150%',
            padding: '5px',
          },
          backgroundColor: 'transparent !important',
        },
        backgroundColor: 'transparent !important',
      },
      backgroundColor: 'transparent !important',
    },
  },
  table_cell: {
    backgroundColor: 'transparent',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '14px !important',
    fontStyle: 'normal',
    fontWeight: 'bold !important',
    lineHeight: '150%',
  },
  table_cell_amount_neg: {
    backgroundColor: 'transparent',
    color: '#FF0000',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '14px !important',
    fontStyle: 'normal',
    fontWeight: 'bold !important',
    lineHeight: '150%',
  },
  table_cell_amount_pos: {
    backgroundColor: 'transparent',
    color: '#666666',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '14px !important',
    fontStyle: 'normal',
    fontWeight: 'bold !important',
    lineHeight: '150%',
  },
  table_cell_id: {
    color: '#0EB0F1',
  },
  table_cell_text: {
    color: '#666666',
  },
  title_table: {
    '& span': {
      color: ({color})=> colorPrincipal,
      fontFamily: 'Poppins, sans-serif !important',
      fontSize: '20px !important',
      fontStyle: 'bold',
      fontWeight: '700 !important',
      lineHeight: '150%',
    },
    padding: '35px 0px 0px',
  },
  title_table_link: {
    '& span': {
      color: ({color})=> colorPrincipal,
      //opacity: '0.5',
      fontFamily: 'Poopins, sans-serif !important',
      fontSize: '14px !important',
      fontStyle: 'normal',
      lineHeight: '150%',
    },
    padding: '35px 0px 0px',
    textAlign: 'right',
  },
  date: {
    color: '#000000'
  },
  tableTitle: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '16px !important',
    fontStyle: 'bold',
    fontWeight: '700 !important',
    lineHeight: '28px',
    color: '#090F37',
  },
  tableValues: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '16px !important',
    fontStyle: 'bold',
    fontWeight: '400 !important',
    lineHeight: '18px',
    color: '#090F37',
  },
  contStatus: {
    backgroundColor: '#361FF5',
    textAlign: 'center',
    borderRadius: '10px',
    padding: '0px 4px',
  },
  credit_unauthorized: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '36px !important',
    fontStyle: 'normal',
    fontWeight: '700 !important',
    lineHeight: '39px',
    color: '#090F37',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px !important',
      fontWeight: '500 !important',
      lineHeight: '28px',
    },
  },
  imgCentral: {
    width: "450px",
    height: "auto",
    textAlign: 'center',
    marginTop: '2rem',
    [theme.breakpoints.down('md')]: {
      width: "450px",
    },
  },
  detalles: {
    width: '100%',
    display: 'grid',
    textAlign: 'rigth',
    '& > *': {
      textDecoration: 'none',
      cursor: 'pointer',
      textAlign: 'rigth',
      justifySelf: 'end',
    }
  },
  approved: {
    textAlign: 'center',
    alignItems: 'center',
    color: '#FFF',
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '14px !important',
  },
  pending: {
    textAlign: 'center',
    alignItems: 'center',
    color: '#FFF',
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '14px !important',
  },
  container_clabe: {
    backgroundColor: 'rgba(97, 136, 247, 0.1)',
    borderRadius: '2px',
    padding: '0px 10px',
    alignItems: 'center',
    verticalAlign: 'middle',
    display: 'flex !important',
  },
  container_actions: {
    marginTop: '20px',
    backgroundColor: 'rgba(97, 136, 247, 0.1)',
    borderRadius: '2px',
    padding: '0px 10px',
    alignItems: 'center',
    verticalAlign: 'middle',
    display: 'flex !important',
  },
  textClabe: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '12px !important',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    lineHeight: '14px',
    color: '#333333',
    paddingLeft: '15px',
  },
  numberClabe: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '14px !important',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    lineHeight: '16px',
    color: '#361FF5',
    paddingLeft: '15px',
  },
  textActions: {
    fontFamily: 'Poppins, sans-serif !important',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    lineHeight: '16px',
    color: '#361FF5',
    paddingLeft: '15px',
  },
  modalClabe: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '14px !important',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    lineHeight: '18px',
    color: '#361FF5',
    paddingLeft: '15px',
  },
  btnPay: {
    color: '#361FF5 !important',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  modalTitle: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '35px !important',
    fontStyle: 'normal',
    fontWeight: '700 !important',
    lineHeight: '46px',
    color: '#361FF5',
  },
  modalSubtitle: {
    marginTop: '5px',
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '14px !important',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    lineHeight: '16px',
    color: '#101828',
  },
  totalPayContainer: {
    padding: '0 0 0 15px',
  },
  modalPay: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '14px !important',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    lineHeight: '16px',
    color: '#667085',
  },
  modalPayAmmount: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '23px !important',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    lineHeight: '28px',
    color: '#101828',
  },
  buttonPay: {
    background: '#361FF5',
  },
  msgEmail: {
    backgroundColor: 'rgba(97, 136, 247, 0.1)',
    borderRadius: '2px',
    textAlign: 'center',
    alignItems: 'center',
    verticalAlign: 'middle',
    display: 'flex !important',
    marginBottom: '20px',
  },
  error: {
    color: 'red',
    fontSize: '22px',
    fontFamily: 'Poppins, sans-serif !important',
  },
  subtitle: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '25px !important',
    fontStyle: 'normal',
    fontWeight: '600 !important',
    lineHeight: '28px',
    color: '#090F37',
  },
  newAccount: {
    cursor: 'pointer',
  },
  actions: {
    cursor: 'pointer',
    textAlign: 'center',
    transition: 'font-size 0.8s ease',
    '& span': {
      fontSize: '14px',
    },
    '&:hover': {
      '& span': {
        fontSize: '17px',
      },
    },
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
  },
  cardTooltip: {
    width: '400px',
  },
  containerData: {
    verticalAlign: 'middle',
    justifyContent: 'center',
  },
  titleSumary: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  tooltipTitle: {
    marginBottom: '15px',
  },
  tooltipData: {
    textAlign: 'right',
    alignItems: 'right',
  },
}));

export default useStyles;
