import React from 'react';
import { useState, useEffect } from 'react';
import Upload from '../../img/UploadImg.png';
//import UploadButton from '../buttons/select_button.js';
import Check from '../../img/icono-arriba.svg';
import '../../css/preaprobado/preaprobado.css';

import { upload } from '../../utils/aws/s3';
import Dynamicore, { SERVICES } from '../../services/dynamicore';
import { AWS_ACCESS_KEY_ID_CORE, AWS_SECRET_ACCESS_KEY_CORE } from '../../settings/aws/index.js';
import AWS from 'aws-sdk';

//const REGION ='us-west-2';

AWS.config.update({
    accessKeyId: AWS_ACCESS_KEY_ID_CORE,
    secretAccessKey: AWS_SECRET_ACCESS_KEY_CORE
})

//const myBucket = new AWS.S3({
//    params: { Bucket: AWS_BUCKET_CORE},
//    region: REGION,
//})

const Uploadfile = (props) => {
  const {titulo, nameDocument, clientId, setSise, onFileUpload } = props;
  const hiddenFileInput = React.useRef(null);
  const [selected, setSelected] = useState(false);
  const [error, setError] = React.useState(false);
  
  const handleClick = e => {
    hiddenFileInput.current.click();
  };

  const handleFileInput = async (e) => {
    setSelected(true);
    const newnameDocument = nameDocument;
    const file = e.target.files[0];
    const files = e.target.files;

    if (files.length === 0) {
      setError(true);
      return;
    }

    setError(false);
    setSise(file.size);

    const ext = (file.name.split('.').pop() || '').toLowerCase();
    
    const s3File = await upload(
      `${'company/3047/people/'}${clientId+'/'}${new Date().getTime()}.${String(Math.random()).substring(
        2,
        7,
      )}${ext ? `.${ext.toLowerCase()}` : ''}`,
      file,
    );
    const people = await Dynamicore(SERVICES.PEOPLE).read( 
      {
        id: clientId,
      }
    );
    await Dynamicore(SERVICES.PEOPLE).update( 
      {
        ...people,
        pii: {
          ...people.pii,
          [newnameDocument]: [{
             url: s3File.Location,
             name: newnameDocument,
             active: true,
             uploaded: new Date().getTime(),
            }]
        },
      }
    );
    
    // Ejecutar la función callback proporcionada por el componente padre
    onFileUpload(files.length);
    
  }

  const handleDelete = (e) => {
    e.target.value = null;
    hiddenFileInput.current.value = null;
    setSelected(false);
    /*const newnameDocument = nameDocument.nameDocument;
    const file = e.target.files[0];
    const ext = (file.name.split('.').pop() || '').toLowerCase();

    const s3File = await upload(
      `${'company/3047/people/'}${clientId+'/'}${new Date().getTime()}.${String(Math.random()).substring(
        2,
        7,
      )}${ext ? `.${ext.toLowerCase()}` : ''}`,
      file,
    );
    const people = await Dynamicore(SERVICES.PEOPLE).read( 
      {
        id: clientId,
      }
    );
    await Dynamicore(SERVICES.PEOPLE).remove( 
      {
        ...people,
        pii: {
          ...people.pii,
          [newnameDocument]: [{
             url: s3File.Location,
             name: newnameDocument,
             active: true,
             uploaded: new Date().getTime(),
            }]
        },
      }
    );
    setSelected(false);*/
  }

  useEffect(() => {
  }, [selected]);

  return(
    <div className="row p-0 h-100 mb-md-2 align-items-center">
      <div className="upload_block col-lg-1 col-sm-1 pt-lg-3 pt-md-3 pt-sm-3">
        {selected ? (
          <img className="upload_img mb-1" src={Check} width='25' alt="Upload" />
          ) : (
          <img className="upload_img mb-1" src={Upload} width='25' alt="Upload" />
          )
        }
      </div>
      <div className="upload_block upload-text col-lg-7 col-sm-7 align-middle">
        <p className="upload-title fs-md-6 text-dark lh-1 pt-2 pt-md-1 mb-1"> {titulo} </p>
        <p className="mb-0 mt-2 mt-md-0">Seleccione un archivo o arrastre y suelte aquí</p>
      </div>
      <div className="upload_block col-lg-3 col-sm-3">
        {/** Empieza el boton */}
        <div>
          <div className='button-end mt-1'>
            <input 
              multiple
              class="d-none" 
              ref={hiddenFileInput} 
              type='file' 
              name='files' 
              onChange={handleFileInput}
            />
            {selected ? ( 
              <button className="card-button-sm fs-7 py-2" onClick={handleDelete}>
              Eliminar
              </button>
             ) : (
                <button className="card-button-sm fs-7 py-2" onClick={handleClick}>
                Seleccionar
                </button>
             )}
          </div>
        </div>
        {error && <p style={{ color: 'red' }}>Debe subir al menos un archivo</p>}
        {/* Termina el boton */}
      </div>
    </div>
  )
}

export default Uploadfile;