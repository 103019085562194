import { setPickedKeyValue } from './objects.js';
import { parseValue } from './strings.js';

const COL_SEPARATOR = ',';
const ROW_SEPARATOR = '\n';

/**
 * Convert array to CSV string
 *
 * @param {array} array Input array to convert
 * @param {string} separator Separator
 * @returns {string}
 */
function arrayToCsv(array, separator = COL_SEPARATOR) {
  return array
    .map((row) => (Array.isArray(row) ? row : [row])
      .map((col) => (typeof col === 'object')
        ? JSON.stringify(col)
        : col)
      .join(separator))
    .join(ROW_SEPARATOR);
}

/**
 *
 * @param {*} str
 * @param {*} hasHeaders
 * @param {*} separator
 * @returns
 */
function csvParse(str, hasHeaders = true, separator = COL_SEPARATOR) {
  const array = str
    .split(ROW_SEPARATOR)
    .filter((row) => !!row)
    .map((row) => row
      .split(separator)
      .map((col) => parseValue(col)));

  if (hasHeaders) {
    const headers = array.shift();

    return array
      .map((row) => {
        const obj = {};
        headers
          .forEach((key, index) => {
            setPickedKeyValue(obj, key, row[index]);
          });

        return obj;
      });
  }

  return array;
}

export {
  arrayToCsv,
  csvParse,
};
