import React from "react";
import MUICard from '../cards/card.jsx';
import {useState} from 'react';
//import imgProfileDevon from '../../img/Profile1.png';
//import imgProfileKristin from '../../img/Profile2.png';
//import imgProfileDarlene from '../../img/Profile3.png';
import BottomLine from '../../img/histories/bottom_line.png'
import Dynamic from '../../img/histories/dynamic.png'
import Camita from '../../img/histories/Camitas.png'
import Promty from '../../img/histories/promty-logo-white.png'

import AliceCarousel from 'react-alice-carousel';
import '../../css//alice_carousel.css';

const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 2 },
    };

const items = [
    <MUICard image={BottomLine}
      description={'El proceso fue rápido, sencillo y 100% en línea. Teníamos los fondos disponibles en menos de 24 horas.'} 
      name={'Eugenio Souto'} 
      job={' Director General'}
      alt={'The Bottom Line Shop'} />,

    <MUICard image={Dynamic}
      description={'Liberfin tiene excelentes servicios y la atención es de muy buena calidad. Muy recomendado'} 
      name={'Oswaldo López'} 
      job={'CEO'}
      alt={'Dynamicore'} />,

    <MUICard image={Camita}
      description={'Liberfin, es el mejor aliado para crecer tu negocio y hacer que las cosas pasen. Confiaron en mi y en mi negocio desde el día 1'} 
      name={'Michelle Reza'} 
      job={'Directora General'}
      alt={'Camita Bienestar'} />,

    <MUICard image={Promty}
      descriptionSmall={true}
      description={'Excelente herramienta financiera. El flujo para hacer la operación mas rápida y eficiente siempre ha sido un dolor de cabeza. Líberfin tiene un proceso súper sencillo, práctico, mejores tasas que muchas financieras, sin duda alguna, un excelente socio comercial para hacer crecer tu empresa!'} 
      name={'Fernando Moctezuma'} 
      job={'Director General'}
      alt={'ProMty'} />,
];

const SimpleSliderTwo = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const slidePrev = () => {
    activeIndex==0?(
    setActiveIndex(items.length-1))
    :setActiveIndex(activeIndex - 1);
  };
            
  const slideNext = () => {
    activeIndex==items.length-1?(
    setActiveIndex(0))
    : setActiveIndex(activeIndex + 1)
  }; 

  return(  
    <AliceCarousel 
      mouseTracking 
      items={items} 
      responsive={responsive} 
      controlsStrategy="alternate"
      disableDotsControls={true}
      infinite={true}
      className="ms-3"
    />
  );
}

export default SimpleSliderTwo;