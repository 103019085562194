import React from 'react';
import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {Auth} from 'aws-amplify';
import axios from 'axios';
import ButtonBlue from '../../components/buttons/button_blue';
import '../../css/landing2/cuentanos.css';
//import CustomModal from '../modals/modal.js';
import { DynamicFormRegister, Link,} from '../../components';
import {EMAIL_REGEXP, PHONE_REGEXP} from '../../constants/index';
import {
  API_CONTEXT,
  API_URL,
} from '../../settings';
import Dynamicore, { SERVICES } from '../../services/dynamicore';
import { getUser } from '../../utils/aws/cognito';
import { getUserInfo } from '../../functions/index.js';
import { signUpSchema } from '../../validations/schema/forms';
import { cuentanosSchema } from '../../validations/CuentanosValidation.js';
import { useLocation } from 'react-router-dom';
import useStyles from './modal.styles.js';
import { options } from './options.js';
import Modal from '@material-ui/core/Modal';
import Header from '../../components/header/header.jsx';
import getPeople from '../../services/dynamicore/processes/getPeople.js';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const DataEmpresa = () => {

  //const financiamiento = useSelector(state => state.financiamiento);
  //console.log(financiamiento);
  
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [datos, setDatos] = useState('');
  //const [isOpen, setIsOpen] = useState(false);
  const [stateCiec, setStateCiec] = useState('hide');
  const [statePassword, setStatePassword] = useState('hide');
  const [authValues, setAuthValues] = useState();
  const [errorcatch, setErrorcatch] = useState();
  const [isProcessing, setIsProcessing] = useState();
  const [isSendingCodeAgain, setIsSendingCodeAgain] = useState(false);
  const [dataempresa, setDataempresa] = useState({
    person: '',
    name: '',
    rfc: '',
    ciec: '',
    confirmciec: '',
    adress: '',
    no_int: '',
    no_ext: '',
    suburb: '',
    municipality: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    terms: '',
    buro: '',
  });
  const [input, setInput] = useState({
    person: '',
    company: '',
    rfc: '',
    ciec: '',
    confirmciec: '',
    adress: '',
    no_int: '',
    no_ext: '',
    suburb: '',
    municipality: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    terminos: '',
  });
  const [error, setError] = useState({
    person: '',
    company: '',
    rfc: '',
    ciec: '',
    confirmciec: '',
    adress: '',
    no_int: '',
    no_ext: '',
    suburb: '',
    municipality: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    terminos: '',
  });

  const [loading, setLoading] = useState(true);
  const [isUnconfirmed, setIsUnconfirmed] = useState();

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);

  const location = useLocation();

  const dataFactura = location.state; // Los datos de la factura vienen dentro de dataFactura.dataFactura

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitData = async (event) => {
    event.preventDefault();
    //console.log(event);
    let dataCompany = {
      person: event.target[0].value,
      name: event.target[1].value,
      adress: event.target[2].value,
      no_int: event.target[3].value,
      no_ext: event.target[4].value,
      zipcode: event.target[5].value,
      suburb: event.target[6].value,
      municipality: event.target[7].value,
      city: event.target[8].value,
      state: event.target[9].value,
      country: event.target[10].value,
      rfc: event.target[11].value,
      ciec: event.target[12].value,
      confirmciec: event.target[14].value,
      terms: event.target[16].checked,
      buro: event.target[17].checked,
    };

    setDatos(dataCompany.name);

    //console.log('dataCompany: ', dataCompany); //dataCompany es la que se consume
    const isValid = await cuentanosSchema.isValid(dataCompany);
    //console.log("es valido?",isValid);

    if ( isValid === true ) {

      setDataempresa({
        person: dataCompany.person,
        name: dataCompany.name,
        rfc: dataCompany.rfc,
        ciec: dataCompany.ciec,
        confirmciec: dataCompany.confirmciec,
        adress: dataCompany.adress,
        no_int: dataCompany.no_int,
        no_ext: dataCompany.no_ext,
        suburb: dataCompany.suburb,
        municipality: dataCompany.municipality,
        city: dataCompany.city,
        state: dataCompany.state,
        zipcode: dataCompany.zipcode,
        country: dataCompany.country,
        terms: dataCompany.terms,
        buro: dataCompany.buro,
      });

      //setIsOpen(true);
      handleOpen();
    }
  };

  const handleSubmitData = async function (values) {
    const e = new Error();
    let userType;
    let {
      attributes = [],
      code = [],
      username,
    } = values;
    const { password } = values;

    //console.log('handleSubmitData =>', values);

    if (isUnconfirmed) {
      code = code.join('');
    } else if (PHONE_REGEXP.test(username)) {
      username = `+52${username}`;
      userType = 'phone_number';
    } else if (EMAIL_REGEXP.test(username)) {
      userType = 'email';
    } else {
      e.code = 'UserTypeInvalid';
      throw e;
    }

    attributes = [{
      Name: userType,
      Value: username,
    }];

    try {
      //console.log("¿Is Unconfirmed: ",isUnconfirmed);
      if (isUnconfirmed) {
        await Auth.confirmSignUp(
          username,
          code,
        );
        await Auth.signIn(username, password);

        const { values = {} } = await Dynamicore(SERVICES.COMPANY).read();
        const [company = {}] = values;
        localStorage.setItem('company', JSON.stringify(company));

        const userInfo = await getUserInfo();
        //console.log("User Info: ", userInfo);
        const [clientId] = userInfo.objects.clients;
        const people = await getPeople(clientId);
        await Dynamicore(SERVICES.PEOPLE).update(
          {
            ...people,
            pii: {
              "email": username,
              "_a_financiar": dataFactura.dataFactura.porcentage,
              "tasa": dataFactura.dataFactura.tasa,
              "salud_financiera": dataFactura.dataFactura.salud,
              "plazo_a_financiar": dataFactura.dataFactura.plazo,
              "valor_de_la_factura": dataFactura.dataFactura.factura,
              "facturacion_anual_estimada": dataFactura.dataFactura.anual,
              "persona": dataempresa.person,
              "nombre_razon_social": dataempresa.name,
              "street": dataempresa.adress,
              "no_interior": dataempresa.no_int,
              "no_ext": dataempresa.no_ext,
              "colony": dataempresa.suburb,
              "municipio": dataempresa.municipality,
              "city": dataempresa.city,
              "state": dataempresa.state,
              "country": dataempresa.country,
              "zipcode": dataempresa.zipcode,
              "rfc": dataempresa.rfc.toUpperCase(),
            },
          }
        );
        //await Dynamicore('/internal/connector/3047/flows/54a3c2359d6d43598762bdacfde5ee0f').create(
        //  {
        //    id: clientId,
        //    status: "Pending",
        //    external_id: "None",
        //    client_type: 139,
        //    pd: 100,
        //    pii: {
        //      "email": username,
        //      "_a_financiar": dataFactura.dataFactura.porcentage,
        //      "tasa": dataFactura.dataFactura.tasa,
        //      "salud_financiera": dataFactura.dataFactura.salud,
        //      "plazo_a_financiar": dataFactura.dataFactura.plazo,
        //      "valor_de_la_factura": dataFactura.dataFactura.factura,
        //      "facturacion_anual_estimada": dataFactura.dataFactura.anual,
        //      "persona": dataempresa.person,
        //      "nombre_razon_social": dataempresa.name,
        //      "street": dataempresa.adress,
        //      "no_interior": dataempresa.no_int,
        //      "no_ext": dataempresa.no_ext,
        //      "colony": dataempresa.suburb,
        //      "municipio": dataempresa.municipality,
        //      "city": dataempresa.city,
        //      "state": dataempresa.state,
        //      "country": dataempresa.country,
        //      "zipcode": dataempresa.zipcode,
        //      "rfc": dataempresa.rfc.toUpperCase(),
        //    },
        //    modules: {"satKeys": {
        //      "rfc": dataempresa.rfc.toUpperCase(), 
        //      "ciec": dataempresa.ciec
        //      }
        //    }, 
        //  }
        //);

        const payload = {
          client: clientId,
          type: 'ciec',
          pas: dataempresa.ciec,
          cer: '',
          key: '',
          rfc: dataempresa.rfc || '',
        };
        console.log('Payload', payload);
        await Dynamicore(SERVICES.SAT_KEY).create(payload);

        //navigate('/success', {state: {datafactura, clientId: clientId}})
        navigate('/pre_aprob', {state: {dataFactura, clientId: clientId}})

        //Aqui se hace los calculos para saber si fue aprobada o no
        {/*
        const factura = datafactura.dataFactura.factura;
        console.log("Factura: ", factura);

        const porcentageFinanciar = datafactura.dataFactura.porcentage;
        console.log("Procentage a financiar: ", porcentageFinanciar);

        const calificacionBuro = 700;
        const deudaTotalVig = 5000000;
        const plazoMedioDeuda = 60;
        const interesAnualProm = 0.25;
        const formulaPago = ((interesAnualProm/12)*(1+(interesAnualProm/12))^plazoMedioDeuda)*deudaTotalVig/(((1+(interesAnualProm/12))^plazoMedioDeuda)-1);
        console.log("Formula Pago: ", formulaPago);

        const deudaActual = formulaPago*12+factura*porcentageFinanciar;
        console.log("Deuda Actual: ", deudaActual);

        //Hitorial de facturación
        const histFactVen = deudaTotalVig*12;

        const histFacComp = 55000000;

        const capacidaPago = deudaActual/(histFactVen-histFacComp);

        const buro = 2;
        const cp = 1;
        const cliente = 3;

        const calidficacionEmpr = (0.4*buro)+(0.4*cp)+(0.2+cliente);



        deudaActual > 1 ? navigate('/success', {state: {datafactura, clientId: clientId}}) : navigate('/refused');
        */}
      } else {
        await axios.post(
          `${API_URL}${SERVICES.USERS_WEBCLIENTS}`,
          {
            password,
            username,
            attributes,
          },
          { headers: { context: API_CONTEXT } },
        );
        //console.log("Pasamos el post EXITOSO");
        setIsUnconfirmed(true);
        setLoading(false);
        setAuthValues({ password, username, userType });
      }
    } catch ({ message }) {
      const user = await getUser(username);

      if (user?.status === 'UNCONFIRMED') {
        setIsUnconfirmed(true);
        setAuthValues({ password, username, userType });
        setErrorcatch('auth.UserNotConfirmedException');
        //console.log("Error: ", errorcatch);
      } else if (message === 'Request failed with status code 409') {
        setErrorcatch('El usuario ya existe, inicie sesión o ingrese otro email/teléfono');
        //console.log("Error: ", errorcatch);
      }
      else {
        setErrorcatch('auth.UnknownError');
        //console.log("Error: ", errorcatch);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const onInputChange = e => {
    const {name, value} = e.target;
    setInput(prev => ({
      ...prev,
      [name]: value,
    }));
  };
  const validateInput = e => {
    let {name, value} = e.target;
    setError(prev => {
      const stateObj = {...prev, [name]: ''};

      switch ( name ) {

        case 'person':
          if ( !value ) {
            stateObj[name] = 'Debe seleccionar una opción';
          } else if ( input.person === '' ) {
            stateObj[name] = 'Seleccione una opción';
          }
          break;

        case 'company':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.company === '' ) {
            stateObj[name] = 'Escriba un nombre valido';
          }
          break;
        
        case 'adress':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.adress === '' ) {
            stateObj[name] = 'Escriba una dirección valida';
          }
          break;
        
        case 'no_int':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.no_int === '' ) {
            stateObj[name] = 'Escriba un número valido';
          }
          break;
        
        case 'no_ext':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.no_ext === '' ) {
            stateObj[name] = 'Escriba un número valido';
          }
          break;

        case 'suburb':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.suburb === '' ) {
            stateObj[name] = 'Escriba una colonia valida';
          }
          break;
        
        case 'municipality': 
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.municipality === '' ) {
            stateObj[name] = 'Escriba una delegación o municipio valido';
          }
          break;
        
        case 'city':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.city === '') {
            stateObj[name] = 'Escriba una ciudad valida';
          }
          break;

        case 'state':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.state === '') {
            stateObj[name] = 'Escriba un estado valido';
          }
          break;
        
        case 'zipcode':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.zipcode === '') {
            stateObj[name] = 'Escriba un código valido';
          }
          break;

        case 'country':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.country === '') {
            stateObj[name] = 'Escriba una código valida';
          }
          break;
        
        case 'rfc':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.rfc === '' ) {
            stateObj[name] = 'Escriba un rfc valido';
          }
          break;

        case 'ciec':
          if ( !value ) {
            stateObj[name] = 'Escriba contraseña CIEC valida';
          } else if ( input.confirmciec && value !== input.confirmciec ) {
            stateObj['confirmciec'] = 'Las contraseñas CIEC no coinciden';
          } else {
            stateObj['confirmciec'] = input.confirmciec ? '' : error.confirmciec;
          }
          break;
        case 'confirmciec':
          if ( !value ) {
            stateObj[name] = 'Confirme contraseña CIEC valida';
          } else if ( input.ciec && value !== input.ciec ) {
            stateObj[name] = 'Las contraseñas CIEC no coinciden';
          }
          break;

        case 'terminos':
          if ( !value ) {
            stateObj[name] = 'Debe aceptar los terminos y condiciones';
          } else if ( input.terminos === '' ) {
            stateObj[name] = 'Debe aceptar los terminos';
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  useEffect(() => {
    // Cargar el estado anterior del componente desde localStorage cuando se monta el componente
    const dataEmpresa = localStorage.getItem('dataEmpresa');
    if (dataEmpresa) {
      setInput(JSON.parse(dataEmpresa));
    }
  }, []);

  useEffect(() => {
    // Guardar el estado actual del componente en localStorage cuando se actualiza el estado
    localStorage.setItem('dataEmpresa', JSON.stringify(input));
  }, [input]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [datos]);

  return (
    <>
    <Header />
    <section id="cuentanos">
      <div className="row mt-lg-5 mt-md-3 mt-sm-1 ms-0 me-0">
        <div className="col-lg-8 col-md-10 col-sm-12 px-5 mx-auto">
          <h4 className="cuentanos-tile mt-4 mb-3 px-2 px-md-0">Cuéntanos <span className="color-blue">de tu empresa</span></h4>
          <p className="company_subtitle">La información y privacidad de la misma es importante para nosotros. Consulta
            nuestro &nbsp;
            <a href="https://terms-privacity.s3.us-west-2.amazonaws.com/Aviso+de+Privacidad+Liberfin+v2.pdf" target="_blank" rel="noopener noreferrer">
              Aviso de Privacidad &nbsp;
            </a>
            &nbsp; para conocer el compromiso de Liberfin con tus datos.
          </p>
          <form className="w-100" onSubmit={submitData}>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <label className="pb-1">Persona Moral</label>
                  <br/>
                  <select
                    value={input.person}
                    onBlur={validateInput}
                    name="person"
                    onChange={onInputChange}
                    className="form-select"
                    required
                  >
                    <option value="">Seleccionar</option>
                    {/*<option value='fisica'>Fisica</option> */}
                    <option value="moral">Moral</option>
                  </select>
                  {error.person && <span className="err red-text mt-2">{error.person}</span>}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 margin-cols">
                <div className="form-group pl">
                  <label className="pb-1">Razón social de la empresa</label>
                  <br/>
                  <input
                    className="form-control input-text form-control-lg"
                    type="text"
                    name="company"
                    required
                    value={input.company}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  {error.company && <span className="err red-text mt-2">{error.company}</span>}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label className="pb-1">Calle</label>
                  <br/>
                  <input
                    className="form-control input-text form-control-lg"
                    type="text"
                    name="adress"
                    required
                    value={input.adress}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  {error.adress && <span className="err red-text mt-2">{error.adress}</span>}
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label className="pb-1">No. exterior</label>
                  <br/>
                  <input
                    className="form-control input-number form-control-lg"
                    type="number"
                    name="no_ext"
                    required
                    value={input.no_ext}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  {error.no_ext && <span className="err red-text mt-2">{error.no_ext}</span>}
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label className="pb-1">No. interior</label>
                  <br/>
                  <input
                    className="form-control input-number form-control-lg"
                    type="number"
                    name="no_int"
                    required
                    value={input.no_int}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  {error.no_int && <span className="err red-text mt-2">{error.no_int}</span>}
                </div>
              </div>
            </div>
            <div className="row mt-4">
            <div className="col-lg-4 col-md-4 margin-cols">
                <div className="form-group">
                  <label className="pb-1">Código Postal</label>
                  <br/>
                  <input
                    className="form-control input-text form-control-lg"
                    type="tel"
                    name="zipcode"
                    maxlength={5}
                    required
                    value={input.zipcode}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  {error.zipcode && <span className="err red-text mt-2">{error.zipcode}</span>}
                </div>
              </div>
              <div className="col-lg-4 col-md-4 margin-cols">
                <div className="form-group">
                  <label className="pb-1">Colonia</label>
                  <br/>
                  <input
                    className="form-control input-text form-control-lg"
                    type="text"
                    name="suburb"
                    required
                    value={input.suburb}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  {error.suburb && <span className="err red-text mt-2">{error.suburb}</span>}
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label className="pb-1">Delegación o Municipio</label>
                  <br/>
                  <input
                    className="form-control input-text form-control-lg"
                    type="text"
                    name="municipality"
                    required
                    value={input.municipality}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  {error.municipality && <span className="err red-text mt-2">{error.municipality}</span>}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-4 col-md-4 margin-cols">
                <div className="form-group">
                  <label className="pb-1">Ciudad</label>
                  <br/>
                  <input
                    className="form-control input-text form-control-lg"
                    type="text"
                    name="city"
                    required
                    value={input.city}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  {error.city && <span className="err red-text mt-2">{error.city}</span>}
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label className="pb-1">Estado</label>
                  <br/>
                  <select
                    value={input.state}
                    onBlur={validateInput}
                    name="state"
                    onChange={onInputChange}
                    className="form-select"
                    required
                  >
                    <option value="">Selecciona un estado</option>
                    {options.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {/*<input
                    className="form-control input-text form-control-lg"
                    type="text"
                    name="state"
                    required
                    value={input.state}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />*/}
                  {error.state && <span className="err red-text mt-2">{error.state}</span>}
                </div>
              </div>
              <div className="col-lg-4 col-md-4 margin-cols">
                <div className="form-group">
                  <label className="pb-1">País</label>
                  <br/>
                  <select
                    value={input.country}
                    onBlur={validateInput}
                    name="country"
                    onChange={onInputChange}
                    className="form-select"
                    required
                  >
                    <option value="">Seleccionar</option>
                    <option value="MX">México</option>
                  </select>
                  {/*<input
                    className="form-control input-text form-control-lg"
                    type="text"
                    name="country"
                    required
                    value={input.country}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />*/}
                  {error.country && <span className="err red-text mt-2">{error.country}</span>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="column-12 mt-4">
                <div className="form-group">
                  <label className="pb-1">RFC de la empresa</label>
                  <input
                    className="form-control input-text form-control-lg"
                    type="text"
                    name="rfc"
                    maxlength={13}
                    required
                    value={input.rfc}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  {error.rfc && <span className="err red-text mt-2">{error.rfc}</span>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 mt-4">
                <div className="form-group">
                  <label className="pb-1">Contraseña CIEC</label>
                  <br/>
                  <div className="input-group mb-3">
                    <input
                      className="form-control form-control-lg border-end-0 input-text input-pass"
                      type={(stateCiec !== 'hide') ? 'text' : 'password'}
                      name="ciec"
                      id="ciec"
                      required
                      value={input.ciec}
                      onBlur={validateInput}
                      onChange={onInputChange}
                    />
                    <button class="btn btn-outline-primary border-start-0 " type="button" id="view-ciec"
                            onClick={(e) => setStateCiec((stateCiec === 'hide') ? 'show' : 'hide')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                           class={(stateCiec !== 'hide') ? 'bi bi-eye d-none' : 'bi bi-eye'}
                           viewBox="0 0 16 16">
                        <path
                          d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                        <path
                          d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                           class={(stateCiec === 'hide') ? 'bi bi-eye-slash d-none' : 'bi bi-eye-slash'}
                           viewBox="0 0 16 16">
                        <path
                          d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                        <path
                          d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                        <path
                          d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                      </svg>
                    </button>
                  </div>
                  {error.ciec && <span className="err red-text">{error.ciec}</span>}
                </div>
              </div>
              <div className="col-12 col-md-6 mt-4">
                <div className="form-group pl">
                  <label className="pb-1">Confirmar contraseña CIEC</label>
                  <br/>
                  <div class="input-group mb-3">
                    <input
                      className="form-control form-control-lg border-end-0 input-text input-pass"
                      type={(statePassword !== 'hide') ? 'text' : 'password'}
                      name="confirmciec"
                      id="confirmciec"
                      autocomplete="current-password"
                      required
                      value={input.confirmciec}
                      onBlur={validateInput}
                      onChange={onInputChange}
                    />
                    <button class="btn btn-outline-primary border-start-0 " type="button" id="view-password"
                            onClick={(e) => setStatePassword((statePassword === 'hide') ? 'show' : 'hide')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                           class={(statePassword !== 'hide') ? 'bi bi-eye d-none' : 'bi bi-eye'}
                           viewBox="0 0 16 16">
                        <path
                          d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                        <path
                          d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                           class={(statePassword === 'hide') ? 'bi bi-eye-slash d-none' : 'bi bi-eye-slash'}
                           viewBox="0 0 16 16">
                        <path
                          d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                        <path
                          d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                        <path
                          d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                      </svg>
                    </button>
                  </div>
                  {error.confirmciec && <span className="err red-text">{error.confirmciec}</span>}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 col-md-6 text-end mb-3">
                <div class="form-check text-end check-mobile padd-check float-md-end  ms-0 ms-md-5">
                  <input
                    class="form-check-input form-check-input-lg ms-0 check-border"
                    type="checkbox"
                    id="flexCheckCheckedTerminosYcondiciones"
                    required
                    value={input.terminos}
                    onChange={onInputChange}
                    name="terminos"/>
                  <label class="form-check-label  terminos-font ps-2" for="flexCheckCheckedTerminosYcondiciones">
                    Acepto Términos y Condiciones
                  </label>
                  {error.terminos && <span className="err red-text">{error.terminos}</span>}
                </div>
              </div>
              <div className="col-sm-6 col-md-6 ps-3 ps-md-3 mb-3">
                <div class="form-check text-start ms-2 mx-md-4">
                  <input
                    class="form-check-input form-check-input-lg check-border"
                    type="checkbox"
                    value="buro"
                    name="buro"
                    id="flexCheckCheckedBuroDeCredito"
                    required
                  />
                  <label class="form-check-label terminos-font " for="flexCheckCheckedBuroDeCredito">
                    Acepta consulta de buró de crédito
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="column-12 center">

                <ButtonBlue onSumbit={() => submitData()}
                            styleClass={'btn btn-blue btn-lg text-white fs-header-9 btn-login width-btutton'}
                            spanClass={'span_icon ps-2'} iconColor={'icon-button-blue'} value={'  Continuar'}/>

              </div>
            </div>
            <div className="row">
              <div className="column-12 center mt-4">
                <a className="cancel_button" href="/" alt="cancelar"><p className="cancelar">Cancelar</p></a>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/*<CustomModal open={isOpen} close={() => setIsOpen(false)} cliente={datos} datafactura={dataFactura} dataempresa={dataempresa}></CustomModal>*/}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <div className="row">
            <div className="col-lg-10 col-md-10 mx-auto px-4 px-md-4">
              <h4 className={classes.fontTitle}>Hola {datos}, mientras calculamos <span className="color-blue">una mejor tasa compártenos tu mail y teléfono</span>
              </h4>
              <p className="contact_subtitle contact-text mb-2 pt-3 fs-6 ms-2">Está información será usada para crear tu
                usuario en la plataforma.</p>
              <p className="contact_subtitle contact-text mb-2 pt-3 fs-6 ms-2">Por favor, ingresa el codigo que recibiras para completar tu registro.</p>
              {loading ? (
                <div>
                <DynamicFormRegister
                  actions={[
                    {
                      color: 'primary',
                      label: isUnconfirmed
                        ? 'Confirmar'
                        : 'Continuar',
                      processing: isProcessing,
                      type: 'submit',
                      typeButton: 'login',
                    },
                  ]}
                  fields={[
                    {
                      type: 'space',
                      height: 15,
                    },
                    {
                      disabled: isUnconfirmed,
                      label: 'Teléfono o correo electrónico',
                      name: 'username',
                      type: 'text',
                      display: { breakpoints: { md: 6, sm: 12, xs: 12 } },
                    },
                    {
                      disabled: isUnconfirmed,
                      label: 'Contraseña',
                      name: 'password',
                      type: 'password',
                      display: { breakpoints: { md: 6, sm: 12, xs: 12 } },
                      settings: {canReveal: true},
                    },
                    {
                      type: !isUnconfirmed ? 'space' : 'hidden',
                    },
                  ]}
                  handleSubmitData={handleSubmitData}
                  size="medium"
                  validationSchema={signUpSchema}
                  id="signUpForm"
                />
                {errorcatch && (<Box mt={2}>
                  <Alert
                    children={t(errorcatch)}
                    severity="error"
                  />
                </Box>)}
                </div>
                ) : (
                  <div></div>
                )
              }
            </div>
          </div>
          {authValues &&
           (
            <div className="row">
              <div className="col-12 col-md-9 mx-auto px-4 px-md-4 pt-4">
                <DynamicFormRegister
                  actions={[
                    {
                      color: 'primary',
                      label: 'Confirmar',
                      processing: isProcessing,
                      type: 'submit',
                      typeButton: 'login',
                    },
                  ]}
                  fields={[
                    //...(new Array(4).fill()).map((_, index) => ({
                    ...(new Array(6).fill()).map((_, index) => ({
                      display: {
                        align: 'center',
                        textAlign: 'center',
                        breakpoints: { md: 2, sm: 2, xs: 2 },
                      },
                      events: {
                        onChange: ({ value }) => {
                          let element;
                          //if (index < 3) {
                          if (index < 5) {
                            [element] = document.getElementsByName(`code.${index + 1}`);
                            if(value){
                              element.select();
                              element.focus();
                            }
                          } else {
                            element = document.querySelector('button[type="submit"]');
                            element.focus();
                          }
                          //element.focus();
                        },
                        onKeyPress: (event) => {
                          if (event.which < 48 || event.which > 57) {
                            event.preventDefault();
                          }
                        },
                      },
                      name: `code.${index}`,
                    })),
                    {
                      type: 'space',
                      height: 10,
                    },
                    {
                      type: 'description',
                      label: <>
                        ¿Aún no has recibido tu código?
                        <br />
                        {isSendingCodeAgain
                          ? <div style={{ color: 'green' }}>Se a enviado el código</div>
                          : <Link
                            children="Solicita uno nuevo"
                            onClick={async () => {
                              setIsSendingCodeAgain(true);
                              await Auth.resendSignUp(authValues.username);
                              setTimeout(() => {
                                setIsSendingCodeAgain(false);
                              }, 3000);
                            }}
                            variant='function'
                          />
                        }
                      </>,
                    },
                  ]}
                  handleSubmitData={handleSubmitData}
                  size="medium"
                  initialValues={authValues}
                  id="frmConfirmSignUp"
                />
                
                </div>
              </div>
            )
          } 
        </div>
      </Modal>
    </section>
    </>
  );
};
export default DataEmpresa;
