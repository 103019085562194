import React from 'react';
import '../../../css/historias.css';

//import SimpleSlider from '../../slider/slider.js';
import SimpleSliderTwo from '../../../components/slider/SimpleSliderTwo';

//import { Container, Grid } from '@mui/material';



const Historias = () =>{
  return(
    <section id="historias">
      <div className='container row ps-2 pe-0 ms-auto me-auto'>
        <div className='col-12 col-lg-4 offset-md-1'>
          <div className="form text-histories ps-4 ps-md-0 ps-lg-4 ms-2 ms-md-0 ms-lg-2 pe-0 pe-md-5 pe-lg-0">
              <h4 className="titulo mb-2  ">Conoce las
                  <br/>
                  <span className='color-blue'> historias de éxito</span>
              </h4>
              <p className="sub_titulo d-none d-md-block fs-6">Liberfin ofrece un proceso rápido, seguro y transparente que busca democratizar el acceso a financiamiento para las PYMEs en México </p>
              <p className="sub_titulo d-block d-md-none fs-6">Clientes que trabajan con nosotros incrementan sus ventas hasta un 25%, de acuerdo al Banco Interamericano de desarrollo.</p>
          </div>
        </div>
        <div className='col-12 col-lg-7 d-none d-lg-block ps-5 pe-5 pt-2 text-center' id="slider1">
          <SimpleSliderTwo />
        </div>
        <div className='col-12 col-sm-12 d-block d-lg-none pt-2 pe-sm-5 text-center' id="slider2">
          <SimpleSliderTwo />
        </div>    
      </div>
    </section>
  )
}

export default Historias;