import React from 'react';
import '../../css/success/success.css';
import Clock from '../../img/Reloj.png';
import DashHeader from '../../components/header/dash_header.jsx';

function RevitionView() {
    return(
        <>
        <DashHeader />
        <div className="mb-5">
            <section id="sectionrefused" className='mt-md-5 pt-md-4'>
                <div className="row align-items-center text-center mb-5">
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className="text-center align-items-center ps-md-3 pe-md-3 ps-lg-5 pe-lg-5 w-50 mx-auto">
                            <h4 className="rechazado mb-5">¡Nos falta <span className='color-blue'>poco!</span></h4>
                            <p className="sub_titulo text-center">
                                Tu proceso de solicitud de factoraje está en revisión. Validaremos tu información para ofrecerte una mejor tasa.
                            </p>
                            <p className="sub_titulo text-center">
                                Una vez validado, te haremos llegar el link para firma de contrato y pagaré al correo que nos proporcionaste.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center text-center">
                    <div className='col-sm-12 col-md-12 col-lg-12 '>
                        <img src={Clock} width="350" height="auto" alt="Clock" className='mt-1'/>
                    </div>
                </div>
            </section>
        </div>
        </>
    );
}

export default RevitionView;