import React from 'react';
import {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Section from './sectionone/section.jsx';
import Financiamiento from './financiamiento/financiamiento.jsx';
import Liquidez from './liquidez/liquidez';
import Historias from './historias/historias';
//import Stores from './stores/stores';
import BgImage from '../../img/bg_index.png';
import Header from '../../components/header/header.jsx';

function Index() {

    const navigate = useNavigate();
    const [sessionExist, setSessionExist] = useState();

    const checkAuth = async () => {
        try {
          const currentSession = await Auth.currentAuthenticatedUser();
          return currentSession;
        } catch (e) {
          return e;
        }
      }
    
      useEffect(() => {
        async function checkSession() {
          const session = await checkAuth();
          if (session !== 'The user is not authenticated') {
            setSessionExist(true);
          } else {
            setSessionExist(false);
          }
        }
        checkSession();
        window.scrollTo(0, 0);
      }, []);

    useEffect(() => {
        if (sessionExist === true) {
            navigate('/dashboard');
        }
    }, [sessionExist]);
    

    return(
        <>
        <Header />
        <div className="bg-index-section"style={{ 
            backgroundImage: `url("`+BgImage+`")` 
          }} >
            <Section />
            <Financiamiento />
            <Liquidez />
            <Historias />
            {/*<Stores />*/}
        </div>
        </>
    )
}

export default Index;