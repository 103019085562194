import { Link as RouterLink } from 'react-router-dom';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Typography,
  withWidth,
} from '@material-ui/core';

import { Divider } from '../../components';
import { pickValue } from '../../utils/objects';

import {
  // CaptchaControl,
  CardSelector,
  // CodeControl,
  // ColorControl,
  // CronControl,
  // DateControl,
  // DocumentControl,
  // FileControl,
  // GoogleMapsControl,
  // HtmlControl,
  // JsonControl,
  KeyValueControl,
  // ListControl,
  NumberControl,
  // SelectControl,
  // SliderControl,
  // SwitchControl,
  TextControl,
  ButtonControl,
} from './controls';

const CONTROL = {
  // CAPTCHA: 'captcha',
  CARDSELECTOR: 'cardselector',
  CHECKBOX: 'checkbox',
  CODE: 'code',
  COLOR: 'color',
  CRON: 'cron',
  DATE: 'date',
  DOCUMENT: 'document',
  // GOOGLE_MAPS: 'googlemaps',
};

const CONTROLS = {
  // [CONTROL.CAPTCHA]: CaptchaControl,
  [CONTROL.CARDSELECTOR]: CardSelector,
  // [CONTROL.CODE]: CodeControl,
  // [CONTROL.COLOR]: ColorControl,
  // [CONTROL.CRON]: CronControl,
  // [CONTROL.CHECKBOX]: SwitchControl,
  // [CONTROL.DATE]: DateControl,
  // 'datepicker': DateControl,
  divider: '',
  // [CONTROL.DOCUMENT]: DocumentControl,
  email: TextControl,
  // file: FileControl,
  // [CONTROL.GOOGLE_MAPS]: GoogleMapsControl,
  hidden: TextControl,
  // html: HtmlControl,
  // json: JsonControl,
  keyvalue: KeyValueControl,
  // list: ListControl,
  number: NumberControl,
  password: TextControl,
  // select: SelectControl,
  // slider: SliderControl,
  // switch: SwitchControl,
  text: TextControl,
  textarea: TextControl,
};

function getControl({ type }) {
  return CONTROLS[type] || TextControl;
}

function DynamicFormRegister(props) {
  const {
    actions,
    fields,
    handleSubmitData,
    id,
    initialValues,
    spacing,
    validationSchema,
    width,
    display,
  } = props;

  async function onSubmit(values, formikHelpers) {
    const {
      setErrors,
      setStatus,
      setSubmitting,
    } = formikHelpers;

    try {
      await setSubmitting(true);
      await handleSubmitData(validationSchema.cast(values));

      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setErrors({ submit: error.message });
    } finally {
      setSubmitting(false);
    }
  }

  if (!Array.isArray(fields)) {
    const Control = getControl(fields);

    return <Control
      {...fields}
      size={props.size}
    />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const {
          errors,
          handleBlur,
          handleChange,
          handleReset,
          handleSubmit,
          setFieldTouched,
          setFieldValue,
          touched,
          values,
        } = formikProps;

        return (
          <Form onReset={handleReset} onSubmit={handleSubmit} id={id} display={display}>
            <Grid container spacing={spacing}>
              {fields
                .map((item, index) => {
                  const Control = getControl(item);
                  const {
                    display: {
                      size = props.size,
                    } = {},
                  } = item;

                  let {
                    display: {
                      breakpoints = { md: 12 },
                    } = {},
                  } = item;

                  switch (item.type) {
                    // case CONTROL.CAPTCHA:
                    case CONTROL.CARDSELECTOR:
                    // case CONTROL.DOCUMENT:
                      breakpoints = {
                        lg: 12,
                        md: 12,
                        sm: 12,
                        xl: 12,
                        xs: 12,
                      };
                      break;

                    default:
                  }

                  if (item.type === 'actions') {
                    return (<Grid key={index} item {...breakpoints}>
                      {item?.settings?.options.map((option, index) => (
                        <IconButton
                          children={option.icon}
                          disabled={option.disabled}
                          key={index}
                          size="small"
                          onClick={option.fn}
                        />
                      ))}
                    </Grid>);
                  }

                  if (item.type === 'description') {
                    return (<Grid key={index} item {...breakpoints} style={item.styleCont}>
                      <Typography
                        align="justify"
                        children={item.label}
                      />
                    </Grid>);
                  }

                  if (item.type === 'list') {
                    return (<Grid key={index} item {...breakpoints} style={item.styleCont}>
                      <Typography
                        align="justify"
                      >
                        <ul
                          align="justify"
                          children={item.label}
                          style={{ marginLeft: '20px' }}
                        >
                          {item.label.map((_item, index) => {
                            return (
                              <li
                                children={_item}
                                key={index}
                              />
                            );
                          })}
                        </ul>
                      </Typography>
                    </Grid>);
                  }

                  if (item.type === 'link') {
                    const linkProps = {};
                    if (typeof item.to === 'function') {
                      linkProps.onClick = item.to;
                      linkProps.to = '#';
                    } else {
                      linkProps.to = item.to;
                    }

                    return (<Grid key={index} item {...breakpoints} style={item.styleCont}>
                      <Link
                        children={item.label}
                        color={item.color}
                        component={RouterLink}
                        variant="body2"
                        {...linkProps}
                      />
                    </Grid>);
                  }

                  if (item.type === 'link_out') {
                    return (<Grid key={index} item {...breakpoints} style={item.styleCont}>
                      <a
                        children={item.label}
                        className={item.className}
                        href={item.to}
                        rel="noreferrer"
                        style={item.style}
                        target="_blank"
                      />
                    </Grid>);
                  }

                  if (item.type === 'btn_link_submit') {
                    return (<Grid key={index} item {...breakpoints} style={item.styleCont}>
                      <Button
                        style={item.style}
                        color={item.color}
                        disabled={item.processing}
                        fullWidth
                        size="large"
                        variant="contained"
                        onClick={() => item.fn()}
                      >
                        {item.processing && (<CircularProgress
                          size={18}
                          style={{ marginRight: 5 }}
                        />)}
                        {item.label}
                      </Button>
                    </Grid>);
                  }

                  if (item.type === 'space') {
                    return (<Grid key={index} item {...breakpoints} style={{ width: '100%' }}>
                      <div style={{ height: `${width === 'md' || width === 'lg' ? item.height : 0}px` }}>&nbsp;</div>
                    </Grid>);
                  }

                  if (item.type === 'line') {
                    return (<Grid key={index} item {...breakpoints} style={{ width: '100%' }}>
                      <div style={{ width: '100%', height: '1px', backgroundColor: '#0eb0f1' }}>&nbsp;</div>
                    </Grid>);
                  }

                  if (item.type === 'offset') {
                    return breakpoints[width] !== 12 ? (<Grid
                      item
                      key={index}
                      {...breakpoints}
                    />) : null;
                  }

                  if (item.type === 'divider') {
                    return (<Grid
                      children={<Divider children={item.label} />}
                      key={index}
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xl={12}
                      xs={12}
                    />);
                  }

                  if (item.type === 'document') {
                    return (<Grid
                      key={index}
                      item
                      {...breakpoints}
                      lg={6}
                      md={6}
                      sm={12}
                      xl={12}
                      xs={12}
                    >
                      <Control
                        {...item}
                        key={`${item.name || ''}_${index}`}
                        error={pickValue(errors, item.name)}
                        helperText={pickValue(errors, item.name)}
                        size={size}
                        value={pickValue(values, item.name)}
                        onBlur={handleBlur}
                        onFocus={item.onFocus}
                        onChange={(event) => {
                          handleChange(event);

                          if (!pickValue(touched, item.name)) {
                            setFieldTouched(item.name, true);
                          }
                        }}
                        useSetFieldValues={[setFieldValue, values]}
                      />
                    </Grid>);
                  }

                  return item.type === 'hidden'
                    ? (<></>)
                    : (<Grid key={index} item {...breakpoints}>
                      <Control
                        {...item}
                        key={`${item.name || ''}_${index}`}
                        error={pickValue(errors, item.name)}
                        helperText={pickValue(errors, item.name)}
                        size={size}
                        value={pickValue(values, item.name)}
                        // FIXME: se le estan pasando dos valores
                        // onBlur={handleBlur, item.onBlur}
                        onBlur={item.onBlur}
                        onFocus={item.onFocus}
                        onChange={(event) => {
                          handleChange(event);

                          /*// FIXME: Mover al events: { onChange }
                          if (item.validate === 'number_phone') {
                            item.onChange(values[item.name], item.nextFieldFocus);
                          }
                          // FIXME: Mover al events: { onChange }
                          if (item.validate === 'code_otp') {
                            item.onChange(item.name, item.index, item.indexMax);
                          }*/

                          /*// TODO: Delete and test
                          if (!pickValue(touched, item.name)) {
                            setFieldTouched(item.name, true);
                          }*/
                        }}
                        useSetFieldValues={[setFieldValue, values]}
                      />
                    </Grid>);
                })}
            </Grid>

            {actions && actions
              .filter(_ => !_.hidden)
              .map((action, index) => (
                <Box key={index} mt={2} style={action.style} className={action.className}>
                  {action.typeButton === 'login' ? (
                    <ButtonControl
                      label={action.label}
                      color={action.color}
                      processing={action.processing}
                      fullWidth
                      size="large"
                      type={'submit'}
                      variant="contained"
                    />
                  ) : (
                    <Button
                      color={action.color}
                      disabled={action.processing}
                      fullWidth
                      size="large"
                      type={action.type}
                      variant="contained"
                    >
                      {action.processing && (<CircularProgress
                        size={18}
                        style={{ marginRight: 5 }}
                      />)}
                      {action.label}
                    </Button>
                  )}
                </Box>
              ))}

          </Form>
        );
      }}
    </Formik>
  );
}

const propFields = PropTypes.shape({
  display: PropTypes.shape({
    breakpoints: PropTypes.shape({
      lg: PropTypes.number,
      md: PropTypes.number,
      sm: PropTypes.number,
      xl: PropTypes.number,
      xs: PropTypes.number,
    }),
    size: PropTypes.oneOf([
      'small',
      'medium',
    ]),
  }),
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(CONTROLS)),
  settings: PropTypes.shape({
    emptyElement: PropTypes.string,
    format: PropTypes.oneOf([
      'clabe',
      'credit_card',
      'credit_card_expire_timr',
      'currency',
      'mx_phone',
      'percentage',
    ]),
    max: PropTypes.number,
    min: PropTypes.number,
    multiline: PropTypes.bool,
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
        name: PropTypes.string,
      })),
      PropTypes.func,
    ]),
    rows: PropTypes.number,
    rowsMax: PropTypes.number,
    step: PropTypes.number,
  }),
});

DynamicFormRegister.defaultProps = {
  fields: [],
  handleSubmitData: () => {
  },
  id: `dynamic_form_${Math.floor(Math.random() * 10000)}`,
  initialValues: {},
  spacing: 2,
  size: 'small',
  validationSchema: Yup.object(),
};

DynamicFormRegister.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.oneOf(['primary', 'secondary']),
    hidden: PropTypes.bool,
    label: PropTypes.string,
    type: PropTypes.oneOf(['submit']),
    processing: PropTypes.bool,
  })),
  fields: PropTypes.oneOfType([
    PropTypes.arrayOf(propFields),
    propFields,
  ]),
  handleSubmitData: PropTypes.func,
  id: PropTypes.string,
  initialValues: PropTypes.object,
  spacing: PropTypes.number,
  size: PropTypes.oneOf([
    'small',
    'medium',
  ]),
  validationSchema: PropTypes.object,
  width: PropTypes.string,
};

export default withWidth()(DynamicFormRegister);
