import { useEffect, useState } from 'react';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { w3cwebsocket } from 'websocket';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
//import { CheckCircle } from '@material-ui/icons';
//import Money from '../../img/Panel/money.png';
//import Flecha from '../../img/Panel/Vector.png';
import { getCurrentSessionData } from '../../utils/aws/cognito';
//import { getSettings } from '../../utils/settings.js';
import { WEBSOCKET_URL } from '../../settings/index.js';
import Page from '../../components/page/Page.jsx';
import Dynamicore, { getStaticData, SERVICES, STATIC_DATA } from '../../services/dynamicore/index.js';
import { getUserInfo } from '../../functions/index.js';
import getPeople from '../../services/dynamicore/processes/getPeople.js';
//import LocalServices from '../../services/local/index.js';
import Modal from '../../components/Modal.jsx';
import { dateFormat } from '../../utils/formater.js';
import { numberFormat } from '../../utils/formater.js';
import DashHeader from '../../components/header/dash_header.jsx';
import ButtonBlue from '../../components/buttons/button_blue';
import Saludos from '../../img/Panel/saludos.png';
import Wallet from '../../img/Panel/wallet.png';
import Cash from '../../img/Panel/cash.png';
import { upload } from '../../utils/aws/s3';
import { arrayToCsv  } from '../../utils/csv';
import CircularProgress from '@mui/material/CircularProgress';
import AddAccountForm from './AddAccount/AddAccount.jsx';
import AddMovementForm from './AddMovement/AddMovement.jsx';
import useStyles from './styles';

function DashboardView({ title }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [modal, setModal] = useState();
  const [razonsocial, setRazonsocial] = useState();
  const [accountId, setAccountId] = useState();
  const [clientStatus, setClientStatus] = useState();
  const [items, setItems] = useState();
  const [totalExpectedDisbursed, setTotalExpectedDisbursed] = useState(0);
  const [wallet, setWallet] = useState({});
  const [infoPay, setInfoPay] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [people, setPeople] = useState({});
  const [exitPay, setExitPay] = useState(false);
  const [isNewAccount, setIsNewAccount] = useState(false);
  const [totalSelected, setTotalSelected] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [clientData, setClientData] = useState();
  const [catalogs, setCatalogs] = useState({});
  const [isLoading, setIsLoading] = useState();
  const [totalSendStatements, setTotalSendStatements] = useState();
  const [payInProcess, setPayInProcess] = useState(false);

  const getData = async function () {
    try {
      const userInfo = await getUserInfo();
      const [clientId] = userInfo.objects.clients;
      const people = await getPeople(clientId);
      //buscar el estatus para que si es preautorizado enviar a la vista de /validation_statements
      const razon = people?.pii?.nombre_razon_social;

      const { json_rol: { Statement = [] } } = userInfo;
      const resources = {};
      Statement.forEach(({ Resource = '', Effect = '' } = {}) => {
        const [, , type, id] = Resource.split(':');

        if (!resources[type]) {
          resources[type] = [];
        }

        if (Effect === 'Allow') {
          resources[type].push(parseInt(id));
        }
      });
      const [accountId = null] = resources.account || [];

      const { values: accountsDestination = [] } = await Dynamicore(SERVICES.ACCOUNTS_DESTINATION)
      .read({
        enabled: 1,
        client: clientId,
      });
      //console.log('accountsDestination=>', accountsDestination);

      const objCatalogs = {};
      objCatalogs.accountsDestination = accountsDestination;


      const accounts2 = await Dynamicore(SERVICES.LIBERFIN_GET_ACCOUNTS)
      .read({
        client: clientId,
      });
      //console.log('accounts2', accounts2.values.data.accounts);

      //console.log('accountId en dashboard=>', accountId)
      //console.log('people en dashboard =>', people);
      //if (people.status === 'Pending') {
      //  navigate('/pending')
      //} else 
      //if (people.status === 'Accepted') {
      //  navigate('/validation_statements')
      //} else
      if (people.status === 'Active' || people.status === 'Accepted') {

        const hasAssets = accounts2.values.data.accounts.some(item => item.type === 'assets');
        //console.log('hasAssets=>', hasAssets);
        if(hasAssets){
          //console.log('Tiene wallet');
          const userWallet = accounts2.values.data.accounts.find(item => item.type === 'assets');
          //console.log('userWallet==>', userWallet);
          setWallet(userWallet)
        } else {
          //console.log('No Tiene wallet');
          setWallet({amount: 0})
        }

        const statements = accounts2.values.data.accounts.filter(item => item.type !== "assets").map((item) => ({
          id: item?.properties?.bill_id.split("-")[0] || item.id,
          fecha_emision: dateFormat(item?.properties?.fechaemision),
          monto: item?.properties?.expected_disbursed,
          tasa: numberFormat(item.config.interest_rate / 100, '%'),
          interest: item?.properties?.expected_disbursed - item?.properties?.principal_disbursed,
          totalStatement: item?.properties?.principal_disbursed,
          estatus: getStatus(item?.status) || 'Sin estatus',
          account: item.id,
          last_payment_date: item?.last_payment_date,
        }));
        //console.log('statements =>', statements);
        //console.log('accounts.values', accounts.values);
        const expectedDisbursed = accounts2.values.data.accounts
          .filter(item => item.type === "liabilities" && item.status === "approved")
          .reduce((total, item) => total + item.properties.principal_disbursed, 0);
          //console.log('expectedDisbursed', expectedDisbursed);

        //const availableCredit = 0;

        //console.log('amortization: ->', amortization);

      
        //let nextAmountPayment = 0;
        //let amountPaid = 0;
        //const balance = 0;

        {/*const amortizationTable = (amortization.rows || []).map((item, index) => {
          let nextPayment = false;
          const bolPayOut = item?.principal?.paid === item?.principal?.expected;

          if (!bolPayOut) {
            nextPayment = true;
            nextAmountPayment = (item?.principal?.expected - item?.principal?.paid > 0) ? item?.principal?.expected : 0;
          } else {
            setIndexNextPayment(index + 1);
          }

          amountPaid = accounts.amount;

          return {
            amount: bolPayOut ? item?.principal?.paid : item?.principal?.expected,
            amountPaid,
            availableCredit,
            balance,
            bolPayOut,
            datePayment: item.date,
            id: item.cycle,
            nextAmountPayment,
            nextPayment,
            numPayment: item.cycle,
            numPayments: (amortization.rows || []).length,
            paid: item?.principal?.paid,
            statusCredit,
          };
        });
        */}

        //console.log('amortizationTable: ->', amortizationTable);

        //Asignar valores
        //setState(accounts);

        setRazonsocial(razon);
        setClientStatus(people.status);
        setAccountId(accountId);
        setItems(statements);
        setTotalExpectedDisbursed(expectedDisbursed);
        setPeople({
          client_id: people.id,
          company: people.company,
        });
        setClientData(people);
        setCatalogs(objCatalogs);
      } else if (people.status === 'Pending') {
        navigate('/pending')
      }

    } catch (error) {
      console.log('error :->', error);
    }
  };

  function getStatus(status) {
    if (status === 'approved'){
      const approved = 'Aprobado';
      return approved;
    } else if (status === 'pending') {
      const pending = 'Pendiente';
      return pending;
    } else if (status === 'closed') {
      const closed = 'Pagado';
      return closed;
    } else {
      const noStatus = 'Sin estatus'
      return noStatus;
    };
  };


  const handleSelectOneItemData = (itemId, amount, account) => {
    if (!selectedItems.some((item) => item.id === itemId)) {
      setSelectedItems((prevSelected) => [
        ...prevSelected,
        { id: itemId, amount: amount, account: account },
      ]);
    } else {
      setSelectedItems((prevSelected) =>
        prevSelected.filter((item) => item.id !== itemId),
      );
    }
  };

  const handleOpenModalPayClick = function (array) {
    // Lógica para pagar el elemento con el ID proporcionado
    console.log('pago=>', array)
    setModal({
      open: true,
      title: 'Pago',
      view: 'Pago',
    });
    setInfoPay(array);
  };

  const handlePay = async function (array) {
    setTotalSendStatements(array.length);
    try {
      const referenceDate = new Date();
      const arrayValues = array.map(item => [
          1,
          item.account, //id del producto/ cuenta
          item.amount, // el monto
          '',
          '',
          referenceDate,
          {},
        ]);

      const filename = `liberfin_movements_${new Date().getTime()}.csv`;
      await upload(
        `company/${people.company}/batch/${filename}`,
        arrayToCsv(arrayValues, '|'),
      );

      await Dynamicore(SERVICES.ACCOUNTS_MASSIVE_MOVEMENTS) ///private/accounts/massivemovements
        .create({
          client: people.client_id,
          execution_at: new Date(),
          input_csv: filename,
          operation: 98, //98
        });
      //setAccountId();
      setPayInProcess(true);
      //setSelectedItems([]);
      //setExitPay(true);
      setModal();
    } catch (e) {
      console.log('error', e);
      setModal();
    }

    //const currentDate = new Date();
    //const formattedDate = currentDate.toISOString();
    //array.forEach(async (item) => {
    //  try {
    //    const sendPay = await Dynamicore(SERVICES.ACCOUNTS_PROCESSOR).create({
    //      account: item.account,
    //      amount: item.amount,
    //      date: formattedDate,
    //      dst_account: item.account,
    //      external_id: "",
    //      extras: {},
    //      operation: 98,
    //      reference: formattedDate,
    //    });
    //  } catch (e) {
    //    console.log(e);
    //  }
    //});
    //setModal();
  };
  const newAccount = function () {
    setModal({
      open: true,
      title: 'Nueva cuenta',
      view: 'newAccount'
    });
  };

  const addAccount = async (values) => {
    setIsProcessing(true);
    //console.log('values de cuenta', values);
    //console.log('client data', clientData);
    try {
      const [bank] = await getStaticData(STATIC_DATA.BANKS, {
        clabePrefix: values.account.substring(0, 3),
      });
      await Dynamicore(SERVICES.LIBERFIN_CREATE_ACCOUNT)
        .create({
          email: values.email,
          nif: values.nif,
          name: values.name,
          phone: values.phone,
          account: values.account,
          client: clientData.id,
          enabled: "1",
          bank_code: bank.id,
          bank_name: bank.name,
        });
      setIsNewAccount(true);
      setModal();
    } catch (e) {
      console.log('error=>', e);
      setModal();
    };
  };

  const handleSpeiOut = function () {
    setModal({
      open: true,
      title: 'Agregar movimiento',
      view: 'addMovement'
    });
  };

  const addMovement = async (data) => {
    setIsProcessing(true);
    console.log('data', data);
    console.log('wallet', wallet);

    try {

      const payload = {
        operation: 106,
        account: wallet.id, //Id del producto wallet
        amount: data.amount, //del que viene del modal
        external_id: modal?.data?.externalId || '',
        dst_account: data.account, //Lista obtenida de las cuentas (de Destination el id)
        date: data.date, //Fecha del modal
        extras: {},
        reference: new Date(),
        //...data,
      };

      //payload.reference = proccessReference(payload);

      console.log('payload=>', payload);

      const processor = await Dynamicore(SERVICES.ACCOUNTS_PROCESSOR)
        .create(payload);

      if (processor === 'EXCEPTION:INVALID_DATE') {
        const e = new Error(processor);
        e.code = 'invalid_date';

        throw e;
      }
      setModal();
    } catch (e) {
      console.log('e=>', e);
    }
  };

  // Función que maneja el mensaje de tipo 'finish'
  const handleFinishMessage = () => {
    //console.log('<= veces que entra  handleFinishMessage =>');
    //console.log('totalSendStatements inicial =>', totalSendStatements);
    setIsLoading(false);
    setTotalSendStatements((prevTotal) => {
      const updatedTotal = prevTotal - 1; // Reduce el total de mensajes de 'finish'
  
      if (updatedTotal === 0) {
        setExitPay(true); // Cuando el total de mensajes de 'finish' llegue a 0, establece exitPay en true
      }
  
      return updatedTotal; // Retorna el nuevo valor actualizado de totalSendStatements
    });

    //console.log('totalSendStatements restado =>', totalSendStatements);

    if (totalSendStatements === 0) {
      setExitPay(true); // Cuando el total de mensajes de 'finish' llegue a 0, establece exitPay en true
    }
  };

  useEffect(() => {
    getData();

    const { accessToken } = getCurrentSessionData();
    const wssClient = new w3cwebsocket([
      WEBSOCKET_URL,
      '/user?token=',
      accessToken,
    ].join(''));

    wssClient.onopen = () => {
      console.debug('WebSocket client: Connected');
    };

    wssClient.onmessage = ({ data }) => {
      
      const message = JSON.parse(data);

      switch (message.type) {
        default: break;

        case 'payment': {
          setIsLoading(true);
          break;
        }

        case 'finish': {
          handleFinishMessage();
          break;
        }
      }
    };

    wssClient.onclose = () => {
      console.debug('WebSocket client: Disconnected');
    };

    return () => wssClient.close();
  }, []);

  useEffect(() => {
  }, [totalSendStatements]);

  useEffect(() => {
    //if (isLoading === true) {
    //  console.log('se cambia a true');
    //}
  }, [isLoading]);
  
  useEffect(() => {
    const totalAmount = selectedItems.reduce((total, item) => total + item.amount, 0);
    //console.log('TotalSelected=>', totalAmount );
    setTotalSelected(totalAmount);
  }, [selectedItems]);

  useEffect(() => {
    if(exitPay === true) {
      setPayInProcess(false);
      getData();
      //window.location.reload();
    }
  }, [exitPay]);

  useEffect(() => {
    if(isNewAccount === true) {
      getData();
      //window.location.reload();
    }
  }, [isNewAccount])

  if (!accountId) {
    return (
      <>
        <DashHeader />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
          <CircularProgress />
        </div>
      </>
    );
  }

  if (isLoading === true) {
    <>
      <DashHeader />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress />
      </div>
    </>
  }

  return (
  <>
  <DashHeader />
  <div className={classes.contPage} id={'contPageDashboard'}>
    <div className="container ps-lx-5 pe-xl-5 ps-lg-4 pe-lg-4 ps-md-1 pe-md-1">
      <Page title={title} style={{
        backgroundColor: '#ffffff',
      }}>

      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <CardHeader
            title={(<Typography
              children="Mi panel"
              variant="caption"
            />)}
            className={classes.container_title}
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} id={'principalWelcome'}>
        <Grid item lg={7} md={7} xs={12}>
          <CardContent>
            <Grid item md={12} xs={12} className={classes.cardTitle}>
              <Typography
                className={classes.fontGreatings}
                variant="caption"
              >
                ¡Hola {razonsocial}!
              </Typography>
              <img src={Saludos} alt="smile" className={classes.imgGreatings} />
            </Grid>
            <Grid item md={12} xs={12} className={classes.contWlcome}>
              <Typography
                className={classes.fontGreatings2}
                children="Buen día"
                variant="caption"
              />
            </Grid>
          </CardContent>
        </Grid>
        <Grid item lg={5} md={5} xs={12}>
          {clientStatus === 'Active' ? (
            <CardContent className={classes.cardWelcome} id={'cardWelcome1'}>
              <Grid item md={12} xs={12} className={classes.contWlcome1}>
                <RouterLink to="/factoring">
                  <ButtonBlue styleClass={classes.btnSoli}
                    spanClass={'span_icon ps-2'} iconColor={'icon-button-blue'} value={'Solicitar nuevo financiamiento'}/>
                </RouterLink>
              </Grid>
            </CardContent>) : (
            <CardContent className={classes.creditStatus} id={'cardWelcome2'}>
              <Grid item md={12} xs={12}>
                <Typography className={classes.notAprob}>
                  
                </Typography>
              </Grid>
            </CardContent>
          )}
          
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.container_amount_principal} id='container_amount_principal'>
        <Grid item lg={6} md={6} xs={12}>
          <Card className={classes.cardAmount}>
            <CardHeader
              title={(
                <Typography
                  className={classes.cardAmountTitle}
                  variant="caption">
                    <img src={Cash} style={{marginRight: '10px'}} alt={'total financiado'} />
                    Total financiado
                </Typography>
                )}
              className={classes.cont_header_amount_title_principal}
            />
            <CardContent
              className={classes.amount_pos_card}
              children={<Typography
                className={classes.amount_pos_principal}
                children={numberFormat(totalExpectedDisbursed, '$')}
                variant="h3"
              />}
            />
          </Card>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <Card className={classes.cardAmount}>
            <CardHeader
              title={(
              <Typography
                className={classes.cardAmountTitle}
                variant="caption">
                  <img src={Cash} style={{marginRight: '10px'}} alt={'Saldo de cuenta'}/>
                  Saldo en la cuenta
              </Typography>
              )}
              className={classes.cont_header_amount_title_principal}
            />
            <CardContent
              className={classes.amount_pos_card}
              children={<Typography
                className={classes.amount_pos_principal}
                children={numberFormat(wallet?.amount, '$')}
                variant="h3"
              />}
            />
          </Card>
        </Grid>
        {/* Deshabilitar 2 cards de momento
        <Grid item lg={3} md={3} xs={12}>
          <Card className={classes.cardAmount}>
            <CardHeader
              title={(<Typography
                children="Monto proximo a pagar"
                variant="caption"
              />)}
              className={classes.cont_header_amount_title_principal}
            />
            <CardContent
              className={classes.amount_pos_card}
              children={<Typography
                className={classes.amount_pos_principal}
                children={numberFormat(totalToPay, '$')}
                //children={numberFormat(state?.properties?.expected_disbursed, '$')}
                variant="h3"
              />}
            />
          </Card>
        </Grid>
        <Grid item lg={3} md={3} xs={12}>
          <Card className={classes.cardAmount}>
            <CardHeader
              title={(<Typography
                children="Interes promedio ponderado"
                variant="caption"
              />)}
              className={classes.cont_header_amount_title_principal_interest}
            />
            <CardContent
              className={classes.amount_pos_card}
              children={<Typography
                className={classes.amount_pos_principal}
                children={'4%'}
                //children={state?.config?.interest_rate + '%'}
                variant="h3"
              />}
            />
          </Card>
        </Grid>
        */}
        <Grid item md={12} xs={12} className={classes.contWlcome}>
          <RouterLink to="/factoring" className={classes.link}>
            Solicitar nuevo financiamiento
          </RouterLink>
        </Grid>
      </Grid>
      {wallet?.properties?.clabe && clientStatus === 'Active' ? (
      <Grid>
        <Grid container spacing={1} className={classes.container_clabe} id={'container_clabe'}>
          <Grid item md={12} xs={12}>
            <Box>
              <img src={Wallet} alt={'número de cuenta'} />
              <Typography
                className={classes.textClabe}
                children="Número de cuenta:"
                variant="caption"
              />
              <Typography
                className={classes.numberClabe}
                children={wallet.properties.clabe || 'Sin clabe'}
                variant="caption"
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container className={classes.container_actions} id={'container_actions'}>
          <Grid item md={6} xs={12} className={classes.actions} onClick={newAccount}>
            <Typography
              className={classes.textActions}
              children={'Dar de alta nueva cuenta'}
              variant="caption"
            />
          </Grid>
          <Grid item md={6} xs={12} className={classes.actions} onClick={handleSpeiOut}>
            <Typography
              className={classes.textActions}
              children={'Transferir saldo'}
              variant="caption"
            />
          </Grid>
        </Grid>
      </Grid>
      ) : (
        <Grid container spacing={1} className={classes.msgEmail}>
          <Grid item md={12} xs={12}>
            <Box>
              <Typography
                className={classes.subtitle}
                children="Te enviamos un email para realizar la firma de tu contrato con nosotros."
                variant="caption"
              />
            </Box>
          </Grid>
        </Grid>
      )} 
      {/* Fin Grid facturas principal */}
      
      {/* Grid de tabla */}
      {items && (
      <Grid container style={{marginTop: '20px'}} id={'container_items'}>
        <Grid item md={12} xs={12} className={classes.detalles}><RouterLink to={`/dashboard/details`}>Ver más</RouterLink></Grid>
        <Grid item md={12} xs={12}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableTitle}></TableCell>
                <TableCell className={classes.tableTitle}>ID</TableCell>
                <TableCell className={classes.tableTitle}>Fecha Emision</TableCell>
                <TableCell className={classes.tableTitle}>Monto Financiado</TableCell>
                <TableCell className={classes.tableTitle}>Tasa de interes</TableCell>
                <TableCell className={classes.tableTitle}>Intereses + IVA</TableCell>
                <TableCell className={classes.tableTitle}>Neto desembolsado</TableCell>
                <TableCell className={classes.tableTitle}>Estatus</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((row) => (
                <Tooltip key={row.id} 
                  title={
                    <Card className={classes.cardTooltip}>
                      <CardContent>
                        <Grid container>
                          <Grid md={12} xs={12} className={classes.titleSumary}>
                            <Typography variant="h6">Resumen de factura</Typography>
                          </Grid>
                          <Grid container className={classes.containerData}>
                            <Grid md={6} xs={12} className={classes.tooltipTitle}>
                              <Typography variant="body1" color="textSecondary">Fecha de vencimiento:</Typography>
                            </Grid>
                            <Grid md={6} xs={12} className={classes.tooltipData}>
                              <Typography variant="body2" color="textPrimary">{row.last_payment_date}</Typography>
                            </Grid>
                            <Grid md={6} xs={12}>
                              <Typography variant="body1" color="textSecondary">Pago al vencimiento:</Typography>
                            </Grid>
                            <Grid md={6} xs={12} className={classes.tooltipData}>
                              <Typography variant="body2" color="textPrimary">{numberFormat(row.monto, '$')}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  }
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                  
                >
                <TableRow key={row.id}>
                  {row.estatus === 'Aprobado' ? (
                    <TableCell>
                      <Checkbox
                        checked={selectedItems.some((selected) => selected.id === row.id)}
                        onChange={() =>
                          handleSelectOneItemData(row.id, row.totalStatement, row.account)
                        }
                      />
                    </TableCell>
                  ) : (
                    <TableCell>

                    </TableCell>
                  )}
                  <TableCell component="th" scope="row" className={classes.tableValues}>
                    {row.id}
                  </TableCell>
                  <TableCell className={classes.tableValues}>{row.fecha_emision}</TableCell>
                  {/*<TableCell className={classes.tableValues}>{row.prox_pago}</TableCell>*/}
                  <TableCell className={classes.tableValues}>{numberFormat(row.monto, '$')}</TableCell>
                  <TableCell className={classes.tableValues}>{row.tasa}</TableCell>
                  <TableCell className={classes.tableValues}>{numberFormat(row.interest, '$')}</TableCell>
                  <TableCell className={classes.tableValues}>{numberFormat(row.totalStatement, '$')}</TableCell>
                  <TableCell className={classes.tableValues}><div className={classes.contStatus}>
                    {
                      row.estatus === 'Pendiente' 
                        ? (<span className={classes.pending}>{row.estatus}</span>) 
                        : row.estatus === 'Aprobado' 
                        ? (<span className={classes.approved}>{row.estatus}</span>) 
                        : row.estatus === 'Pagado'
                        ? (<span className={classes.approved}>{row.estatus}</span>) 
                        : (<span>Sin status :</span>) 
                    }
                  </div>
                  </TableCell>
                  {/*wallet?.properties?.clabe && row.estatus === 'Aprobado' && (
                    <TableCell 
                      className={[classes.tableValues, classes.btnPay]}
                      onClick={() => handlePayClick(row.id, row.account, row.totalStatement)}
                    >
                      Pagar
                    </TableCell>
                  )*/}
                </TableRow>
                </Tooltip>
              ))}
            </TableBody>
          </Table>
        </Grid>
        {payInProcess === false ? (
          <Grid item md={12} xs={12}>
            <div className="row mt-3">
              <div className="col-12 text-center">
              {selectedItems.length > 0 && totalSelected <= wallet.amount && (
                <ButtonBlue onClick={() => {handleOpenModalPayClick(selectedItems)}} styleClass={'btn btn-blue btn-lg text-white fs-header-9 btn-login width-btutton'} spanClass={'span_icon'} iconColor={'icon-button-blue'} value={'Pagar'} />
               )}
              {totalSelected > wallet.amount && (
                <span className={classes.error}>El monto es mayor al saldo en su cuenta</span>
              )}
              </div>
            </div>
          </Grid>
        ) : (
          <Grid item md={12} xs={12}>
            <div className="row mt-3">
              <div className="col-12 text-center">
                <span className={classes.error}>Se estan procesando los pagos anteriores</span>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
      )}
      {/* Fin Grid de tabla */}
      </Page>
    </div>
  </div>
    <Modal
      actions={[
        {
          //className: {classes.buttonPay},
          color: 'primary',
          form: 'frmPay',
          hidden: !(modal?.view === 'Pago'),
          label: 'Pagar',
          fn: () => handlePay(infoPay),
        }
      ]}
      events={{
        onClose: () => setModal(),
      }}
      open={modal?.open}
      //subtitle={modal?.subtitle}
      //title={modal?.title}
    >
      
      <>
      {modal?.view === 'Pago' && (
      <>
      <Box m={2} textAlign="center">
        <Grid container>
          <Grid item md={12}>
            <Typography
              className={classes.modalTitle}
              children='Pagar financiamiento'
              variant="caption"
            />
          </Grid>
          <Grid item md={12}>
            <Typography
              className={classes.modalSubtitle}
              children='Asegúrate de que el monto y la cuenta que elegiste sean correctas.'
              variant="caption"
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={4} textAlign="left">
        <Grid container>
          <Grid item md={12}>
            <Typography
              className={classes.textClabe}
              children='Cuenta Origen: '
              variant="caption"
            />
            <Typography
              className={classes.modalClabe}
              children={wallet?.properties?.clabe}
              variant="caption"
            />
          </Grid>
          <Grid item md={12} mt={2}>
            <Typography
              className={classes.textClabe}
              children='Cuentas destino: '
              variant="caption"
            />
            {infoPay.map(item => (
                <Typography
                  className={classes.modalClabe}
                  children={item?.account}
                  variant="caption"
                />
              )
            )}
          </Grid>
        </Grid>
      </Box>
      <Box mt={2} textAlign="left">
        <Grid container className={classes.totalPayContainer}>
          <Grid item md={12}>
            <Typography
              className={classes.modalPay}
              children='Monto a Pagar'
              variant="caption"
            />
          </Grid>
          <Grid item md={12}>
            <Typography
              className={classes.modalPayAmmount}
              children={numberFormat(infoPay.reduce((acc, item) => acc + item.amount, 0), '$')}
              variant="caption"
            />
          </Grid>
        </Grid>
      </Box>
      </>
      )}
      {modal?.view === 'newAccount' && (
        <>
          <Box m={2} textAlign="center">
            <Grid container>
              <Grid item md={12}>
                <Typography
                  className={classes.modalTitle}
                  children='Alta de cuenta nueva'
                  variant="caption"
                />
              </Grid>
              <Grid item md={12}>
                <Typography
                  className={classes.modalSubtitle}
                  children='Asegúrate de que la cuenta sea correcta.'
                  variant="caption"
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Grid container>
              <AddAccountForm handleSubmitData={addAccount} isProcessing={isProcessing} />
            </Grid>
          </Box>
        </>
      )}
      {modal?.view === 'addMovement' && (
        <>
        <Box m={2} textAlign="center">
          <Grid container>
            <Grid item md={12}>
              <Typography
                className={classes.modalTitle}
                children='Transferir saldo'
                variant="caption"
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <Grid container>
              <AddMovementForm handleSubmitData={addMovement} destinations={catalogs} isProcessing={isProcessing} />
            </Grid>
          </Box>
        </Box>
        </>
      )}
      </>
      
    </Modal>
  </>
  );
}

DashboardView.defaultProps = {
  title: 'Panel',
};

DashboardView.propTypes = {
  title: PropTypes.string,
};

export default DashboardView;
