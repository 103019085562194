import React from 'react';
import '../../css/footer.css';
import LogoWhite from '../../img/Logo_Liberfin_White.png';
import Twitter from '../../img/Twitter.svg';
import Linkedin from '../../img/linkedin.svg';

const Footer = () =>{
    return(
        <section id="footer">
            <div className="row px-md-5 pt-3 pb-3 me-0">
                <div className='col-md-2 mt-2 pt-2 footer-logo footer-logo-padding d-none d-md-block'>
                    <img className='ms-4' src={LogoWhite} width='130' height='42' alt='LogoWhite' />
                    <p className='fs-7 mt-2 ms-md-4 ms-lg-3 ms-xl-3 pt-2'>© 2022 Liberfin, CDMX</p>
                </div>
                {/*}
                <div className='col-md-3 pt-4 ps-md-5 align-items-center padding-mobile'>
                    <p className='fs-7 text-center text-md-start ps-md-4 px-4 px-md-0'>Consulta los Costos y las <br className='d-none d-md-block ' />comisiones de nuestros productos</p>
                </div>
                */}
                <div className='col-md-3 pt-4 ps-md-5 align-items-center padding-mobile'>
                    <p className='text-center text-md-start mb-2'><a href="https://terms-privacity.s3.us-west-2.amazonaws.com/TYCOS+LIBERFIN+V2.pdf" className="link fs-7" target="_blank" rel="noopener noreferrer">Terminos y condiciones</a></p>
                </div>
                <div className="col-md-2 pt-4 ps-md-4">
                    <p className='text-center text-md-start'><a href="https://terms-privacity.s3.us-west-2.amazonaws.com/Aviso+de+Privacidad+Liberfin+v2.pdf" className="link fs-7" target="_blank" rel="noopener noreferrer">Aviso de privacidad</a></p>
                </div>

                <div className='col-6 col-md-2 offset-md-1 pt-2 footer-logo d-block d-md-none ms-auto pt-4'>
                    <img src={LogoWhite} className="ms-3" width='130' height='42' alt='LogoWhite' />
                    <p className='fs-7 ms-3 mt-2'>© 2022 Liberfin, CDMX</p>
                </div>
                <div className='col-4 col-md-2 pt-4 phone-me ps-md-5 ps-sm-0'>
                    <p className='footer_fonts padding-phones fs-7'>Teléfonos: </p>
                    <p className='footer_fonts padding-phones fs-7'>
                        +52 1 55 3334 3173
                    </p>
                    <p className='mt-2 d-block d-md-none' >
                    <i className="padding-i"><a href="/"><img src={Twitter} width="26" height="23" alt="Twitter" /></a></i>
                    <i className="padding-i ml"><a href="/"><img src={Linkedin} width="26" height="23" alt="Linkedin" /></a></i>
                    </p>
                </div>
                <div className="col-md-1 pt-3 ps-xl-5 d-none d-md-block">
                    <div className='row pt-4 mt-3'>
                    <div className="col-5">
                        <a class="w-50 mx-auto" href="/"><img src={Twitter} width="18" height="16" alt="Twitter" /></a></div>
                    <div className="col-6">
                        <a class="w-50 mx-auto" href="/">
                            <img src={Linkedin} width="18" height="16" alt="Linkedin" /></a></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer;