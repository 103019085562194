import React from 'react';
import '../../css/congratulation/congratulation.css';
//import { NavLink } from 'react-router-dom';
import CurrencyInput from 'react-currency-input-field';
import congratsMan from '../../img/congratsMan.png';
import { useEffect } from 'react';
import Header from '../../components/header/header.jsx';


const Congratulation = () =>{
    const data = {
        name: ''
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <>
        <Header />
        <section id="sectionblue" className='mt-md-5 pt-md-4'>
            <div className="row">
                <div className='col-sm-12 col-md-6 d-none d-lg-block ps-5 pt-5 pe-5 '>
                    <img src={congratsMan} width="450" height="auto" alt="Person" className='float-end me-5'/>
                </div>
                <div className='col-12 col-md-7 col-lg-5 mx-auto mx-lg-0 ml-card-mobile text-center ps-0'>
                    <div className='card-blue pt-3 pt-md-4 px-1 px-md-5'> 
                        <div className="card-content text-center ms-4">
                            <div className='pl-title'>
                                <h4 className="card_blue_title mb-3 mb-md-1">¡Felicidades <br/>{data.name}!
                                    <i className="ms-4 ms-md-2 me-3"> 
                                        <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.9654 10.8842L6.7045 0.623306C5.37301 -0.708192 3.09857 0.234746 3.09857 2.11815V2.84577L11.4043 11.1515C12.0825 11.8297 12.0825 12.931 11.4043 13.6091L3.09857 21.9124V22.64C3.09857 24.5234 5.37548 25.4639 6.7045 24.1349L16.9654 13.8739C17.7896 13.0473 17.7896 11.7084 16.9654 10.8842Z" fill="#6188F7"/>
                                            <path d="M2.96493 2.71231C1.87103 1.6184 0 2.39304 0 3.93986V20.8137C0 22.3606 1.87103 23.1377 2.96493 22.0413L3.0961 21.9101V2.84348L2.96493 2.71231Z" fill="#C4D3FF"/>
                                            <path d="M11.4043 11.149L3.09857 2.84326V21.9124L11.4043 13.6066C12.0825 12.9285 12.0825 11.8272 11.4043 11.149Z" fill="#AAC1FF"/>
                                        </svg>
                                    </i>
                                    <i className="me-4">
                                        <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.8538 8.2464L5.07963 0.472244C4.07082 -0.536558 2.34761 0.177854 2.34761 1.6048V2.15608L8.64044 8.44891C9.15421 8.96268 9.15421 9.7971 8.64044 10.3109L2.34761 16.6018V17.1531C2.34761 18.5801 4.0727 19.2926 5.07963 18.2857L12.8538 10.5115C13.4782 9.88523 13.4782 8.8708 12.8538 8.2464Z" fill="#6188F7"/>
                                            <path d="M2.24637 2.05499C1.41757 1.2262 0 1.8131 0 2.98504V15.7695C0 16.9414 1.41757 17.5302 2.24637 16.6995L2.34575 16.6001V2.15437L2.24637 2.05499Z" fill="#C4D3FF"/>
                                            <path d="M8.64044 8.44712L2.34761 2.1543V16.6019L8.64044 10.3091C9.15421 9.79532 9.15421 8.9609 8.64044 8.44712Z" fill="#AAC1FF"/>
                                        </svg>
                                    </i>
                                    <i className="me-4">
                                        <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.6639 6.48783L4.54763 0.371537C3.75396 -0.422135 2.39822 0.139926 2.39822 1.26257V1.69629L7.34909 6.64715C7.7533 7.05137 7.7533 7.70784 7.34909 8.11206L2.39822 13.0614V13.4952C2.39822 14.6178 3.75543 15.1784 4.54763 14.3862L10.6639 8.26991C11.1552 7.77718 11.1552 6.97908 10.6639 6.48783Z" fill="#6188F7"/>
                                            <path d="M2.31856 1.61678C1.66651 0.964729 0.551239 1.42648 0.551239 2.34849V12.4066C0.551239 13.3286 1.66651 13.7918 2.31856 13.1383L2.39675 13.0601V1.69497L2.31856 1.61678Z" fill="#C4D3FF"/>
                                            <path d="M7.34909 6.64569L2.39822 1.69482V13.0615L7.34909 8.11059C7.7533 7.70638 7.7533 7.0499 7.34909 6.64569Z" fill="#AAC1FF"/>
                                        </svg>
                                    </i>
                                    <i className="me-2">
                                        <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.47144 2.9707L2.67086 0.170122C2.30745 -0.19329 1.68668 0.0640705 1.68668 0.578117V0.77671L3.95361 3.04365C4.1387 3.22873 4.1387 3.52932 3.95361 3.71441L1.68668 5.98067V6.17926C1.68668 6.69331 2.30812 6.94999 2.67086 6.58726L5.47144 3.78668C5.69637 3.56107 5.69637 3.19563 5.47144 2.9707Z" fill="#6188F7"/>
                                            <path d="M1.65021 0.740359C1.35164 0.441793 0.840973 0.653221 0.840973 1.0754V5.68088C0.840973 6.10306 1.35164 6.31516 1.65021 6.01592L1.68601 5.98012V0.77616L1.65021 0.740359Z" fill="#C4D3FF"/>
                                            <path d="M3.95361 3.04282L1.68668 0.775879V5.98051L3.95361 3.71358C4.1387 3.52849 4.1387 3.2279 3.95361 3.04282Z" fill="#AAC1FF"/>
                                        </svg>
                                    </i>
                                </h4> 
                                <p className='card_blue_subtitle fs-6 pe-md-5 pe-3 mb-3'>Hemos aprobado tu solicitud.</p>
                            </div>
                            <form>
                            <div className='card-white px-4 py-3 pb-4'>
                                
                                <label className='mt-1 mb-2 '>Monto a financiar</label>
                                <br/>
                                <CurrencyInput intlConfig={{ locale: 'en-US', currency: 'USD' }} placeholder='$' className='card-white-input border-0 bg-gray text-start px-3 fs-6' disabled />
                                <br />
                                <label className='mt-2 mb-2 '>Plazo</label>
                                <br />
                                <input type="text" placeholder='90 días' className='px-3 fs-6 border-0 bg-gray text-start' disabled />
                                <br />
                                <label className='mt-2 mb-2'>Tasa mensual</label>
                                <br />
                                <CurrencyInput suffix="%" placeholder='%' value="1.67" className="px-3 fs-6 border-0 bg-gray text-start" disabled />
                                
                            </div>
                            {/*<NavLink to='/'> */}
                                <button className="h-btn3 mt-md-4 mt-5 mb-4 border-2">
                                    <span className="pt-3 mt-1">Ir a mi perfil</span>
                                    <span className="span_icon">
                                        <i className="icon-button-blue ps-1">
                                            <svg width="11" height="14" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.688628 14.82L6.50863 8.99997L0.688628 3.17997C0.103628 2.59497 0.103628 1.64997 0.688628 1.06497C1.27363 0.479971 2.21863 0.479971 2.80363 1.06497L9.68863 7.94997C10.2736 8.53497 10.2736 9.47997 9.68863 10.065L2.80363 16.95C2.21863 17.535 1.27363 17.535 0.688628 16.95C0.118628 16.365 0.103628 15.405 0.688628 14.82Z" fill="#F7F7F7"/>
                                            </svg>
                                        </i>
                                    </span>
                                </button>
                            {/*</NavLink> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}

    export default Congratulation;