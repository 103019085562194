import React from 'react';

const ButtonTransparent = ({styleClass, iconColor, spanClass, value, isdisabled, onSubmit}) =>{
    return(
        <div>
            <button className={`${styleClass}`} disabled={isdisabled} onSumbit={() => onSubmit()} >  {/* In use "h-btn" */}
                <div className='row align-items-center'>
                    <div className='col-9 align-items-center'>
                    <p className="text-center align-items-center lh-1 mb-0 ps-5 fs-financiamiento-15"> {value}</p>
                    </div>
                    <div className='col-3 align-items-center text-start pb-1'>
                        <span className={`${spanClass}`}>
                            <i className={`${iconColor}`}> 
                                <svg className="ms-0 mt-1" width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                    <path d="M0.688628 14.82L6.50863 8.99997L0.688628 3.17997C0.103628 2.59497 0.103628 1.64997 0.688628 1.06497C1.27363 0.479971 2.21863 0.479971 2.80363 1.06497L9.68863 7.94997C10.2736 8.53497 10.2736 9.47997 9.68863 10.065L2.80363 16.95C2.21863 17.535 1.27363 17.535 0.688628 16.95C0.118628 16.365 0.103628 15.405 0.688628 14.82Z" fill="#361FF5"/>
                                </svg>
                            </i>
                        </span>
                    </div>
                </div>
            </button>
            
        </div>
    )
}

export default ButtonTransparent;