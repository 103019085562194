import { makeStyles } from '@material-ui/core';

const colorPrincipal = '#361FF5 !important';

export default makeStyles((theme) => ({
  contPagec: {
    height: '90vh',
    marginBottom: '300px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '150px',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '300px',
    },
  },
    container_title: {
      '& span': {
        color: ({color})=> colorPrincipal,
        fontFamily: 'Poppins, sans-serif !important',
        fontSize: '36px !important',
        fontStyle: 'normal',
        fontWeight: 'bold !important',
        lineHeight: '39px',
      },
      padding: '5px 0px 15px',
    },
    boxbuttongroup: {
      marginBottom: '20px',
      height: '100%',
    },
    tabs_colors: {
      color: ({color})=> colorPrincipal,
      '& .MuiTab-wrapper': {
        color: ({color})=> colorPrincipal,
        fontFamily: 'Poppins, sans-serif',
        fontSize: '20px !important',
        fontStyle: 'normal',
        fontWeight: '700 !important',
      },
      '& .PrivateTabIndicator-colorSecondary-17': {
        backgroundColor: ({color})=> colorPrincipal,
      },
      '& .PrivateTabIndicator-colorSecondary-19': {
        backgroundColor: ({color})=> colorPrincipal,
      },
      '& .PrivateTabIndicator-colorSecondary-79': {
        backgroundColor: ({color})=> colorPrincipal,
      },
    },
}));