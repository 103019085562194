import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  anual: 0,
  factura: 0,
  monto: 0,
  porcentage: 0,
  plazo: 0,
  salud: '',
  tasa: 0,
}

export const financiamientoSlice = createSlice({
  name: 'financiamiento',
  initialState: initialState,
  reducers: {
    setFinanciamiento: (state, action) => {
      state.factura = action.payload.factura;
      state.porcentage = action.payload.porcentage;
      state.plazo = action.payload.plazo;
      state.anual = action.payload.anual;
      state.salud = action.payload.salud;
      state.monto = action.payload.monto;
      state.tasa = action.payload.tasa;
    },
    unsetFinanciamiento: (state) => {
      state.factura = ''
      state.porcentage = ''
      state.plazo = 0
      state.anual = ''
      state.salud = ''
      state.monto = 0
      state.tasa = 0
    },
  },
})

// Action creators are generated for each case reducer function
export const { setFinanciamiento, unsetFinanciamiento } = financiamientoSlice.actions

export default financiamientoSlice.reducer