import React from 'react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import '../../css/about/about.css';
import ButtonTransparent from '../../components/buttons/button_transparent.js';
import Phone from '../../img/quienes-somos-img.png';
import Header from '../../components/header/header.jsx';
import { Link, animateScroll as scroll } from "react-scroll";



const About = () =>{
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    const navigate = useNavigate();

    const fnGoToElement = (el) => {
      console.log('Entra a fnGoToElement =>');
      if(el === '/section_f'){
        navigate('/');
        setTimeout(function(){
          var getMeTo = document.getElementById("section_f");
          getMeTo.scrollIntoView({behavior: 'smooth', block: 'start'});
        }, 1000);
        setTimeout(() => {
          window.scrollBy(0, -80);
        }, 2000);
      }
      setTimeout(function(){
        scroll(`#${el}`);
      }, 2000);
    }

    return(
      <>
        <Header />
          <section id="about1">
              <div className='container mx-auto row ps-lg-5 ps-md-5 ps-xs-1'>
                <div className="col-sm-12 col-md-6 mb-mobile">
                  <h4 className="about_title mb-3">¿Quiénes
                      <span className='color-blue'> somos?</span>
                  </h4>
                  <div className="mt-1 about-p-widht">
                      <p className='about_sub_titulo mb-3 fs-6'>Liberfin es una plataforma tecnológica mexicana que busca crear productos y servicios financieros inclusivos para la pequeña y mediana empresa, apoyándolas a alcanzar su libertad financiera.</p>
                  </div>
                  <ul className='about-list'>
                    <li>
                      <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="17.5" cy="17.5" r="17.5" fill="#361FF5"/>
                      <path d="M15 21.2L11.5 17.7C11.11 17.31 10.49 17.31 10.1 17.7C9.71 18.09 9.71 18.71 10.1 19.1L14.29 23.29C14.68 23.68 15.31 23.68 15.7 23.29L26.3 12.7C26.69 12.31 26.69 11.69 26.3 11.3C25.91 10.91 25.29 10.91 24.9 11.3L15 21.2Z" fill="#F7F7F7"/>
                      </svg>

                      <span>Proceso 100% en linea.</span>
                    </li>
                    <li className='list-mobile'>
                      <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="17.5" cy="17.5" r="17.5" fill="#361FF5"/>
                      <path d="M15 21.2L11.5 17.7C11.11 17.31 10.49 17.31 10.1 17.7C9.71 18.09 9.71 18.71 10.1 19.1L14.29 23.29C14.68 23.68 15.31 23.68 15.7 23.29L26.3 12.7C26.69 12.31 26.69 11.69 26.3 11.3C25.91 10.91 25.29 10.91 24.9 11.3L15 21.2Z" fill="#F7F7F7"/>
                      </svg>

                      <span>Respuesta rápida.</span>
                    </li>
                    <li>
                      <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="17.5" cy="17.5" r="17.5" fill="#361FF5"/>
                      <path d="M15 21.2L11.5 17.7C11.11 17.31 10.49 17.31 10.1 17.7C9.71 18.09 9.71 18.71 10.1 19.1L14.29 23.29C14.68 23.68 15.31 23.68 15.7 23.29L26.3 12.7C26.69 12.31 26.69 11.69 26.3 11.3C25.91 10.91 25.29 10.91 24.9 11.3L15 21.2Z" fill="#F7F7F7"/>
                      </svg>

                      <span>Tasas competitivas.</span>
                    </li> 
                  </ul>
                  <div className="mobile-button-view web-view pt-3 ">
                    <a onClick={() => fnGoToElement('/section_f')}>
                      <ButtonTransparent value={'Solicita financiamiento'} styleClass={'about-card-button'} spanClass={'span_icon'} iconColor={'icon-button-blue'}  />
                    </a>
                  </div>
              </div>
              <div className="col-sm-12 col-md-6 center handphone-mobile">
                <div className='hand-img-conter'>
                  <img className='hand-img' src={Phone} width="100" height="auto" alt="call" />
                </div>
              </div>
            </div>
        </section>
      </>
    )
}

export default About
