import React from 'react';
import '../../css/success/success.css';
import Header from '../../components/header/header.jsx';
import Notpage from '../../img/not_page.png';

function RefusedView() {
    return(
        <>
        <Header />
        <div className="mb-5">
            <section id="sectionrefused" className='mt-md-5 pt-md-4'>
                <div className="row align-items-center text-center mb-5">
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className="text-center align-items-center ps-md-3 pe-md-3 ps-lg-5 pe-lg-5 w-50 mx-auto">
                            <h4 className="rechazado mb-5">¡Lo <span className='color-blue'>sentimos!</span></h4>
                            <p className="sub_titulo text-center">Lamentablemente tu crédito no fue aprobado, te invitamos a volver a aplicar para un crédito posterior a 30 días de tu solicitud.</p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center text-center">
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                        <img src={Notpage} width="350" height="auto" alt="Refused" className='mt-5'/>
                    </div>
                </div>
            </section>
        </div>
        </>
    );
}

export default RefusedView;