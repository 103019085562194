import './css/header.css';
import './css/index.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { Routes, Route, HashRouter } from "react-router-dom";

//import Header from './components/header/header.jsx';
//import Auth from './components/Auth/Auth.jsx';
import Index from './views/index/index.jsx';
import About from './views/about/about.jsx';
//import Questions from './components/questions/questions.jsx';
import {AskView} from './views/asks/index.js';
import Footer from './components/footer/footer.jsx';
//import LandingCuentanos from './views/landing2/landing2.jsx';
import DataEmpresa from './views/dataempresa/index.jsx';
import SuccessView from './views/success/success.jsx';
import AprovedView from './views/result/aproved.jsx'
import RefusedView from './views/refused/refused.jsx';
import RevitionView from './views/revision/revition.jsx';
import PreaprobadoView from './views/preaprobado/preaprobado.jsx';
import FisicPersonView from './views/fisicPerson/index.jsx';
import FacturajeView from './views/facturation/facturation.jsx';
import LoanResultView from './views/facturation/loanResult';
import Confirm from './views/validation/confirm.jsx';
import Firma from './views/firma/firma.jsx';
import Congratulation from './views/congratulation/congratulation.jsx';
import NotFound from './views/notfound/notfound.jsx';
//import Login from './views/login/login.jsx';
import AuhtenticationSignInView from './views/login/signin.jsx';
import DashboardView from './views/dashboard/index.jsx';
import DashboardDetailsView from './views/dashboard/Details/index.jsx';
import FacturationView from './views/dashboard/Facturation/index.js';
import ProfileView from './views/profile/index.jsx';
import Validateauth from './components/validateAuth/validateAuth.js';
//import LaunchView from './views/launch/index.jsx';
import NotActiveView from './views/dashboard/NotActive/index.jsx';

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route path='/' element={ <Index />} />
          <Route path='/how_work' element={<DataEmpresa />} />
          <Route path="/success" element={<SuccessView />} />
          <Route path='/pre_aprob' element={<PreaprobadoView />} />
          
          <Route path='/down_doc' element={<Firma />} />
          <Route path='/factoring' element={<FacturajeView />} />
          <Route path='/validation_statements' element={<LoanResultView />} />
          <Route path="/aproved" element={<AprovedView />} />
          <Route path="/refused" element={<RefusedView />} />
          <Route path="/revition" element={<RevitionView />} />
          <Route path='/confirm' element={<Confirm />} />
          <Route path='/congratulation' element={<Congratulation />} />

          <Route path='/about' element={<About />} />
          <Route path='/asks' element={<AskView />} />

          <Route path='/auth/login' element={<AuhtenticationSignInView />} />
          <Route path='/dashboard' element={ <Validateauth> <DashboardView /> </Validateauth>} />
          <Route path='/dashboard/facturaction' element={ <Validateauth> <FacturationView /> </Validateauth>} />
          <Route path='/legal_representative' element={<Validateauth> <FisicPersonView /></Validateauth>} />
          <Route path='/dashboard/details' element={<DashboardDetailsView />} />
          <Route path='/profile' element={<Validateauth> <ProfileView /> </Validateauth>} />
          <Route path='/pending' element={<Validateauth> <NotActiveView /> </Validateauth>} />
          
          {/* <Route path='/' element={<LaunchView />} /> */}
          <Route path='*' element={<NotFound />} />
        </Routes>
        <Footer />
      </HashRouter>
  </>
  );
}

export default App;
