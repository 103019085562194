import React from 'react';
import {useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../css/preaprobado/preaprobado.css';
//import { NavLink } from 'react-router-dom';
import { Grid } from '@mui/material';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import Uploadfile from '../../components/uploadfile/uploadfile.js';
import ButtonBlue from '../../components/buttons/button_blue.js';
import BgImage from '../../img/bg_index.png';
import DashHeader from '../../components/header/dash_header.jsx';
import List from '../../components/lists/list.js';
import useStyles from './modal.styles.js';
import Modal from '@material-ui/core/Modal';

function getModalStyle() {

  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const PreaprobadoView = () =>{
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [validate, setValidate] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const [tamaño, setTamaño] = useState(0);
  const [tamaño2, setTamaño2] = useState(0);
  const [tamaño3, setTamaño3] = useState(0);
  const [tamaño4, setTamaño4] = useState(0);
  const [tamaño5, setTamaño5] = useState(0);
  const [tamaño6, setTamaño6] = useState(0);
  const [tamaño7, setTamaño7] = useState(0);
  const [tamaño8, setTamaño8] = useState(0);
  const [tamaño9, setTamaño9] = useState(0);
  const [totalFiles, setTotalFiles] = React.useState(0);
  const [error, setError] = useState(false);
  const classes = useStyles();
  const location = useLocation();
  const clientId = location.state.clientId;

  //Función PARA MOSTRAR el boton del modal de comprobacion de dcoumentos
  const checkvalidate = async () => {
    try {
      //Se quita este time out y el setValidate y se usaria un useNavigate
      setTimeout(() => {
        setValidate(true);
      }, 2000);
    } catch (error) {
      console.log('error: ', error);
    }
  }

  const handleOpen = () => {
    //Logica para validar que todos los documentos esten cargados
    console.log('totalFiles', totalFiles);
    if (totalFiles >= 9) {
      setOpen(true);
      checkvalidate();
    } else {
      setError(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileUpload = (fileCount) => {
    setError(false);
    setTotalFiles(totalFiles + fileCount);
  };

  return(
    <>
    <DashHeader />
    <div className="bg-index-section prearobado mb-0 pb-0 pb-md-5 h-100" style={{ 
      backgroundImage: `url("`+BgImage+`")` 
    }} >
      <section id="preaprobado">
        <div className='text-center px-5'>
            <h4 className="preptitle mb-3">Tu financiamiento
            <span className='color-blue'> está preaprobado</span>
            </h4>
            <p className="preap-subtitle">
              Necesitamos nos ayudes subiendo la documentación necesaria para validar la información. Se te notificará la aprobación de tus solicitud lo antes posible y compartiremos la liga para firma de contrato y pagaré (el cual deberá ser firmado por el aval).
            </p>
        </div>
      
      
        <div className='row files-padding'>
          <div className='col-12 col-md-6 h-100'>
            <Grid className="upload-borders mb-3 mb-md-4 me-0" style={{border: "1px dashed", borderRadius: "4px", borderColor: "#6188F7", padding: "0px 0px 0px 12px", marginRight: "10px", marginBottom: "20px", minHeight: "60px"}}>

              <Uploadfile titulo={'Acta constitutiva'} clientId={clientId} nameDocument={'acta_constitutiva'} setSise={setTamaño} onFileUpload={handleFileUpload}
              />

            </Grid> 
            <Grid className="upload-borders mb-3 mb-md-4 me-0" style={{border: "1px dashed", borderRadius: "4px", borderColor: "#6188F7", padding: "0px 0px 0px 12px", marginRight: "10px", marginBottom: "20px", minHeight: "60px"}}>
              <Uploadfile titulo={'Poder de Rep Legal para validadion'} clientId={clientId} nameDocument={'poder_de_rep_legal'} setSise={setTamaño2} onFileUpload={handleFileUpload} />
            </Grid>
          </div>
          <div className='col-12 col-md-6 h-100'>
           
            {/*
            <Grid className="upload-borders mb-3 mb-md-4 me-0" style={{border: "1px dashed", borderRadius: "4px", borderColor: "#6188F7", padding: "0px 0px 0px 12px", marginRight: "10px", marginBottom: "20px", minHeight: "60px"}}>  
              <Uploadfile titulo={'Factura que vas a descontar.'} clientId={clientId} nameDocument={'factura_a_descontar'} setSise={setTamaño3} />
            </Grid> 
            */}
            <Grid className="upload-borders mb-3 mb-md-4 me-0" style={{border: "1px dashed ", borderRadius: "4px", borderColor: "#6188F7", padding: "0px 0px 0px 12px", marginRight: "10px", marginBottom: "20px", minHeight: "60px"}}>
              <Uploadfile titulo={'Carátula de Edo. Cuenta bancario para CLABE y/o cuenta'} clientId={clientId} nameDocument={'edo_cuenta_bancario'} setSise={setTamaño3} onFileUpload={handleFileUpload}/>
            </Grid>
            <Grid className="upload-borders mb-3 mb-md-4 me-0" style={{border: "1px dashed", borderRadius: "4px", borderColor: "#6188F7", padding: "0px 0px 0px 12px", marginRight: "10px", marginBottom: "20px", minHeight: "60px"}}>

              <Uploadfile titulo={'RFC del accionista mayoritario que firma como aval.'} clientId={clientId} nameDocument={'rfc_del_accionista_mayoritario'} setSise={setTamaño4} onFileUpload={handleFileUpload}/>

            </Grid> 
          </div>
          <div className='col-12 col-md-6 h-100'>
            <Grid className="upload-borders mb-3 mb-md-4 me-0" style={{border: "1px dashed", borderRadius: "4px", borderColor: "#6188F7", padding: "0px 0px 0px 12px", marginRight: "10px", marginBottom: "20px", minHeight: "60px"}}>

              <Uploadfile titulo={'Identificación Rep. Legal'} clientId={clientId} nameDocument={'identificacion_rep_legal'} setSise={setTamaño5} onFileUpload={handleFileUpload}/>

            </Grid> 
            <Grid className="upload-borders mb-3 mb-md-4 me-0" style={{border: "1px dashed", borderRadius: "4px", borderColor: "#6188F7", padding: "0px 0px 0px 12px", marginRight: "10px", marginBottom: "20px", minHeight: "60px"}}>

              <Uploadfile titulo={'Comprobante de domiclio no mayor a 2 meses'} clientId={clientId} nameDocument={'comprobante_de_domiclio'} setSise={setTamaño6} onFileUpload={handleFileUpload} />

            </Grid> 

          </div>
          <div className='col-12 col-md-6 h-100'>
            <Grid className="upload-borders mb-3 mb-md-4 me-0" style={{border: "1px dashed gray", borderRadius: "4px", borderColor: "#6188F7", padding: "0px 0px 0px 12px", marginRight: "10px", marginBottom: "20px", minHeight: "60px"}}>

              <Uploadfile titulo={'Identificación del aval'} clientId={clientId} nameDocument={'identificacion_del_aval'} setSise={setTamaño7} onFileUpload={handleFileUpload}/>

            </Grid> 
            <Grid className="upload-borders mb-3 mb-md-4 me-0" style={{border: "1px dashed gray", borderRadius: "4px", borderColor: "#6188F7", padding: "0px 0px 0px 12px", marginRight: "10px", marginBottom: "20px", minHeight: "60px"}}>

              <Uploadfile titulo={'Comprobante de domicilio del Aval (No mayor a 2 meses)'} clientId={clientId} nameDocument={'comprobante_de_domicilio_del_aval'} setSise={setTamaño8} onFileUpload={handleFileUpload}/>

            </Grid> 
          </div>
          <div className='col-12 col-md-6 h-100'>
            <Grid className="upload-borders mb-3 mb-md-4 me-0" style={{border: "1px dashed", borderRadius: "4px", borderColor: "#6188F7", padding: "0px 0px 0px 12px", marginRight: "10px", marginBottom: "20px", minHeight: "60px"}}>
              <Uploadfile titulo={'Listado de Resultados (últimos 12 meses)'} clientId={clientId} nameDocument={'listado_resultado'} setSise={setTamaño9} onFileUpload={handleFileUpload}/>
            </Grid> 
          </div>
        </div>
        <div className="text-center">{error && <p style={{ color: 'red' }}>Debe subir todos los archivos solicitados</p>}</div>
        <div className="row mb-5 pb-0">
          <div className="col-12 center">
              <ButtonBlue onClick={() => handleOpen() } styleClass={'btn btn-blue btn-lg text-white fs-header-9 btn-login width-button-validar'} spanClass={'span_icon'} iconColor={'icon-button-blue'} value={'Validar la información'} />
          </div>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <div className="row">
		          <div className="col-12 col-md-12 mx-auto px-4 px-md-4">
                <h4 className={classes.fontTitle}>Estamos validando
                  <span className='color-blue'> tu información</span>
                </h4>
                <p className='subtitle mt-4'>Esto tomará algunos segundos</p>
                <CircularProgress
                  className={classes.circularProgress}
                  variant="indeterminate"
                  disableShrink
                  sx={{
                    color: '#361ff5',
                    animationDuration: '850ms',
                    position: 'absolute',
                    marginTop: '12px',
                    left: 'calc(50% - 35px) ',
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: 'round',
                    },
                  }}
                  thickness={4}	
                />
                <br/>
                <div className='w-100 mt-lg-4 mt-md-5 addScroll'>
                  <div className='row mt-lg-5 mt-md-2 mt-sm-0'>
                    <List label={'Acta Const...'} size={ (tamaño / 1048576).toFixed(2)} />
                    <List label={'Poder de Rep Legal para validadion'} size={(tamaño2 / 1048576).toFixed(2)} />
                    <List label={'Carátula de Edo. Cuenta bancario para CLABE y/o cuenta'} size={(tamaño3 / 1048576).toFixed(2)} />
                    <List label={'RFC del accionista mayoritario '} size={(tamaño4 / 1048576).toFixed(2)} />
                    <List label={'Identificación Rep. Legal'} size={(tamaño5 / 1048576).toFixed(2)} />
                    <List label={'Comprobante de domiclio no mayor a 3 meses'} size={(tamaño6 / 1048576).toFixed(2)} />
                    
                    
                    
                    <List label={'Identificación del  Aval '} size={(tamaño7 / 1048576).toFixed(2)}/>
                    <List label={'Comprobante de domicilio del Aval (No mayor a 2 meses)'} size={(tamaño8 / 1048576).toFixed(2)} />
                    <List label={'Listado de Resultados (últimos 12 meses)'} size={(tamaño9 / 1048576).toFixed(2)} />
                  </div>
                </div>
                <div className="row mt-4 mt-md-0 ">
                  <div className="text-center mt-4">
                    {validate ? (
                        /*<NavLink to='/confirm'> */ //Se comento ir a la parte de confirmación por reduncancia
                        <ButtonBlue onClick={() => {navigate('/legal_representative')}} styleClass={'btn btn-blue btn-lg text-white fs-header-9 btn-login width-btutton'} spanClass={'span_icon'} iconColor={'icon-button-blue'} value={'Continuar'} />
                      ) : (
                        <div></div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

      </section>
    </div>
    </>
  )
}
export default PreaprobadoView;