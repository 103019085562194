import React from 'react';
import ButtonTransparent from '../buttons/button_transparent.js';
//import {financiamientoSchema} from '../../validations/FinanciamientoValidation.js';

const LiquidezCard = ({total, tasa, onSubmit, error}) => {

    return(

        <div className="card liquidez-card mx-md-auto mx-lg-0">
            <div className='title_content'>
                <span className='card_title'>Obtén la liquidez que</span>
                <br/>
                <span className='card_title_blue'>necesita tu negocio</span>
                <br/>
                <span className='card_title_blue'>para crecer</span>
                <p className='fs-7 mt-3 mb-1'>Anticipa tu factura.</p>
            </div>
            <div className='monto shadow-price margin-financiamiento mt-3 mt-mobil'>
                {total}<small className='mxn ms-1'>MXN</small>
            </div>
            <div class="tasa shadow-price mt-1 mb-3 mt-mobil">
                {tasa && tasa > 0 ? ( <span className='mt-1'>Tasa mensual del {tasa}%</span>) : (<div><span className='mt-1'>Tasa mensual</span></div>) }
            </div>
            {error && <div style={{color: 'red', fontSize: '14px'}}>Elija los valores correctamente</div>}
            <ButtonTransparent onSumbit={() => onSubmit()} styleClass={'btn btn-blue-light px-1 w-50 mx-auto align-middle border-2 margin-card-button'} spanClass={'span_icon ps-0'} iconColor={'icon-button-blue'} value={'Solicitar'} />
        </div>
    )
}

export default LiquidezCard;