import React from 'react';
import Header from '../../components/header/header.jsx';
import Success from '../../components/landing3/success.jsx';

function SuccessView() {
    return(
        <>
        <Header />
        <div>
            <Success />
        </div>
        </>
    )
}

export default SuccessView;