import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  description: {
    paddingLeft: '0.5rem !important',
    fontSize: '1rem!important',
    marginBottom: '0 !important',
    marginTop: '0 !important',
    color: '#361FF5 !important',
    [theme.breakpoints.up('md')]: {
      color: '#361FF5 !important',
    },
  },
}));

export default useStyles;
