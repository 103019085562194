import React, {useState} from 'react';
import {
  ContAccordion,
  ContAccordionItem,
  ContAccordionItemInner,
  ContIcon,
  ContName,
  ContSubItem, DataInfo, DataName,
} from './styles';
import AddCircle from './add_circle_outline.svg';
import DownCircle from './remove_circle_outline.svg';

export const AccordionMyS = ({data}) => {
  const [stData, setStData] = useState(data);
  const [active, setActive] = useState(false);

  const fnToggleLevels = index => {
    let arStates = stData.slice();
    arStates[index].show = !arStates[index].show;
    setStData(arStates);

    setActive(!active);
  };

  return (
    <ContAccordion id='cont-accordion'>
      {stData.map((item, index) => {
        return (
          <ContAccordionItem key={index} id='accordion-item'>
            {/*<ContAccordionItemInner onClick={() => fnToggleLevels(index)} active={active}>*/}
            <ContAccordionItemInner onClick={() => fnToggleLevels(index)} >
              <ContName>
                <DataName>{item.dataFirst}</DataName>
              </ContName>
              <ContIcon>
                <img src={item.show ? DownCircle : AddCircle}  alt={''}/>
              </ContIcon>
            </ContAccordionItemInner>

            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataSecond}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataThird}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataQuarter}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataFive}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
          </ContAccordionItem>
        );
      })}
    </ContAccordion>
  );
};
