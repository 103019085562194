import useStyles from './list.styles';

export default function List ({label, size}) {
  const classes = useStyles();
  return (
  <div class="row mx-md-auto mb-3 mt-2 mb-md-2 mt-md-2 align-items-center" id={'List'}>
	  <div className="col-1">
      <svg width="24" height="24" className="mt-0" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#361FF5"/>
        <g clip-path="url(#clip0_151_2639)">
        <path d="M10.2857 14.537L7.88567 12.137C7.61824 11.8696 7.1931 11.8696 6.92567 12.137C6.65824 12.4044 6.65824 12.8296 6.92567 13.097L9.79881 15.9701C10.0662 16.2376 10.4982 16.2376 10.7657 15.9701L18.0342 8.70842C18.3017 8.44099 18.3017 8.01585 18.0342 7.74842C17.7668 7.48099 17.3417 7.48099 17.0742 7.74842L10.2857 14.537Z" fill="#F7F7F7"/>
        </g>
        <defs>
        <clipPath id="clip0_151_2639">
        <rect width="16.4571" height="16.4571" fill="white" transform="translate(4.11426 3.42871)"/>
        </clipPath>
        </defs>
      </svg>
    </div>
	  <div className="col-9">
      <p className={classes.description}>{label}</p> 
    </div>
	  <div className="col-2">
   <span className="float-end list-size text-muted text-md-dark" >{size} MB</span>
  	</div>
  </div>
                        
  );
} 