import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  Paper,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import useStyles from './styles';
import { numberFormat } from '../../../../utils/formater.js';

import Label from '../../../../components/Label/Label.jsx';

function PropietiesView({ config }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box mb={4} className={classes.box_container} id='propieties_box_container'>
    <Grid container spacing={4} className={classes.container_amount} id='container_amount'>
      <Grid item md={4} xs={12} id='grid-contract' className={classes.cont_databox}>
        <CardHeader
          title={(<Typography
            children="Número del contrato"
            variant="caption"
          />)}
          className={classes.cont_header_amount_title}
        />
        <CardContent
          className={classes.amount_pos_card}
          children={<Typography
            className={classes.amount_pos}
            children={config?.contract}
            variant="h3"
          />}
        />
      </Grid>
      <Grid item md={4} xs={12} id='grid-type' className={classes.cont_databox}>
        <CardHeader
          title={(<Typography
            children="Tasa de interes anual"
            variant="caption"
          />)}
          className={classes.cont_header_amount_title}
        />
        <CardContent
          className={classes.amount_pos_card}
          children={<Typography
          className={classes.amount_pos}
          children={config?.interest_rate + '%' || '0%'}
          variant="h3"
        />}
        />
      </Grid>
      <Grid item md={4} xs={12} id='grid-cuotas' className={classes.cont_databox}>
        <CardHeader
          title={(<Typography
            children="Número de cuotas"
            variant="caption"
          />)}
          className={classes.cont_header_amount_title}
        />
        <CardContent
          className={classes.amount_pos_card}
          children={<Typography
          className={classes.amount_pos}
          children={config?.installments}
          variant="h3"
        />}
        />
      </Grid>
    </Grid>
    <Grid container spacing={4} className={classes.container_amount} id='second_container'>
      <Grid item md={4} xs={12} id='grid-contract' className={classes.cont_databox}>
        <CardHeader
          title={(<Typography
            children="Periocidad de pago"
            variant="caption"
          />)}
          className={classes.cont_header_amount_title}
        />
        <CardContent
          className={classes.amount_pos_card}
          children={<Typography
            className={classes.amount_pos}
            children={config?.periodicity}
            variant="h3"
          />}
        />
      </Grid>
      <Grid item md={4} xs={12} id='tasa-mora' className={classes.cont_databox}>
        <CardHeader
          title={(<Typography
            children="Tasa de interes moratoria"
            variant="caption"
          />)}
          className={classes.cont_header_amount_title}
        />
        <CardContent
          className={classes.amount_pos_card}
          children={<Typography
            className={classes.amount_pos}
            children={config?.interest_arrears + '%' || '0%'}
            variant="h3"
          />}
        />
      </Grid>
      <Grid item md={4} xs={12} id='grid-comisi' className={classes.cont_databox}>
        <CardHeader
          title={(<Typography
            children="Comisión por Apertura de Crédito"
            variant="caption"
          />)}
          className={classes.cont_header_amount_title}
        />
        <CardContent
          className={classes.amount_pos_card}
          children={<Typography
            className={classes.amount_pos}
            children={numberFormat(config?.commission_opening, '$')}
            variant="h3"
          />}
        />
      </Grid>
    </Grid>
    </Box>
  );
}

PropietiesView.propTypes = {
  account: PropTypes.object.isRequired,
};

export default PropietiesView;
