import PropTypes from 'prop-types';

import DynamicForm from '../../../components/DynamicForm/index';
import { addClabeSchema } from './validations/validations';

const fields = [
  {
    label: 'RFC',
    name: 'nif',
  },
  {
    label: 'Nombre',
    name: 'name',
  },
  {
    label: 'Correo electrónico',
    name: 'email',
    type: 'email',
  },
  {
    label: 'Teléfono',
    name: 'phone',
    type: 'number',
    display: {
      breakpoints: {
        md: 6,
      },
    },
    settings: {
      format: 'mx_phone',
    },
  },
  {
    label: 'CLABE',
    name: 'account',
    type: 'number',
    display: {
      breakpoints: {
        md: 6,
      },
    },
    settings: {
      format: 'clabe',
    },
  },
];

function AddAccountForm({ handleSubmitData, isProcessing }) {
  return (
    <DynamicForm
      actions={[
        {
          id: 'btnSend',
          name: 'btnSend',
          color: 'primary',
          label: 'Aceptar',
          processing: isProcessing,
          type: 'submit',
          typeButton: 'login',
        },
      ]}
      id="formAccount"
      validationSchema={addClabeSchema}
      fields={fields}
      handleSubmitData={handleSubmitData}
    />
    
  );
}

AddAccountForm.defaultProps = {};

AddAccountForm.propTypes = {
  handleSubmitData: PropTypes.func.isRequired,
};

export default AddAccountForm;
