import React from 'react';
import { useState } from 'react';
import Upload from '../../img/UploadImg.png';
import Check from '../../img/icono-arriba.svg';
import '../../css/preaprobado/preaprobado.css';

const FacturaButton = ({titulo, monto, fecha_emision, select}) => {

    const [file, setFile] = useState(true);

    return(
      <div className="row p-0 h-100 mb-md-2">
        <div className="upload_block col-lg-1 col-sm-2 text-center pt-lg-3 pt-md-3 ms-2 pe-xs-0 pe-md-4">
          {file ? (
            <img className="upload_img mb-1" src={Upload} width='25' alt="Upload" />
            ) : (
            <img className="upload_img mb-1" src={Check} width='25' alt="Upload" />
            )
          }
        </div>
        <div className="upload_block upload-text col-lg-6 col-sm-5 text-start text-md-start text-sm-start pt-0 pt-md-2 align-middle">
          <p className="upload-title fs-md-6 text-dark lh-1 pt-2 pt-md-1 mb-1"> {titulo} &nbsp; {fecha_emision} </p>
          <p className="list-size text-muted text-md-dark pt-2">{monto}</p>
        </div>
        {select ? (
        <div className="upload_block col-lg-4 col-sm-3 text-center align-items-center align-middle pt-3">
          <button className="card-button-sm fs-7 py-2">
            Seleccionar
          </button>
        </div>) : (
          <></>
        )}
      </div>
    )
}

export default FacturaButton;