import * as Yup from 'yup';
import { parseISO, isDate } from 'date-fns';

// Esquema de validación para el campo de tipo fecha
const dateSchema = Yup.date()
  .transform((value, originalValue) => {
    if (originalValue) {
      const parsedDate = isDate(originalValue) ? originalValue : parseISO(originalValue);
      return parsedDate;
    }
    return null;
  })
  .typeError('Debe ser una fecha válida')
  .required('Este campo es requerido');

// Esquema de validación para el campo de tipo dinero
const currencySchema = Yup.number()
  .typeError('Debe ser un número válido')
  .required('Este campo es requerido');

export const addMovementSchema = Yup
  .object()
  .shape({
    date: dateSchema,
    amount: currencySchema,
    account: Yup
      .string()
      .required('Este campo es requerido')
  });