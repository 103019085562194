import React from 'react';
import { useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';

import Money from '../../../img/Panel/money.png';
import Flecha from '../../../img/Panel/Vector.png';
import Page from '../../../components/page/Page.jsx';
import DashHeader from '../../../components/header/dash_header.jsx';

import useStyles from './styles';


function FacturationView({title}) {
  const classes = useStyles();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
    <DashHeader />
      <div className={classes.ContHistoFac}>
      <div className="container ps-lx-5 pe-xl-5 ps-lg-5 pe-lg-5 ps-md-1 pe-md-1 mb-5">
        <Page title={title}>
          <div className="row  mb-4">
            <div className="col-12 text-center">
              <h4 className="finan-title mb-2">Mi historial <span className='color-blue'>de facturas</span></h4>
            </div>
          </div>
          <div className="row mt-4 ">
            <div className="col-12">
            <Grid item md={12} xs={12} id='grid-saldo' className={classes.cont_databox}>
              <Grid container spacing={4}>
                <Grid item md={1} xs={1} id='grid-img' className={classes.cont_img}>
                  <img src={Money} alt="Money" className={classes.img_money} />
                </Grid>
                <Grid item md={10} xs={10} id='grid-monto' className={classes.cont_databox}>
                  <CardHeader
                    title={(<Typography
                      className={classes.amount_pos}
                      children='$100,000.00'
                    //children={state?.amount === 0 ? '-' : numberFormat(state?.amount, '$')}
                      variant="h3"
                    />)}
                    className={classes.cont_header_amount_title}
                  />
                  <CardContent
                    className={classes.amount_pos_card}
                    children={
                      <Typography variant="caption" className={classes.cont_header_amount_title}>
                      Proxima fecha de pago: <b className={classes.date}>12 de mayo</b>
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item md={1} xs={1}>
                  <Link
                    component={RouterLink}
                    children={<img src={Flecha} alt="Arrow" />}
                    //to={`/dashboard/${state?.accounts?.id}`}
                    to={`/dashboard/details`}
                  />
                </Grid>
              </Grid>
            </Grid> {/* Fin Grid saldo */}
            </div>
          </div>
          <div className="row mt-4 ">
            <div className="col-12">
            <Grid item md={12} xs={12} id='grid-saldo' className={classes.cont_databox}>
              <Grid container spacing={4}>
                <Grid item md={1} xs={1} id='grid-img' className={classes.cont_img}>
                  <img src={Money} alt="Money" className={classes.img_money} />
                </Grid>
                <Grid item md={10} xs={10} id='grid-monto' className={classes.cont_databox}>
                  <CardHeader
                    title={(<Typography
                      className={classes.amount_pos}
                      children='$75,000.00'
                    //children={state?.amount === 0 ? '-' : numberFormat(state?.amount, '$')}
                      variant="h3"
                    />)}
                    className={classes.cont_header_amount_title}
                  />
                  <CardContent
                    className={classes.amount_pos_card}
                    children={
                      <Typography variant="caption" className={classes.cont_header_amount_title}>
                      Proxima fecha de pago: <b className={classes.date}>12 de mayo</b>
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item md={1} xs={1}>
                  <Link
                    component={RouterLink}
                    children={<img src={Flecha} alt="Arrow" />}
                    //to={`/dashboard/${state?.accounts?.id}`}
                    to={`/dashboard/details`}
                  />
                </Grid>
              </Grid>
            </Grid> {/* Fin Grid saldo */}
            </div>
          </div>
          <div className="row mt-4 ">
            <div className="col-12">
            <Grid item md={12} xs={12} id='grid-saldo' className={classes.cont_databox}>
              <Grid container spacing={4}>
                <Grid item md={1} xs={1} id='grid-img' className={classes.cont_img}>
                  <img src={Money} alt="Money" className={classes.img_money} />
                </Grid>
                <Grid item md={10} xs={10} id='grid-monto' className={classes.cont_databox}>
                  <CardHeader
                    title={(<Typography
                      className={classes.amount_pos}
                      children='$75,000.00'
                    //children={state?.amount === 0 ? '-' : numberFormat(state?.amount, '$')}
                      variant="h3"
                    />)}
                    className={classes.cont_header_amount_title}
                  />
                  <CardContent
                    className={classes.amount_pos_card}
                    children={
                      <Typography variant="caption" className={classes.cont_header_amount_title}>
                      Proxima fecha de pago: <b className={classes.date}>12 de abril</b>
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item md={1} xs={1}>
                  <Link
                    component={RouterLink}
                    children={<img src={Flecha} alt="Arrow" />}
                    //to={`/dashboard/${state?.accounts?.id}`}
                    to={`/dashboard/details`}
                  />
                </Grid>
              </Grid>
            </Grid> {/* Fin Grid saldo */}
            </div>
          </div>
          <div className="row mt-4 ">
            <div className="col-12">
            <Grid item md={12} xs={12} id='grid-saldo' className={classes.cont_databox}>
              <Grid container spacing={4}>
                <Grid item md={1} xs={1} id='grid-img' className={classes.cont_img}>
                  <img src={Money} alt="Money" className={classes.img_money} />
                </Grid>
                <Grid item md={10} xs={10} id='grid-monto' className={classes.cont_databox}>
                  <CardHeader
                    title={(<Typography
                      className={classes.amount_pos}
                      children='$105,000.00'
                    //children={state?.amount === 0 ? '-' : numberFormat(state?.amount, '$')}
                      variant="h3"
                    />)}
                    className={classes.cont_header_amount_title}
                  />
                  <CardContent
                    className={classes.amount_pos_card}
                    children={
                      <Typography variant="caption" className={classes.cont_header_amount_title}>
                      Proxima fecha de pago: <b className={classes.date}>15 de junio</b>
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item md={1} xs={1}>
                  <Link
                    component={RouterLink}
                    children={<img src={Flecha} alt="Arrow" />}
                    //to={`/dashboard/${state?.accounts?.id}`}
                    to={`/dashboard/details`}
                  />
                </Grid>
              </Grid>
            </Grid> {/* Fin Grid saldo */}
            </div>
          </div>
          <div className="row mt-4 ">
            <div className="col-12">
            <Grid item md={12} xs={12} id='grid-saldo' className={classes.cont_databox}>
              <Grid container spacing={4}>
                <Grid item md={1} xs={1} id='grid-img' className={classes.cont_img}>
                  <img src={Money} alt="Money" className={classes.img_money} />
                </Grid>
                <Grid item md={10} xs={10} id='grid-monto' className={classes.cont_databox}>
                  <CardHeader
                    title={(<Typography
                      className={classes.amount_pos}
                      children='$100,000.00'
                    //children={state?.amount === 0 ? '-' : numberFormat(state?.amount, '$')}
                      variant="h3"
                    />)}
                    className={classes.cont_header_amount_title}
                  />
                  <CardContent
                    className={classes.amount_pos_card}
                    children={
                      <Typography variant="caption" className={classes.cont_header_amount_title}>
                      <b className={classes.date}>Liquidado</b>
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item md={1} xs={1}>
                  <Link
                    component={RouterLink}
                    children={<img src={Flecha} alt="Arrow" />}
                    //to={`/dashboard/${state?.accounts?.id}`}
                    to={`/dashboard/details`}
                  />
                </Grid>
              </Grid>
            </Grid> {/* Fin Grid saldo */}
            </div>
          </div>
          <div className="row mt-4 ">
            <div className="col-12">
            <Grid item md={12} xs={12} id='grid-saldo' className={classes.cont_databox}>
              <Grid container spacing={4}>
                <Grid item md={1} xs={1} id='grid-img' className={classes.cont_img}>
                  <img src={Money} alt="Money" className={classes.img_money} />
                </Grid>
                <Grid item md={10} xs={10} id='grid-monto' className={classes.cont_databox}>
                  <CardHeader
                    title={(<Typography
                      className={classes.amount_pos}
                      children='$80,000.00'
                    //children={state?.amount === 0 ? '-' : numberFormat(state?.amount, '$')}
                      variant="h3"
                    />)}
                    className={classes.cont_header_amount_title}
                  />
                  <CardContent
                    className={classes.amount_pos_card}
                    children={
                      <Typography variant="caption" className={classes.cont_header_amount_title}>
                      Proxima fecha de pago: <b className={classes.date}>09 de octubre</b>
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item md={1} xs={1}>
                  <Link
                    component={RouterLink}
                    children={<img src={Flecha} alt="Arrow" />}
                    //to={`/dashboard/${state?.accounts?.id}`}
                    to={`/dashboard/details`}
                  />
                </Grid>
              </Grid>
            </Grid> {/* Fin Grid saldo */}
            </div>
          </div>
        </Page>
      </div>
      </div>
    </>
  );
}

FacturationView.defaultProps = {
    title: 'Historial de facturación',
  };
  
  FacturationView.propTypes = {
    title: PropTypes.string,
  };
  
  export default FacturationView;