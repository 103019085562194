import { makeStyles } from '@material-ui/core';
const colorPrincipal = '#361FF5';
export default makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  table: {
    '& .MuiPaper-root ': {
      backgroundColor: 'transparent !important',
      boxShadow: 'none',
    },
    '& .MuiTable-root': {
      '& .MuiTableBody-root .MuiButtonBase-root': {
        '& .MuiButton-label': {
          color: '#FFFFFF',
          fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
          fontSize: '14px !important',
          fontStyle: 'normal',
          fontWeight: 'bold !important',
          lineHeight: '150%',
        },
        backgroundColor: '#0EB0F1 !important',
        borderRadius: '16px',
        height: '40px',
        width: '110px',
      },
      '& .MuiTableBody-root > .MuiTableRow-root': {
        '&:nth-child(even)': {
          backgroundColor: '#FAF7FA',
        },
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        boxShadow: '0px 0px 8px rgba(14, 176, 241, 0.25)',
        height: '56px',
      },
      '& .MuiTableBody-root > .WithStyles(ForwardRef(TableRow))-root-63': {
        backgroundColor: 'transparent !important',
      },
      '& .MuiTableHead-root': {
        '& .MuiTableRow-head': {
          '& .MuiTableCell-head': {
            backgroundColor: 'transparent !important',
            color: '#00154B !important',
            fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
            fontSize: '14px !important',
            fontStyle: 'normal',
            fontWeight: 'bold !important',
            lineHeight: '150%',
            padding: '5px',
          },
          backgroundColor: 'transparent !important',
        },
        backgroundColor: 'transparent !important',
      },
      backgroundColor: 'transparent !important',
    },
  },
  tableTitle: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '16px !important',
    fontStyle: 'bold',
    fontWeight: '700 !important',
    lineHeight: '28px',
    color: '#090F37',
  },
  tableValues: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '18px !important',
    fontStyle: 'bold',
    fontWeight: '400 !important',
    lineHeight: '28px',
    color: '#090F37',
  },
  container_amount_principal: {
    backgroundColor: '#FFFFFF!important',
    margin: '20px 0px 0px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      height: '240px',
    },
  },
  container_amount: {
    marginBottom: '20px',
    background: '#FFFFFF',
    borderRadius: '16px',
    justifyContent: 'space-between',
    //boxShadow: '0px 0px 8px rgba(14, 176, 241, 0.25);',
    height: '120px',
    margin: '0',
    maxWidth: '100%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '240px',
    },
  },
  cont_databox: {
    background: '#F8F8F8',
    borderRadius: '10px',
  },
  cont_header_amount_title: {
    '& span': {
      color: '#000000 !important',
      opacity: '0.5 !important',
      fontFamily: 'Inter !important',
      fontSize: '14px !important',
      fontStyle: 'normal',
      fontWeight: 'bold !important',
      lineHeight: '150%',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px !important',
      },
    },
    padding: '5px 5px',
  },
  cardAmount: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #FFFFFFF',
    borderRadius: '20px',
    boxShadow: '0px 0px 8px rgba(14, 176, 241, 0.25)',
    padding: '10px 10px',
    textAlign: 'center !important',
  },
  cardAmountStatus: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #FFFFFFF',
    borderRadius: '20px',
    boxShadow: '0px 0px 8px rgba(14, 176, 241, 0.25)',
    padding: '10px 10px 10px 10px',
    textAlign: 'center !important',
  },
  cont_header_amount_title_principal: {
    '& span': {
      color: '#090F37 !important',
      fontFamily: 'Poppins, sans-serif !important',
      fontSize: '16px !important',
      fontStyle: 'normal',
      fontWeight: '400 !important',
      lineHeight: '22px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px !important',
      },
    },
  },
  amount_pos_card: {
    padding: '0 !important',
  },
  amount_pos_principal: {
    color: ({color})=> colorPrincipal,
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '28px !important',
    lineHeight: '36px',
    fontStyle: 'normal',
    fontWeight: '700 !important',
    paddingLeft: '15px',
  },
  amount_pos: {
    color: ({color})=> colorPrincipal,
    fontFamily: 'Inter, sans-serif !important',
    fontSize: '24px !important',
    fontStyle: 'normal',
    fontWeight: '700 !important',
    textAlign: 'left',
  },
  credit_pending: {
    '& span': {
      alignItems: 'center',
      color: '#ff9800',
      display: 'flex',
      fontFamily: 'Poppins, sans-serif !important',
      fontSize: '14px !important',
      fontStyle: 'normal',
      fontWeight: 'bold !important',
      justifyContent: 'center',
      lineHeight: '150%',
      textAlign: 'center',
      //textTransform: 'uppercase',
    },
    alignItems: 'center',
    backgroundColor: 'rgba(255, 152, 0, 0.08)',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    minWidth: '152px',
    padding: '10px 5px',
    width: '150px',
  },
  pay_credit: {
    backgroundColor: '#A3DA8D',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minWidth: '135px',
    width: '135px',
    height: '34px',
    minHeight: '34px',
    margin: '0 auto !important',
    '& span': {
      color: '#000000',
      //cursor: 'pointer',
      fontFamily: 'Poppins, sans-serif !important',
      fontSize: '14px !important',
      fontStyle: 'normal',
      fontWeight: 'bold !important',
      lineHeight: '16px',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    '& MuiCardHeader-content':{
      flex: '0 0 auto !important',
    },
  },
  container_table: {
    marginTop: '20px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));