const MESSAGES = {
  EMAIL: 'Escribe una dirección de correo electrónico válida',
  PASSWORD: 'La contraseña debe contener al menos 8 caracteres, mayúsculas, minúsculas y un digito.',
  REQUIRED: 'Campo requerido',
  URL: 'Escribe una URL válida',
  USERNAME: 'Escriba un teléfono o correo electrónico válido',
  TYPE_ERROR_NUMBER: 'Selecciona un elemento válido',
};

export default MESSAGES;
