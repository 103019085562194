const TAB = {
  AMORTIZATIONS: 'amortizations',
  GENERAL: 'general',
  MOVEMENTS: 'movements',
};

const TABS = [
  {
    label: 'Datos general',
    value: TAB.GENERAL,
  },
  //{
  //  label: 'Amortizaciones',
  //  value: TAB.AMORTIZATIONS,
  //},
  //{
  //  label: 'Movimientos',
  //  value: TAB.MOVEMENTS,
  //},
];

export {
  TABS,
};
