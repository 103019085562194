import axios from 'axios';

import Dynamicore from '../index.js';
import { SERVICES } from '../index.js';
import {
  API_CONTEXT,
  API_URL,
} from '../../../settings/index.js';

async function getPeople(id, token) {
  let data = {};
  if (token) {
    const { data: { data: peopleData = [] } } = await axios
      .get(`${API_URL}${SERVICES.PEOPLE}?id=${id}`,
        {
          headers: {
            Authorization: token,
            context: API_CONTEXT,
          },
        },
      );
    const [people] = peopleData;

    data = people;
  } else {
    data = await Dynamicore(SERVICES.PEOPLE).read({
      id,
    });
  }

  return data;
};

export default getPeople;
