import styled, { createGlobalStyle, css } from 'styled-components';

export const TitleSection = styled.div`
  top: ${props => props.top};
  font: 36px 'Poppins', sans-serif;
  color:  #090F37;
  font-style: normal;
  font-weight: bold;
  margin-bottom: -10px;
  text-align: center;
  
  &>span{
    color: #361FF5;
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 36px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 36px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 36px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 36px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 36px;
  }
  @media(min-width: 1920px){
    font-size: 36px;
  }
`