import { createElement } from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import Amplify from 'aws-amplify';
import { AWS_AMPLIFY } from './settings/aws/index.js';
import { Provider } from 'react-redux';
import store from './redux/store';
import { enableES5 } from 'immer';

enableES5();

//const store = configureStore();

Amplify.configure(AWS_AMPLIFY);

if (navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
}

//const StoreContext = React.createContext();

render(
  createElement(Provider, {
    children: createElement(App),
    store,
  }),
  document.getElementById('root'),
);

