import {
  colors,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginLeft: theme.spacing(2),
  },
  btnAction: {
    [theme.breakpoints.up('xs')]: {
      float: 'right',
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '25%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '25%',
    },
  },
  btnActionSendSMS: {
    [theme.breakpoints.up('xs')]: {
      left: '0',
      marginBottom: '35px',
      marginTop: '15px',
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      left: '35%',
      marginTop: '-58px',
      position: 'relative',
      width: '25%',
    },
    [theme.breakpoints.up('lg')]: {
      left: '35%',
      marginTop: '-58px',
      position: 'relative',
      width: '25%',
    },
  },
  card: {
    '& > *': {
      flexBasis: '50%',
      flexGrow: 1,
      width: '50%',
    },
    backgroundColor: 'transparent !important',
    boxShadow: '0 0 0 0 rgb(63 63 68 / 5%), 0 0 0 0 rgb(63 63 68 / 15%)',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'visible',
    position: 'relative',
    border: 'none !important',
  },
  cardCenter: {
    justifyContent: 'center',
  },
  cardSteps: {
    '& > *': {
      flexBasis: '50%',
      flexGrow: 1,
      width: '50%',
    },
    backgroundColor: 'transparent !important',
    boxShadow: '0px 0px 0px rgba(5, 152, 255, 0.1), 0px 0px 0px rgba(5, 152, 255, 0.08), 0px 0px 0px rgba(5, 152, 255, 0.08);',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'visible',
    position: 'relative',
    width: '100%',
  },
  content: {
    '&:nth-child(2)': {
      alignItems: 'center',
      display: 'flex !important',
      flexDirection: 'column',
      //justifyContent: 'end',
      paddingTop: '1rem',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'start',
      },
    },
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      marginTop: '0px',
      padding: 'none !important',
      width: '100%',
    },
  },
  contentBox: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    }
  },
  contentSteps: {
    '&:nth-child(2)': {
      alignItems: 'center',
      display: 'flex !important',
      flexDirection: 'column',
      justifyContent: 'end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'start',
      },
    },
    alignItems: 'start',
    display: 'flex !important',
    height: 'auto',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      display: 'flex',
      height: 'auto',
      justifyContent: 'center',
      marginTop: '0px',
      padding: 'none !important',
      width: '100%',
    },
  },
  contentStepsVerification: {
    '&:nth-child(2)': {
      alignItems: 'center',
      display: 'flex !important',
      flexDirection: 'column',
      justifyContent: 'end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'start',
      },
    },
    alignItems: 'center',
    display: 'flex !important',
    height: 'auto',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      display: 'flex',
      height: 'auto',
      justifyContent: 'center',
      marginTop: '0px',
      padding: 'none !important',
      width: '100%',
    },
  },
  content_center: {
    width: '100%',
    height: '100%',
    '& > div': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      height: '370px',
      marginTop: 0,
    },
  },
  container_title: {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '45px !important',
    fontWeight: '700 !important',
    display: 'flex !important',
  },
  container_title_blue: {
    color: '#090F37',
  },
  container_title_blue: {
    color: '#361FF5',
  },
  content_1: {
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      marginBottom: '35px',
    },
  },
  content_2: {
    '& > div': {
      width: '100%',
    },
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '370px',
      marginTop: 0,
    },
  },
  contentForm: {
    width: '438px',
    height: '570px',
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    boxShadow: '0px 8px 28px -6px #0B3569',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '320px',
      height: 'auto',
    },
    '& #signInForm input': {
      fontFamily: 'Poppins, sans-serif',
    },
    '& #signInForm label': {
      backgroundColor: '#FFF !important',
      paddingLeft: '3px !important',
      paddingRight: '3px !important',
    },
    '& #signInForm fieldset': {
      top: '0px !important',
    }
  },
  contentStepsForm: {
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    boxShadow: '0px 8px 28px -6px #0B3569',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    justifyContent: 'space-between',
    minHeight: '570px',
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      width: '320px',
    },
  },
  contentStepsFormVerification: {
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    boxShadow: '0px 8px 28px -6px #0B3569',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    justifyContent: 'space-between',
    minHeight: '500px',
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      width: '100%',
    },
  },
  contentStepsCompDicio: {
    width: 'auto !important',
  },
  contentStepsCompDicioListen: {
    '& > div': {
      marginBottom: '25px',
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  contentStepsCompDicioListenText: {
    color: '#00154B',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '18px',
    textAlign: 'center',
    width: '60%',
  },
  contentFormResult: {
    marginLeft: '-13%',
  },
  iconPC: {
    marginTop: '35px',
  },
  icon: {
    backgroundColor: colors.green[500],
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
    height: 64,
    left: theme.spacing(3),
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    width: 64,
  },
  logoInLogin: {
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      marginTop: '0px',
    },
  },
  root: {
    height: '100%',
    minHeight: '100%',
    position: 'relative',
    marginBottom: '180px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '180px',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '80px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '100px',
    },
  },
  media: {
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4,
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  bg: {
    backgroundColor: '#FFF',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  bgRegister: {
    backgroundColor: '#FFF',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  bgFullHeight: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 80px)',
    },
  },
  waves: {
    backgroundImage: 'url(/static/images/waves.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    bottom: 0,
    height: '100vh',
    left: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 0,
  },
  hi: {
    color: '#0b3569',
    fontSize: '48px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      fontWeight: 700,
    },
  },
  welcomeLogin: {
    color: '#0b3569',
    fontSize: '34px',
  },
  subTitle: {
    color: '#0b3569',
    fontSize: '34px',
    lineHeight: '34px',
    marginTop: '25px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      fontWeight: 700,
      marginTop: '0',
    },
  },
  welcome: {
    color: '#2c6bdd',
    fontSize: '34px',
    '& > span': {
      color: '#0b3569',
    },
  },
  back_footer: {
    backgroundColor: '#0b6bdd',
    bottom: '-79px',
    display: 'block',
    height: '0px',
    left: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 0,
  },
  footer: {
    alignItems: 'center',
    backgroundColor: '#000',
    bottom: 0,
    color: '#ffffff',
    display: 'flex',
    height: '0px',
    justifyContent: 'center',
    left: 0,
    position: 'relative',
    width: '100%',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'column-reverse',
      flexWrap: 'wrap',
      justifyContent: 'center',
      padding: '0',
      textAlign: 'center',
    },
  },
  footer_cont: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 24px',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '1266px',
      padding: '0 24px',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '1266px',
      padding: '0 24px',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'column-reverse',
      flexWrap: 'wrap',
      textAlign: 'center',
    },
  },
  footer_text: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexWrap: 'wrap',
      textAlign: 'center',
    },
  },
  cont_policies: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    '& a': {
      color: '#a7d3ff',
      textDecoration: 'none',
    },
    '& div': {
      marginLeft: '25px',
      [theme.breakpoints.down('sm')]: {
        margin: '0 12px 10px',
      },
    },
  },
  textStep: {
    marginBottom: '10px',
    textAlign: 'left',
  },
  lightBox: {
    alignItems: 'center',
    backdropFilter: 'blur(8px)',
    background: 'rgba(255, 255, 255, 0.2)',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 9,
  },
  contIconCodeOTP: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '15px',
    paddingTop: '25px',
  },
  contTextCodeOTP: {
    marginBottom: '15px',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '14px',
  },
  contStepsRegister: {
    position: 'relative',
    width: '80%',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
  contStepsTitle: {
    width: '100%',
    paddingLeft: '25px',
    textAlign: 'left',
  },
  stepsTitle: {
    paddingLeft: '15px',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '150%',
    color: '#0EB0F1',
  },
  stepsSubTitle: {
    paddingLeft: '15px',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '12px',
    color: '#3D3D3D',
  },
  contQR: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'start',
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'start',
      flexDirection: 'row',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  textQR: {
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#00154B',
    [theme.breakpoints.down('lg')]: {
      width: '33%',
    },
    [theme.breakpoints.down('md')]: {
      width: '33%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '25px',
    },
  },
  contLink: {
    width: '33%',
    padding: '10px 15px',
    border: '1px solid #0EB0F1',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      width: '33%',
    },
    [theme.breakpoints.down('md')]: {
      width: '33%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '25px',
    },
  },
  linkOut: {
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#0EB0F1',
    textDecoration: 'none',
  },
  contLine: {
    width: '100%',
    height: '1px',
    marginTop: '25px',
    marginBottom: '15px',
    backgroundColor: '#0EB0F1',
  },
  contBtnsVerify: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    float: 'right',
    [theme.breakpoints.down('lg')]: {
      width: '33%',
    },
    [theme.breakpoints.down('md')]: {
      width: '33%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '25px',
    },
  },
  contBtnVerify: {
    width: '100%',
    marginLeft: '15px',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '15px',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '15px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '5px',
      marginRight: '5px',
    },
  },
  textVerifyDicio: {
    marginTop: '25px',
    marginBottom: '15px',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#00154B',
  },
  textVerifyDicioTitle: {
    marginTop: '10px',
    marginBottom: '15px',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '24px',
    fontWeight: 'normal',
    color: '#00154B',
  },
  ulVerifyDicio: {
    marginBottom: '15px',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#00154B',
    '& > li': {
      marginLeft: '30px',
    },
    [theme.breakpoints.down('lg')]: {
      width: '320px',
    },
    [theme.breakpoints.down('md')]: {
      width: '320px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  contentInfoAndQR: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    flexDirection: 'row',
    '& > div': {
      paddingTop: '25px',
    },
  },
  contentFormCreditRequest: {
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    boxShadow: '0px 8px 28px -6px #0B3569',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    justifyContent: 'space-between',
    width: '438px',
    [theme.breakpoints.down('sm')]: {
      width: '320px',
      height: 'auto',
    },
  },
  contIconVerifyDicio: {
    marginBottom: '15px',
    paddingTop: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contTextVerifyDicio: {
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '14px',
    [theme.breakpoints.down('lg')]: {
      width: '80% !important',
      marginBottom: '50px',
    },
    [theme.breakpoints.down('md')]: {
      width: '80% !important',
      marginBottom: '50px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '0px',
    },
  },
  cardResponse: {
    '& > *': {
      flexBasis: '50%',
      flexGrow: 1,
      width: '50%',
    },
    alignItems: 'center',
    backgroundColor: 'transparent !important',
    boxShadow: '0 0 0 0 rgb(63 63 68 / 5%), 0 0 0 0 rgb(63 63 68 / 15%)',
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto',
    minHeight: '600px',
    overflow: 'visible',
    position: 'relative',
    width: '100%',
  },
  contentResponse: {
    height: 'auto',
    '&:nth-child(2)': {
      display: 'flex !important',
      alignItems: 'center',
      justifyContent: 'end',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'start',
      },
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      marginTop: '0px',
      padding: 'none !important',
      width: '100%',
    },
  },
  contTextTitleResponse: {
    [theme.breakpoints.down('xl')]: {
      width: '500px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  textResponseTitle: {
    color: '#0B3569',
    fontSize: '48px',
    fontWeight: 700,
    marginBottom: '25px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '40px',
    },
  },
  textResponseSubTitle: {
    color: '#0B3569',
    fontSize: '34px',
    marginBottom: '25px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
    },
  },
  contTextResponse: {
    position: 'relative',
    margin: '0 auto',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '14px',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '50px',
      width: '80% !important',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '50px',
      width: '80% !important',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '0px',
    },
  },
  contBtnsResponse: {
    width: '100%',
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '25px',
    },
  },
  contBtnResponse: {
    width: '100%',
    marginLeft: '15px',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '15px',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '15px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '5px',
      marginRight: '5px',
    },
  },
  left: {
    textAlign: 'left',
  },
  center: {
    textAlign: 'center',
  },
  space: {
    width: '100%',
    height: '50px',
  },
  header_imagotipo: {
    height: '56px',
    display: 'block',
    paddingTop: '20px',
    marginBottom: '20px !important',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  header_isotipo: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  space_inter_recovery_sended: {
    height: '200px',
    [theme.breakpoints.down('sm')]: {
      height: '20px',
    },
  },
  space_inter_recovery_sended_no: {
    height: '200px',
    [theme.breakpoints.down('sm')]: {
      height: '20px',
    },
  },
  space_top_recovery_forms: {
    height: '60px',
    [theme.breakpoints.down('sm')]: {
      height: '0px',
    },
  },
  container_icons: {
    bottom: '10%',
    display: 'block',
    height: '83%',
    left: 0,
    margin: '0 3%',
    position: 'absolute',
    width: '94%',
    zIndex: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  icon_1: {
    backgroundImage: 'url(/static/images/icons-auth/icon-1.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '140px',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '140px',
  },
  icon_2: {
    backgroundImage: 'url(/static/images/icons-auth/icon-2.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '140px',
    left: '35%',
    position: 'absolute',
    top: '70%',
    width: '140px',
  },
  icon_3: {
    backgroundImage: 'url(/static/images/icons-auth/icon-3.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100px',
    left: '10%',
    position: 'absolute',
    top: '55%',
    width: '100px',
  },
  icon_4: {
    backgroundImage: 'url(/static/images/icons-auth/icon-4.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100px',
    position: 'absolute',
    right: 0,
    top: '85%',
    width: '100px',
  },
  icon_5: {
    backgroundImage: 'url(/static/images/icons-auth/icon-5.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '80px',
    left: '0%',
    position: 'absolute',
    top: '80%',
    width: '80px',
  },
  icon_square_1: {
    backgroundImage: 'url(/static/images/icons-auth/icon-square-1.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '60px',
    position: 'absolute',
    right: '0%',
    top: '30%',
    width: '60px',
  },
  icon_square_2: {
    position: 'absolute',
    width: '60px',
    height: '60px',
    top: '50%',
    left: '0%',
    backgroundImage: 'url(/static/images/icons-auth/icon-square-2.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_square_3: {
    position: 'absolute',
    width: '60px',
    height: '60px',
    top: '90%',
    left: '50%',
    backgroundImage: 'url(/static/images/icons-auth/icon-square-3.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_square_4: {
    position: 'absolute',
    width: '40px',
    height: '40px',
    top: '60%',
    right: '8%',
    backgroundImage: 'url(/static/images/icons-auth/icon-square-4.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_square_5: {
    position: 'absolute',
    width: '40px',
    height: '40px',
    top: '60%',
    left: '30%',
    backgroundImage: 'url(/static/images/icons-auth/icon-square-5.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_square_6: {
    position: 'absolute',
    width: '40px',
    height: '40px',
    top: '90%',
    left: '13%',
    backgroundImage: 'url(/static/images/icons-auth/icon-square-6.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_square_7: {
    position: 'absolute',
    width: '20px',
    height: '20px',
    top: '60%',
    left: '52%',
    backgroundImage: 'url(/static/images/icons-auth/icon-square-7.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_square_8: {
    position: 'absolute',
    width: '20px',
    height: '20px',
    top: '90%',
    left: '27%',
    backgroundImage: 'url(/static/images/icons-auth/icon-square-8.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_blue_1: {
    position: 'absolute',
    width: '140px',
    height: '140px',
    top: 0,
    right: 0,
    backgroundImage: 'url(/static/images/icons-auth/icon-blue-1.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_blue_2: {
    position: 'absolute',
    width: '140px',
    height: '140px',
    top: '70%',
    left: '35%',
    backgroundImage: 'url(/static/images/icons-auth/icon-blue-2.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_blue_3: {
    position: 'absolute',
    width: '100px',
    height: '100px',
    top: '55%',
    left: '10%',
    backgroundImage: 'url(/static/images/icons-auth/icon-blue-3.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_blue_4: {
    position: 'absolute',
    width: '100px',
    height: '100px',
    top: '85%',
    right: 0,
    backgroundImage: 'url(/static/images/icons-auth/icon-blue-4.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_blue_5: {
    position: 'absolute',
    width: '80px',
    height: '80px',
    top: '80%',
    left: '0%',
    backgroundImage: 'url(/static/images/icons-auth/icon-blue-5.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_blue_square_1: {
    backgroundImage: 'url(/static/images/icons-auth/icon-blue-square-1.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '60px',
    position: 'absolute',
    right: '0%',
    top: '30%',
    width: '60px',
  },
  icon_blue_square_2: {
    backgroundImage: 'url(/static/images/icons-auth/icon-blue-square-2.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '60px',
    left: '0%',
    position: 'absolute',
    top: '50%',
    width: '60px',
  },
  icon_blue_square_3: {
    position: 'absolute',
    width: '60px',
    height: '60px',
    top: '90%',
    left: '50%',
    backgroundImage: 'url(/static/images/icons-auth/icon-blue-square-3.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_blue_square_4: {
    position: 'absolute',
    width: '40px',
    height: '40px',
    top: '60%',
    right: '8%',
    backgroundImage: 'url(/static/images/icons-auth/icon-blue-square-4.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_blue_square_5: {
    position: 'absolute',
    width: '40px',
    height: '40px',
    top: '60%',
    backgroundImage: 'url(/static/images/icons-auth/icon-blue-square-5.svg)',
    left: '30%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  icon_blue_square_6: {
    backgroundImage: 'url(/static/images/icons-auth/icon-blue-square-6.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '40px',
    left: '13%',
    position: 'absolute',
    top: '90%',
    width: '40px',
  },
  icon_blue_square_7: {
    backgroundImage: 'url(/static/images/icons-auth/icon-blue-square-7.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '20px',
    left: '52%',
    position: 'absolute',
    top: '60%',
    width: '20px',
  },
  icon_blue_square_8: {
    backgroundImage: 'url(/static/images/icons-auth/icon-blue-square-8.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '20px',
    left: '27%',
    position: 'absolute',
    top: '90%',
    width: '20px',
  },
}));

export default useStyles;
