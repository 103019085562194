import React from 'react';
import '../../../css/liquidez.css';
import Man from '../../../img/blueImgMan.png';

const Liquidez = () =>{
  return(
    <section id="liquidez" title="liquidez">
      <div className='container row ps-0 pe-0 ms-auto me-auto'>
        <div className='col-lg-7 col-md-12 col-sm-12 pe-md-0'>
          <div className="ps-lg-4 ps-md-4 offset-md-1 margin-text">
            <h4 className="titulo mb-2">¿Cómo descontar
            <br/>
            <span className='color-blue'> tus facturas en cuestion de minutos?</span>
            </h4>
            <p className='sub_titulo fs-6 mb-4'>Sigue los siguientes pasos y obtén la pre-aprobación en minutos mediante un proceso 100% digital.</p>
            <ul className='mt-1 fs-6 w-75'>
              <li className="mb-2 pt-2 pb-1">
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17.5" cy="17.5" r="17.5" fill="#361FF5"/>
                <path d="M14.684 12.48V10.986H18.068V24H16.412V12.48H14.684Z" fill="white"/>
                </svg>
                <span>Simula tu financiamiento.</span>
              </li>
              <li className="mb-2 pt-2 pb-1">
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17.5" cy="17.5" r="17.5" fill="#361FF5"/>
                <path d="M13.2412 22.686C14.7652 21.462 15.9592 20.46 16.8232 19.68C17.6872 18.888 18.4132 18.066 19.0012 17.214C19.6012 16.35 19.9012 15.504 19.9012 14.676C19.9012 13.896 19.7092 13.284 19.3252 12.84C18.9532 12.384 18.3472 12.156 17.5072 12.156C16.6912 12.156 16.0552 12.414 15.5992 12.93C15.1552 13.434 14.9152 14.112 14.8792 14.964H13.2952C13.3432 13.62 13.7512 12.582 14.5192 11.85C15.2872 11.118 16.2772 10.752 17.4892 10.752C18.7252 10.752 19.7032 11.094 20.4232 11.778C21.1552 12.462 21.5212 13.404 21.5212 14.604C21.5212 15.6 21.2212 16.572 20.6212 17.52C20.0332 18.456 19.3612 19.284 18.6052 20.004C17.8492 20.712 16.8832 21.54 15.7072 22.488H21.8992V23.856H13.2412V22.686Z" fill="white"/>
                </svg>
                <span>Llena el formulario, ten a la mano el RFC y CIEC de la empresa.</span>
              </li>
              <li className="mb-2 pt-2 pb-1">
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17.5" cy="17.5" r="17.5" fill="#361FF5"/>
                <path d="M13.2802 14.262C13.3642 13.158 13.7902 12.294 14.5582 11.67C15.3262 11.046 16.3222 10.734 17.5462 10.734C18.3622 10.734 19.0642 10.884 19.6522 11.184C20.2522 11.472 20.7022 11.868 21.0022 12.372C21.3142 12.876 21.4702 13.446 21.4702 14.082C21.4702 14.826 21.2542 15.468 20.8222 16.008C20.4022 16.548 19.8502 16.896 19.1662 17.052V17.142C19.9462 17.334 20.5642 17.712 21.0202 18.276C21.4762 18.84 21.7042 19.578 21.7042 20.49C21.7042 21.174 21.5482 21.792 21.2362 22.344C20.9242 22.884 20.4562 23.31 19.8322 23.622C19.2082 23.934 18.4582 24.09 17.5822 24.09C16.3102 24.09 15.2662 23.76 14.4502 23.1C13.6342 22.428 13.1782 21.48 13.0822 20.256H14.6662C14.7502 20.976 15.0442 21.564 15.5482 22.02C16.0522 22.476 16.7242 22.704 17.5642 22.704C18.4042 22.704 19.0402 22.488 19.4722 22.056C19.9162 21.612 20.1382 21.042 20.1382 20.346C20.1382 19.446 19.8382 18.798 19.2382 18.402C18.6382 18.006 17.7322 17.808 16.5202 17.808H16.1062V16.44H16.5382C17.6422 16.428 18.4762 16.248 19.0402 15.9C19.6042 15.54 19.8862 14.988 19.8862 14.244C19.8862 13.608 19.6762 13.098 19.2562 12.714C18.8482 12.33 18.2602 12.138 17.4922 12.138C16.7482 12.138 16.1482 12.33 15.6922 12.714C15.2362 13.098 14.9662 13.614 14.8822 14.262H13.2802Z" fill="white"/>
                </svg>
                <span>Autoriza consulta buró.</span>
              </li>
              <li className="mb-2 pt-2 pb-1">
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17.5" cy="17.5" r="17.5" fill="#361FF5"/>
                <path d="M15 21.1999L11.5 17.6999C11.11 17.3099 10.49 17.3099 10.1 17.6999C9.71 18.0899 9.71 18.7099 10.1 19.0999L14.29 23.2899C14.68 23.6799 15.31 23.6799 15.7 23.2899L26.3 12.6999C26.69 12.3099 26.69 11.6899 26.3 11.2999C25.91 10.9099 25.29 10.9099 24.9 11.2999L15 21.1999Z" fill="#F7F7F7"/>
                <circle cx="17.5" cy="17.5" r="17.5" fill="#361FF5"/>
                <path d="M20.7626 12.408H14.4446V16.512C14.7206 16.128 15.1286 15.816 15.6686 15.576C16.2086 15.324 16.7906 15.198 17.4146 15.198C18.4106 15.198 19.2206 15.408 19.8446 15.828C20.4686 16.236 20.9126 16.77 21.1766 17.43C21.4526 18.078 21.5906 18.768 21.5906 19.5C21.5906 20.364 21.4286 21.138 21.1046 21.822C20.7806 22.506 20.2826 23.046 19.6106 23.442C18.9506 23.838 18.1286 24.036 17.1446 24.036C15.8846 24.036 14.8646 23.712 14.0846 23.064C13.3046 22.416 12.8306 21.552 12.6626 20.472H14.2646C14.4206 21.156 14.7506 21.69 15.2546 22.074C15.7586 22.458 16.3946 22.65 17.1626 22.65C18.1106 22.65 18.8246 22.368 19.3046 21.804C19.7846 21.228 20.0246 20.472 20.0246 19.536C20.0246 18.6 19.7846 17.88 19.3046 17.376C18.8246 16.86 18.1166 16.602 17.1806 16.602C16.5446 16.602 15.9866 16.758 15.5066 17.07C15.0386 17.37 14.6966 17.784 14.4806 18.312H12.9326V10.968H20.7626V12.408Z" fill="white"/>
                </svg>
                <span>Sube tus documentos.</span>
              </li>
              <li className="mb-2 pt-2 pb-1">
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17.5" cy="17.5" r="17.5" fill="#361FF5"/>
                <path d="M12.0598 21.102V19.86L18.3778 11.112H20.3398V19.68H22.1398V21.102H20.3398V24H18.7198V21.102H12.0598ZM18.7918 12.822L13.9498 19.68H18.7918V12.822Z" fill="white"/>
                </svg>
                <span>Acepta la oferta.</span>
              </li>
              <li className="mb-2 pt-2 pb-1">
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17.5" cy="17.5" r="17.5" fill="#361FF5"/>
                <path d="M15 21.1999L11.5 17.6999C11.11 17.3099 10.49 17.3099 10.1 17.6999C9.71 18.0899 9.71 18.7099 10.1 19.0999L14.29 23.2899C14.68 23.6799 15.31 23.6799 15.7 23.2899L26.3 12.6999C26.69 12.3099 26.69 11.6899 26.3 11.2999C25.91 10.9099 25.29 10.9099 24.9 11.2999L15 21.1999Z" fill="#F7F7F7"/>
                <circle cx="17.5" cy="17.5" r="17.5" fill="#361FF5"/>
                <path d="M19.6571 14.082C19.3931 12.75 18.5711 12.084 17.1911 12.084C16.1231 12.084 15.3251 12.498 14.7971 13.326C14.2691 14.142 14.0111 15.492 14.0231 17.376C14.2991 16.752 14.7551 16.266 15.3911 15.918C16.0391 15.558 16.7591 15.378 17.5511 15.378C18.7871 15.378 19.7711 15.762 20.5031 16.53C21.2471 17.298 21.6191 18.36 21.6191 19.716C21.6191 20.532 21.4571 21.264 21.1331 21.912C20.8211 22.56 20.3411 23.076 19.6931 23.46C19.0571 23.844 18.2831 24.036 17.3711 24.036C16.1351 24.036 15.1691 23.76 14.4731 23.208C13.7771 22.656 13.2911 21.894 13.0151 20.922C12.7391 19.95 12.6011 18.75 12.6011 17.322C12.6011 12.918 14.1371 10.716 17.2091 10.716C18.3851 10.716 19.3091 11.034 19.9811 11.67C20.6531 12.306 21.0491 13.11 21.1691 14.082H19.6571ZM17.2091 16.764C16.6931 16.764 16.2071 16.872 15.7511 17.088C15.2951 17.292 14.9231 17.61 14.6351 18.042C14.3591 18.462 14.2211 18.978 14.2211 19.59C14.2211 20.502 14.4851 21.246 15.0131 21.822C15.5411 22.386 16.2971 22.668 17.2811 22.668C18.1211 22.668 18.7871 22.41 19.2791 21.894C19.7831 21.366 20.0351 20.658 20.0351 19.77C20.0351 18.834 19.7951 18.102 19.3151 17.574C18.8351 17.034 18.1331 16.764 17.2091 16.764Z" fill="white"/>
                </svg>
                <span>Firma digital del contrato.</span>
              </li>
              <li className="mb-2 pt-2 pb-1">
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="17.5" cy="17.5" r="17.5" fill="#361FF5"/>
                <path d="M15 21.1999L11.5 17.6999C11.11 17.3099 10.49 17.3099 10.1 17.6999C9.71 18.0899 9.71 18.7099 10.1 19.0999L14.29 23.2899C14.68 23.6799 15.31 23.6799 15.7 23.2899L26.3 12.6999C26.69 12.3099 26.69 11.6899 26.3 11.2999C25.91 10.9099 25.29 10.9099 24.9 11.2999L15 21.1999Z" fill="#F7F7F7"/>
                <circle cx="17.5" cy="17.5" r="17.5" fill="#361FF5"/>
                <path d="M15 21.1999L11.5 17.6999C11.11 17.3099 10.49 17.3099 10.1 17.6999C9.71 18.0899 9.71 18.7099 10.1 19.0999L14.29 23.2899C14.68 23.6799 15.31 23.6799 15.7 23.2899L26.3 12.6999C26.69 12.3099 26.69 11.6899 26.3 11.2999C25.91 10.9099 25.29 10.9099 24.9 11.2999L15 21.1999Z" fill="#F7F7F7"/>
                <circle cx="17.5" cy="17.5" r="17.5" fill="#361FF5"/>
                <path d="M21.2129 12.264L16.2089 24H14.5529L19.6289 12.426H12.6809V11.022H21.2129V12.264Z" fill="white"/>
                </svg>
                <span>Recibe los fondos.</span>
              </li>
            </ul>
          </div>
        </div>
        <div className='col-lg-5 col-md-12 col-sm-12 d-none d-lg-block ps-lg-3 pt-5 text-center '>
            <img className='man-img mt-4' src={Man} width="100" height="auto" alt="financiemiento" />
        </div>
      </div>
    </section>
  )
}

export default Liquidez;