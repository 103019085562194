import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import DynamicForm from '../../../components/DynamicForm/index';
import { addMovementSchema } from './validations/validations';

const fields = [
  {
    label: 'Fecha de movimiento',
    name: 'date',
    type: 'date',
    display: {
      breakpoints: {
        md: 6,
      },
    },
  },
  {
    label: 'Monto',
    name: 'amount',
    type: 'number',
    display: {
      breakpoints: {
        md: 6,
      },
    },
    settings: {
      format: 'currency',
    },
  },
  //{
  //  label: 'Cuenta destino',
  //  name: 'account',
  //  type: 'number',
  //  settings: {
  //    format: 'clabe',
  //  },
  //},
];

function AddMovementForm({ handleSubmitData, destinations, isProcessing }) {
  const [sendfields, setSendFields] = useState([]);

  useEffect(() => {
    if (destinations) {
      const data = destinations.accountsDestination;
      setSendFields([
        {
          label: 'Fecha de movimiento',
          name: 'date',
          type: 'date',
          display: {
            breakpoints: {
              md: 6,
            },
          },
        },
        {
          label: 'Monto',
          name: 'amount',
          type: 'number',
          display: {
            breakpoints: {
              md: 6,
            },
          },
          settings: {
            format: 'currency',
          },
        },
        {
          label: 'Cuenta Destino',
          name: 'account',
          type: 'select',
          display: {
            breakpoints: {
              md: 6,
            },
          },
          settings: {
            emptyElement: 'Selecciona',
            options: data,
          },
        },
      ]);
    }
  }, [destinations]);

  return (
    <DynamicForm
      actions={[
        {
          id: 'btnSend',
          name: 'btnSend',
          color: 'primary',
          label: 'Aceptar',
          processing: isProcessing,
          type: 'submit',
          typeButton: 'login',
        },
      ]}
      id="frmAddMovement"
      initialValues={{date: new Date()}}
      validationSchema={addMovementSchema}
      fields={sendfields}
      handleSubmitData={handleSubmitData}
    />
    
  );
}

AddMovementForm.defaultProps = {};

AddMovementForm.propTypes = {
  handleSubmitData: PropTypes.func.isRequired,
};

export default AddMovementForm;
