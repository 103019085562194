import numeral from 'numeral';
import Rates from './catalog';

export function formatNumber(number) {
  return new Intl.NumberFormat( 'ES-MX', {style: 'currency', currency: 'MXN', maximumFractionDigits: 0, minimumFractionDigits: 0} ).format(number);
}

//funcion para poner coma a los numeros
export function numberPrefix(value = 0, prefix = null) {
  return numeral(value)
  .format(`${prefix ? prefix.concat(' ') : ''} ${'0,0'}`);
}

//Funcion para calcular el monto de la factura anual de la empresa
export function getMonto(porcentage, factura) {
  return (
    (porcentage/100) * factura
  );
}

//Funcion para asignarle 0 al valor de la factura anual de la empresa si este es nulo
export function validateAnualValue(value) {
  if (value === 'undefined' || value === null || value === ''){
    value = 0;
  }
  return value;
}

//Funcion para asignarle 0 al valor de la salud de la empresa si este es nulo
export function validateHealthValue(value) {
  if (value === 'undefined' || value === null || value === ''){
    value = 0;
  }
  return value;
}

//Funcion para asignar valor a la capacidad de pago de la empresa
export function validateCapacidadValue(valueHealth, valueAnual, valueFactura) {
  //2-SI(M19=1,(M16+M16*0.15+M7)/M16,SI(M19=2,(M16+M16*0.15+M7)/M16,SI(M19=3,(M16+M16*0.15+M7)/M16)))
  //M19 = valueHealth
  //M16 = valueAnual
  //M7 = valueFactura
  if (valueHealth === '1') {
    return (2-(valueAnual + valueAnual * 0.15 + valueFactura) / valueAnual).toFixed(2);
  } else if (valueHealth === '2') {
    return (2-(valueAnual + valueAnual * 0.15 + valueFactura) / valueAnual).toFixed(2);
  } else if (valueHealth === '3') {
    return (2-(valueAnual + valueAnual * 0.15 + valueFactura) / valueAnual).toFixed(2);
  }
}

export function getDays(plazo) {
  const operacion = (120-plazo)/120;
  return operacion;
}


//Funcion para obtener la Tasa mensual de la empresa respecto a la capacidad de pago
const tasaAnual24 = 24;
const tasaAnual28 = 28;
const tasaAnual35 = 35;

export function getTasa(value) {
  if (value > 0 && value <= 0.44){
    const tasa1 = tasaAnual24/12; //2
    return tasa1;
  } else {
    if (value > 0 && value > 0.44  && value <=  0.7){
        const tasa2 = tasaAnual28/12;
        return tasa2.toFixed(2); //2.33
    } else {
        if ( value > 0 && value > 0.7 ){
            const tasa3 = tasaAnual35/12;
            return tasa3.toFixed(2); //2.91
        }
    }
  }
}

//Funcion para obtener la nueva Tasa segun el Excel, recibe la calificación calculada
export function getNewTasa(calification) {
  //console.log('calification en getNewTasa', calification);
  const rates = Rates;
  //console.log('rates en getNewTasa', rates);

  const rate = rates.find((rate) => {
    return rate.comp === calification;
  });

  //console.log('rate en getNewTasa', rate);

  if (rate === undefined) {
    return 0;
  } else {
  const tasa = (rate.rate/ 12).toFixed(2);
  //console.log('tasa en getNewTasa', tasa);

  return tasa;
  }
}