import React from 'react';
import Header from '../../components/header/header.jsx';

export default function NotFound() {
    return (
        <>
        <Header onlyLogo={true} />
        <div className="mb-5">
            <section id="sectionrefused" className='mt-md-5 pt-md-4 text-center'>
                <h1>Error 404</h1>
                <br/>
                <h2>Página no encontrada</h2>
                <div class="text-center mt-5">
                    <a href="/" class="btn btn-blue btn-lg text-white btn-login width-btutton">Volver al inicio</a>
                </div>
            </section>
        </div>
        </>
    );
}