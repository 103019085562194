import React from 'react';
import SelectButton from '../buttons/select_button';

export default function ListConfirm ({label, size}) {
  return (
    
    <div class="col-10 col-md-8 mx-md-auto px-md-4 px-4 mb-3 mt-0  mb-md-2 mt-md-0 row">
	<div className="col-2 col-md-1 h-100 ">
      <svg width="26" height="26" className="mx-4 mx-md-0 float-md-end mt-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#361FF5"/>
        <g clip-path="url(#clip0_151_2639)">
        <path d="M10.2857 14.537L7.88567 12.137C7.61824 11.8696 7.1931 11.8696 6.92567 12.137C6.65824 12.4044 6.65824 12.8296 6.92567 13.097L9.79881 15.9701C10.0662 16.2376 10.4982 16.2376 10.7657 15.9701L18.0342 8.70842C18.3017 8.44099 18.3017 8.01585 18.0342 7.74842C17.7668 7.48099 17.3417 7.48099 17.0742 7.74842L10.2857 14.537Z" fill="#F7F7F7"/>
        </g>
        <defs>
        <clipPath id="clip0_151_2639">
        <rect width="16.4571" height="16.4571" fill="white" transform="translate(4.11426 3.42871)"/>
        </clipPath>
        </defs>
      </svg>
    </div>
	<div className="col-9 col-md-9">
          <p className="ps-2 ps-md-0 fs-6 mb-0 mt-md-1">{label}</p> 
   	 <span className=" list-size text-muted text-md-dark ms-2 ms-md-0" >{size}</span>
        </div>
	<div className="col-1">
<SelectButton value={'ELIMINAR'} styleClass='card-button' />
    	</div>
    </div>
                        
  );
}