import { makeStyles } from '@material-ui/core';
const colorPrincipal = '#361FF5';
export default makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  box_container: {
    height: '100%',
  },
  table: {
    minWidth: 900,
  },
  container_amount: {
    marginBottom: '20px',
    background: '#FFFFFF',
    borderRadius: '16px',
    justifyContent: 'space-between',
    //boxShadow: '0px 0px 8px rgba(14, 176, 241, 0.25);',
    height: '120px',
    margin: '0',
    maxWidth: '100%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '240px',
    },
  },
  cont_databox: {
    background: '#F8F8F8',
    borderRadius: '10px',
  },
  cont_header_amount_title: {
    '& span': {
      color: '#000000 !important',
      opacity: '0.5 !important',
      fontFamily: 'Inter !important',
      fontSize: '14px !important',
      fontStyle: 'normal',
      fontWeight: 'bold !important',
      lineHeight: '150%',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px !important',
      },
    },
    padding: '5px 5px',
  },
  amount_pos_card: {
    padding: '0 !important',
  },
  amount_pos: {
    color: ({color})=> colorPrincipal,
    fontFamily: 'Inter, sans-serif !important',
    fontSize: '24px !important',
    fontStyle: 'normal',
    fontWeight: '700 !important',
    textAlign: 'left',
    cursor: 'pointer',
  },
  credit_pending: {
    '& span': {
      alignItems: 'center',
      color: '#ff9800',
      display: 'flex',
      fontFamily: 'Poppins, sans-serif !important',
      fontSize: '14px !important',
      fontStyle: 'normal',
      fontWeight: 'bold !important',
      justifyContent: 'center',
      lineHeight: '150%',
      textAlign: 'center',
      //textTransform: 'uppercase',
    },
    alignItems: 'center',
    backgroundColor: 'rgba(255, 152, 0, 0.08)',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    minWidth: '152px',
    padding: '10px 5px',
    width: '150px',
  },
}));