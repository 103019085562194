import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

import FORMATS from '../utils/formats';

function InputMxPhone(props) {
  const { name, inputRef, onChange, onBlur, ...other } = props;

  return (
    <NumberFormat
      {...other}
      format={FORMATS.MX_PHONE}
      getInputRef={inputRef}
      isNumericString
      type="tel"
      onBlur={onBlur}
      onValueChange={({ value }) => {
        onChange({
          target: { name, value },
        });
      }}
    />
  );
}

InputMxPhone.propTypes = {
  name: PropTypes.string,
  inputRef: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export default InputMxPhone;
