import {useState, useEffect} from 'react';
import {Navigate} from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';

//import { getCurrentSessionData } from '../../utils/aws/cognito.js';

function Validateauth({ children }) {

    const [isAuth, setIsAuth] = useState();

    const checkAuth = async () => {
        try {
          await Auth.currentAuthenticatedUser();
          setIsAuth(true);
        } catch {
          setIsAuth(false);
        }
    }

    useEffect(() => {
        checkAuth();
      }, []);
    
    if(isAuth === undefined) {
      return (
        <>
        </>
      )
    } else if(isAuth){
      return children;
    } else {
      return (
        <Navigate to="/auth/login" />
      )
    }
};

Validateauth.propTypes = {
  children: PropTypes.node,
};

export default Validateauth;
