import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    borderRadius: 2,
    cursor: 'default',
    display: 'inline-flex',
    flexGrow: 0,
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    height: 20,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    padding: theme.spacing(0.5, 1),
    whiteSpace: 'nowrap',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  primary: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    color: theme.palette.primary.main,
  },
  secondary: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.08),
    color: theme.palette.secondary.main,
  },
  error: {
    backgroundColor: alpha(theme.palette.error.main, 0.08),
    color: theme.palette.error.main,
  },
  success: {
    backgroundColor: alpha(theme.palette.success.main, 0.08),
    color: theme.palette.success.main,
  },
  warning: {
    backgroundColor: alpha(theme.palette.warning.main, 0.08),
    color: theme.palette.warning.main,
  },
}));

function Label(props) {
  const classes = useStyles();
  const {
    children,
    className,
    color,
    style,
    uppercase,
    ...rest
  } = props;

  return (
    <span
      children={children}
      className={clsx(
        classes.root,
        (uppercase ? classes.uppercase : undefined),
        {
          [classes[color]]: color,
        },
        className,
      )}
      {...rest}
      style={style}
    />
  );
}

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'warning',
    'success',
  ]),
  style: PropTypes.object,
  uppercase: PropTypes.bool,
};

Label.defaultProps = {
  className: '',
  color: 'secondary',
  uppercase: true,
};

export default Label;
