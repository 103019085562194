import { makeStyles } from '@material-ui/core';

const colorPrincipal = '#361FF5 !important';

export default makeStyles((theme) => ({
  ContHistoFac: {
    height: '90vh',
    marginBottom: '300px',
  },
  cont_databox: {
      background: '#F8F8F8',
      borderRadius: '10px',
  },
  cont_img: {
      textAlign: 'left',
      verticalAlign: 'middle !important',
  },
  amount_pos: {
      color: ({color})=> colorPrincipal,
      fontFamily: 'Poppins, sans-serif !important',
      fontSize: '34px !important',
      fontStyle: 'normal',
      fontWeight: '700 !important',
      textAlign: 'left',
      '& div': {
        color: '#FFF',
        fontStyle: 'bold',
      },
  },
  cont_header_amount_title: {
    '& span': {
      color: '#4F4F4F !important',
      fontFamily: 'Inter !important',
      fontSize: '20px !important',
      fontStyle: 'normal',
      fontWeight: 'bold !important',
      lineHeight: '150%',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px !important',
      },
      '& b': {
        color: '#000000 !important',
        fontSize: '18px !important',
      }
    },
    padding: '5px 0px',
  },
  amount_pos_card: {
    padding: '0 !important',
  },
}));