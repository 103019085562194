import AwsS3 from 'aws-sdk/clients/s3';

import {
    AWS_BUCKET_CORE as BUCKET_CORE,
    //AWS_CREDENTIALS as CREDENTIALS,
    AWS_CREDENTIALS_CORE as CREDENTIALS_CORE,
    AWS_REGION as REGION,
} from '../../settings/aws/index.js';

const clientcore = new AwsS3({
  apiVersion: '2006-03-01',
  region: REGION,
  credentials: CREDENTIALS_CORE,
});

/**
 * Get signed URL
 *
 * @param {string} path
 */

 export const getSignedUrl = async (path, Expires = 10, repository = BUCKET_CORE) => {
  path = path.replace(`https://${repository}.s3.${REGION}.amazonaws.com/`, '');

  const params = {
    Bucket: repository,
    Key: path,
    Expires,
  };

  return clientcore.getSignedUrl('getObject', params);
};

/**
* Upload file to AWS S3
*
* @param {string} path
* @param {(Buffer|Uint8Array|Blob|string|Readable)} file
*/

export const upload = async (path, file) => {
    const params = {
      Body: file,
      Bucket: BUCKET_CORE,
      Key: path,
      ContentType: file.type,
    };
    const options = {
      partSize: 50 * 1024 * 1024,
      queueSize: 1,
    };
  
    return clientcore.upload(params, options).promise();
};

/**
* Download file from AWS S3
*
* @param {string} path
*/
export const download = async (path) => {
  try {
    const url = await getSignedUrl(path);
    window.open(url);
  } catch (error) {
    return false;
  }

  return true;
};