import React from 'react';
import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import useStyles from './styles.js';
import Modal from '@material-ui/core/Modal';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import DashHeader from '../../components/header/dash_header.jsx';
import Page from '../../components/page/Page.jsx';
import ButtonBlue from '../../components/buttons/button_blue.js';
import DataTable from '../../components/DataTable';
import FacturaButton from '../../components/facturaButton/facturaButton.js';
//import AccordionFact from '../../components/AccordionFact/AcocordionFact.jsx';

import Clock from '../../img/Reloj.png';

import { getUserInfo } from '../../functions/index.js';
//import getPeople from '../../services/dynamicore/processes/getPeople.js';
import Dynamicore, { SERVICES } from '../../services/dynamicore';
import { API_CONTEXT, API_URL } from '../../settings/index.js';
import { getCurrentSessionData } from '../../utils/aws/cognito';
import { getSignedUrl } from '../../utils/aws/s3';
import { numberFormat } from '../../utils/formater.js';
//import { Grid, 
//  TextField } from '@material-ui/core';

//import { Viewer, Worker } from '@react-pdf-viewer/core';
//import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

function getModalStyle() {

  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const headers = [
  'ID',
  'Nombre',
  'RFC',
  'Monto de factura',
  'Fecha de emisión',
  'Estatus',
];

function FacturajeView({ title }) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const classes = useStyles();
  const navigate = useNavigate();
  const [modalStyle] = useState(getModalStyle);
  //const newplugin = defaultLayoutPlugin();

  const [open, setOpen] = useState(false);
  const [modalview, setModalview] = useState(false);
  const [dataSat, setDataSat] = useState([]);
  const [myurl, setMyurl] = useState('');
  //const [selectedFile, setSelectedFile] = useState({
  //  name: '',
  //  monto: 0,
  //  fecha: '',
  //});
  const [isSelect, setIsSelect] = useState(false);
  const [error, setError] = useState(false);
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [statements, setStatements] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [clientId, setClientId] = useState();
  const [keysSat, setKeysSat] = useState({
    ciec: '',
    rfc: '',
  });

  const getData = async function () {
    try {

      const today = new Date().toISOString().slice(0, 10);
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      const oneMonthAgo = date.toISOString().slice(0, 10);
      
      const userInfo = await getUserInfo();
      //const accountId = userInfo.account;
      const [clientId] = userInfo.objects.clients;
      //const people = await getPeople(clientId);
      //console.log('data CLient =>', people);

      const {values} = await Dynamicore(SERVICES.LIBERFIN_GET_BILLS).create({
        customerId: clientId,
        start_date: oneMonthAgo,
        end_date: today,
      });

      //get axios sat
      //const {values} = await Dynamicore(`${API_URL}${SERVICES.SAT_DATAFILTER}`).create({
      //  page: 1,
      //  limit: 999,
      //  query: {
      //    filters: [
      //    {
      //      "name": "client",
      //      "filter": [
      //        clientId
      //      ],
      //      "type": "in",
      //      "sort": "desc"
      //    },
      //    {
      //      "name": "fechaemision",
      //      "filter": [
      //        oneMonthAgo,
      //        today
      //      ],
      //      "type": "between",
      //      "sort": "desc"
      //    },
      //    {
      //      "name": "efectocomprobante",
      //      "filter": [
      //        "I"
      //      ],
      //      "type": "in",
      //      "sort": "desc"
      //    }
      //  ]},
      //});

      const dataEmpresa = JSON.parse(localStorage.getItem('dataEmpresa'));

      if (dataEmpresa === null || dataEmpresa === undefined) {
        const satValues = await Dynamicore(SERVICES.SAT_GET_KEYS)
          .read({
            client: clientId,
          });
        setKeysSat({
          ciec: satValues.values[0].pass,
          rfc: satValues.values[0].rfc,
        });
      } else {
        setKeysSat({
          ciec: dataEmpresa.ciec,
          rfc: dataEmpresa.rfc,
        });
      }
      //const response = await axios.get(`${API_URL}${SERVICES.SAT}?client=14529`,
      //const response = await axios.get(`${API_URL}${SERVICES.SAT}`,
      //const response = await axios.get(`${API_URL}${SERVICES.SAT}?client=${clientId}&efectocomprobante=I`,
      //  {
      //    headers: {
      //      Authorization: accessToken,
      //      context: API_CONTEXT,
      //    },
      //  },
      //);
      //console.log('response: ', response?.data.data);
      setDataSat(values.data);
      setTotal(values.data.length);
      setClientId(clientId);

      const rows2 = values.data.map((item) => ({
        id: item.uuid,
        values: [
          item.uuid.split("-")[0],
          item.nombrereceptor,
          item.rfcreceptor,
          numberFormat(item.monto, '$'),
          item.fechaemision,
          item.estatus === 1 ? 'Activa' : 'Cancelada',
        ]
      }));

      //const rows = response?.data.data.map((item) => ({
      //  id: item.uuid,
      //  values: [
      //    item.uuid.split("-")[0],
      //    item.nombrereceptor,
      //    item.rfcreceptor,
      //    numberFormat(item.monto, '$'),
      //    item.fechaemision,
      //    item.estatus === 1 ? 'Activa' : 'Cancelada',
      //  ]
      //}));
      setAllItems(rows2);
      setItems(rows2);

      //const id = clientId;
      //const company = response?.data?.data[0].company;
      //const uuid = response?.data?.data[0].uuid;
//
      //const link = ['company', company, 'people', id, 'cfdi', `${uuid}.pdf`,].join('/');
//
      //const s3url = await getSignedUrl(link);
//
      //setMyurl(s3url);

    } catch (error) {
      console.log('error: ', error);
    }
  };

  //FUncion para buscar en la tabla
  const handleSearch = (query) => {
    const newItems = allItems.reduce((pV, cV) => {
      if (
        cV.values.some((el) => {
          try {
            if (el.toLowerCase().includes(query.toLowerCase())) {
              return true;
            }
          } catch (err) {
            console.error(err);
          }
          return false;
        })
      ) {
        pV.push(cV);
      }
      return pV;
    }, []);
    console.log('newItems', newItems);
    if(newItems.length > 0) {
      setItems(newItems);
    } else {
      setItems(newItems);
    }
    //const newItems = [].reduce((pV, cV) => {
    //  if (cV.values.some((el) => {
    //    try {
    //      if (el.toLowerCase().includes(query.toLowerCase())) {
    //        return true;
    //      }
    //    } catch (err) {
    //      console.error(err);
    //    }
    //    return false;
    //    })
    //  ) {
    //    pV.push(cV);
    //  }
    //  return pV;
    //}, []);
    ////console.log('newItems', newItems);
    //setItems(newItems);
  };

  //Funcion para seleccionar la factura
  const handleFacture = async (data, key) => {
    //console.log('data a handleFacture', data);

    //const id = key.client;
    //const company = key.company;
    //const uuid = key.uuid;
    //const link = ['company', company, 'people', id, 'cfdi', `${uuid}.pdf`,].join('/');
    //const s3url = await getSignedUrl(link);
    //setMyurl(s3url);

    //setSelectedFile({
    //  name: key.nombreemisor,
    //  monto: key.monto,
    //  fecha: key.fechaemision,
    //})
    if (data.length > 0) {
      setStatements(data);
      setIsSelect(true);
      setError(false);
    }
  }

  //Función para enviar las facturas seleccionadas y cambiar la vista del modal
  const onValidate = async () => {
    try {
      await Dynamicore(SERVICES.CREDIT_LIBERFIN).create({
        installments: 1,
        customerId: clientId,
        bills: statements,
        ...keysSat,
      })
    } catch (error) {
      console.log('error: ', error);
    }
    
    //setModalview(true);
    //navigate('/validation_statements');
    navigate('/dashboard');
  }

  const handleOpen = () => {
    if (isSelect) {
      setOpen(true);
    } else {
      setError(true);
    }
  };

  const searchInObject = (obj) => {
    const objectSeted = {};
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "object") {
        const nestedObj = searchInObject(obj[key]);
        Object.keys(nestedObj).forEach((nestedKey) => {
          objectSeted[`${key}.${nestedKey}`] = nestedObj[nestedKey];
        });
      } else {
        objectSeted[key] = obj[key];
      }
    });
    return objectSeted;
  }

  const filterData = (items, searchTerm) => {
    if (!searchTerm || searchTerm === "") {
      return items;
    }
    
    return items.filter((item) =>
      Object.values(searchInObject(item)).reduce(
        (accumulator, currentValue) =>
          accumulator || currentValue.toLowerCase().includes(searchTerm.toLowerCase()),
        false
      )
    );
  };

  const filteredData = filterData(items, searchTerm);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (JSON.stringify(filteredData) !== JSON.stringify(items)) {
      setItems(filteredData);
    }
  }, [filteredData, items]);

  useEffect(() => {}, [myurl]);

  useEffect(() => {}, [error]);

  return(
    <>
    <DashHeader />
    <div className={classes.contPageFactoring} id={'contPageFactoring'}>
      <div className="container ps-lx-5 pe-xl-5 ps-lg-5 pe-lg-5 ps-md-1 pe-md-1 mb-5">
        <Page title={title}>
          <div className="row">
            <div className="col-12 text-center">
              <div className={classes.title}>Facturas emitidas</div>
              <p className={classes.subtitle}>Selecciona las facturas a la que quieres aplicar el factoraje.</p>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-12 col-md-12 col-sm-12">
            {dataSat?.length > 0 ? (
              <>
                {/*
                <Grid className={classes.searchInput}>
                  <TextField
                    label="Buscar"
                    placeholder="Buscar"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Grid>
                */}
                <DataTable
                  headers={headers}
                  selectable={true}
                  rows={items}
                  total={total}
                  getDataAction={getData}
                  handleSelectData={handleFacture}
                  searchAction={handleSearch}
                />
              </>
              ) : (
                <div></div>
              )
            }
            </div>
          </div>
          {/*
          <div className="row mt-2">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className={classes.contFacturas}>
                {dataSat?.map((key, index) => {
                  return (
                    <>
                      <AccordionFact
                        id={index} 
                        key={index}
                        onClick={() => handleFacture(index, key)}
                        name={dataSat}
                        value={key?.uuid}
                        nameIssue={key?.nombreemisor} 
                        dateIssue={key?.fechaemision}
                        rfc={key?.rfcemisor}
                        status={key?.estatus}
                        dateCancel={key?.fechacancelacion}
                        monto={key?.monto}
                      />
                    </>
                  )
                  }).slice(-10)
                }
              </div>
            </div>
          </div>
          */}
          {/* Comentar esta parte, ya no se usa*/}
          {error ? (
            <div className='row text-center mt-4'>
              <h6 className={classes.error}>Seleccione la factura a financiar</h6>
            </div>) 
            : (<></>)
          }
          <div className="row mt-3">
            <div className="col-12 text-center">
              {dataSat?.length > 0 ? (
                <ButtonBlue onClick={() => handleOpen() } 
                  styleClass={'btn btn-blue btn-lg text-white fs-header-9 btn-login width-button-validar'} 
                  spanClass={'span_icon'} 
                  iconColor={'icon-button-blue'} 
                  value={'Continuar'} />
              ) : (
                <div className='text-center'>
                  <CircularProgress
                    className={classes.circularProgress}
                    variant="indeterminate"
                    disableShrink
                    sx={{
                      color: '#361ff5',
                      animationDuration: '850ms',
                      position: 'absolute',
                      marginTop: '25px',
                      left: 'calc(50% - 35px) ',
                      [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                      },
                    }}
                    thickness={4}	
                  />
                  <div className={classes.consult}>
                    <span className={classes.subtitle}>Estamos consultando tus facturas emitidas</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Page>
      </div>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <div className="row text-center">
          { !modalview ? (
            <>
              <div className="row">
                <div className='col-lg-12 col-md-12 col-xs-12 text-center'>
                  <div className={classes.title}>¿Estas seguro de que deseas <br />seleccionar esta factura?</div>
                </div>
              </div>
              <div className="row mt-5 ps-5">
                <div className="col-lg-12 col-md-12 col-xs-12 text-center ps-lg-5 ps-md-5 ps-xs-0">
                  
                    {statements.map((item, index) => {
                      return (
                        <div className={classes.factureContainer}>
                          <FacturaButton key={index} titulo={item.name} monto={item.amount} fecha_emision={item.emisionDate} select={false} />
                        </div>
                      )}
                    )}
                    {/*
                    <FacturaButton titulo={selectedFile.name} monto={selectedFile.monto} fecha_emision={selectedFile.fecha} select={false} />
                    */}
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-12 col-md-12 col-xs-12 text-center">
                  <ButtonBlue onClick={onValidate} styleClass={'btn btn-blue btn-lg text-white fs-header-9 btn-login width-btutton'} spanClass={'span_icon'} iconColor={'icon-button-blue'} value={'Aceptar'} />
                </div>
              </div>
            </>
            ) : (
            <>
            <div className="row align-items-center text-center">
              <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className="text-center align-items-center ps-md-3 pe-md-3 ps-lg-5 pe-lg-5 w-75 mx-auto">
                      <h4 className="rechazado">¡Nos falta <span className='color-blue'>poco!</span></h4>
                      <p className="sub_titulo text-center">
                          Tu proceso de solicitud de factoraje está en revisión. Validaremos tu información para ofrecerte una mejor tasa.
                      </p>
                      <p className="sub_titulo text-center">
                          Una vez validado, te haremos llegar el link para firma de contrato y pagaré al correo que nos proporcionaste.
                      </p>
                  </div>
              </div>
            </div>
            <div className="row align-items-center text-center">
                <div className='col-sm-12 col-md-12 col-lg-12 '>
                    <img src={Clock} width="250" height="auto" alt="Clock" className='mt-1'/>
                </div>
            </div>
            </>
            ) 
          }
          </div>
        </div>
      </Modal>
    </div>
    </>
  );
}

FacturajeView.defaultProps = {
  title: 'Facturaje',
};

FacturajeView.propTypes = {
  title: PropTypes.string,
};

export default FacturajeView;