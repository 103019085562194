export const {
    REACT_APP_AWS_ACCESS_KEY_ID: AWS_ACCESS_KEY_ID,
    REACT_APP_AWS_BUCKET_CORE: AWS_BUCKET_CORE,
    REACT_APP_AWS_SECRET_ACCESS_KEY_CORE: AWS_SECRET_ACCESS_KEY_CORE,
    REACT_APP_AWS_ACCESS_KEY_ID_CORE: AWS_ACCESS_KEY_ID_CORE,
    REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID: AWS_COGNITO_IDENTITY_POOL_ID,
    REACT_APP_AWS_REGION: AWS_REGION,
    REACT_APP_AWS_SECRET_ACCESS_KEY: AWS_SECRET_ACCESS_KEY,
    REACT_APP_AWS_USER_POOLS_ID: AWS_USER_POOLS_ID,
    REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID: AWS_USER_POOLS_WEB_CLIENT_ID,
  } = process.env;

export const AWS_AMPLIFY = {
    aws_project_region: AWS_REGION,
    aws_cognito_identity_pool_id: AWS_COGNITO_IDENTITY_POOL_ID,
    aws_cognito_region: AWS_REGION,
    aws_user_pools_id: AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: AWS_USER_POOLS_WEB_CLIENT_ID,
    oauth: {},
};

export const AWS_CREDENTIALS = {
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
};

export const AWS_CREDENTIALS_CORE = {
    accessKeyId: AWS_ACCESS_KEY_ID_CORE,
    secretAccessKey: AWS_SECRET_ACCESS_KEY_CORE,
};
