import styled from 'styled-components'
//import {GlobalColors, GlobalFonts} from '../../../styles/GlobalStyles';

export const ContAccordion = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const ContAccordionItem = styled.div`
  width: 100%;
  background: #F7F7F7;
  padding: 15px 0;
  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;

  //:active{
  //  background: #361FF5;
  //  padding-top: 0px;
  //}
`
export const ContAccordionItemInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${props => props.active ? '#361FF5' : '#F7F7F7'};
`
export const ContIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover{
    cursor: pointer;
  }
`
export const ContName = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 15px;
  align-items: flex-start;
  justify-content: center;
`
export const ContSubItem = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 15px;
`
export const DataName = styled.div`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: left;
  color: #090F37;
`
export const DataInfo = styled.div`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: justify;
  color: #4F4F4F
`
