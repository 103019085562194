import axios from 'axios';
import jwt_decode from 'jwt-decode';

import Dynamicore, { SERVICES } from '../index.js';
import {
  API_CONTEXT,
  API_URL,
} from '../../../settings/index.js';

async function getUserinfo(token) {
  let userInfo;
  if (token) {
    const { data: { data = {} } = {} } = await axios.get(
      `${API_URL}${SERVICES.USERS_GET_INFO}`,
      {
        headers: {
          Authorization: token,
          context: API_CONTEXT,
        },
      },
    );

    userInfo = data;
  } else {
    const { values } = await Dynamicore(SERVICES.USERS_GET_INFO).read();
    userInfo = values;
  }

  userInfo.objects.clients = [];
  (userInfo?.json_rol?.Statement || [])
    .forEach(({ Action, Resource }) => {
      if (Action.includes('client:select')) {
        if (token) {
          userInfo.token = jwt_decode(token);
        }

        userInfo
          .objects
          .clients
          .push(Resource.replace(`dcore:${userInfo.company}:client:`, ''));
      }
      if (Action.includes('account:select')) {
        userInfo.account = Resource.replace(`dcore:${userInfo.company}:account:`, '');
      }
    });

  return userInfo;
};

export default getUserinfo;
