import * as yup from 'yup';

export const cuentanosSchema = yup.object().shape({
    person: yup.string().required('Seleccione una opcion'),
    name: yup.string().required('El nombre es requerido'),
    rfc: yup.string(10).max(13).required('Escriba un RFC valido entre 10 y 13 caracteres'),
    ciec: yup.string().min(4).required('La contraseña ciec es requerida'),
    confirmciec:  yup.string().oneOf([yup.ref('ciec'), null], 'Las contraseñas no coinciden'),
    terms: yup.boolean().oneOf([true]).required("Debe aceptar los terminos y condiciones"),
    buro: yup.boolean().oneOf([true], 'Debe aceptar')
});

export const personFisicaSchema = yup.object().shape({
    person: yup.string().required('Campo requerido'),
    lastname: yup.string().required('Campo requerido'),
    motherlastname: yup.string().required('Campo requerido'),
    rfc: yup.string(10).max(13).required('Escriba un RFC valido entre 10 y 13 caracteres'),
    terms: yup.boolean().oneOf([true]).required("Debe aceptar los terminos y condiciones"),
    buro: yup.boolean().oneOf([true], 'Debe aceptar')
});