import React, {useState, useEffect}from 'react';
import { Auth } from 'aws-amplify';
import { NavLink } from 'react-router-dom';
import { Link, animateScroll as scroll } from "react-scroll";
import { useNavigate } from 'react-router-dom';
import { ListItemIcon, MenuItem } from '@material-ui/core';
import { getUserInfo } from '../../functions/index.js';

export default function DashHeader(){

  const navigate = useNavigate();

  const [isAuth, setIsAuth] = useState(false);

  const fnGoToElement = (el) => {
    if(el === 'https://web.callmatik.com/panel/es/login'){
      window.location.href = el;
    }
    if(el === '/registrate'){
      window.location.href = el;
    }
    if(el === '/'){
      navigate('/');
    }
    if(el === '/about'){
      navigate('/about');
    }
    if(el === '/liquidez'){
      navigate('/');
      setTimeout(function(){
        var getMeTo = document.getElementById("liquidez");
        getMeTo.scrollIntoView({behavior: 'smooth', block: 'start'});
      }, 1000);
      setTimeout(() => {
        window.scrollBy(0, -80);
      }, 2000);
    }
    setTimeout(function(){
      scroll(`#${el}`);
    }, 2000);
  }

  const checkAuth = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAuth(true);
    } catch {
      setIsAuth(false);
    }
  }

  //const getData = async () => {
  //  if(!isAuth){
  //    try {
  //    const user = await getUserInfo();
  //    console.log('user =>', user);
  //    } catch (error) {
  //      console.log('error =>', error);
  //    }
  //  } else {
  //   return null;
  //  }    
  //}

  useEffect(() => {
    checkAuth();
    //getData();
  }, []);

  return (
    <>
      <ul className="nav nav-pills pt-0 text-end float-end ms-lg-4 ms-md-5 ms-xl-4">
        <li className='nav-item pt-2 mt-1 d-none d-lg-block'>
            <NavLink to="/dashboard" 
                className="btn pe-4 fs-header-8 border-0 box-shadow-0 shadow-none bg-white header-txt"
                //style={({ isActive }) => ({
                //    color: isActive ? '#361FF5' : '#090F37'
                //    })}
            >
            <span className='d-none d-xl-block'>Panel</span>
            <span className='d-block d-xl-none'>Panel</span>
            </NavLink>
        </li>
        <li className='nav-item pt-2 mt-1 d-none d-lg-block'>
            <NavLink to="/profile" 
                className="btn pe-4 fs-header-8 border-0 box-shadow-0 shadow-none bg-white header-txt"
                //style={({ isActive }) => ({
                //    color: isActive ? '#361FF5' : '#090F37'
                //    })}
            >
            <span className='d-md-block d-lg-none d-xl-none'>Perfil</span>
            <span className='d-md-none d-lg-block d-xl-block'>Perfil</span>
            </NavLink>
        </li>
        <li className='nav-item d-none d-lg-block'>
           {!isAuth ? (
              <NavLink to="/auth/login" className="btn btn-blue btn-lg text-white fs-header-9 btn-login " >
              Ingreso a clientes
                  <svg className="ms-2" width="11" height="16" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.688628 14.82L6.50863 8.99997L0.688628 3.17997C0.103628 2.59497 0.103628 1.64997 0.688628 1.06497C1.27363 0.479971 2.21863 0.479971 2.80363 1.06497L9.68863 7.94997C10.2736 8.53497 10.2736 9.47997 9.68863 10.065L2.80363 16.95C2.21863 17.535 1.27363 17.535 0.688628 16.95C0.118628 16.365 0.103628 15.405 0.688628 14.82Z" fill="#F7F7F7"/>
                  </svg>
              </NavLink>
              ) : (
                <NavLink to="/auth/login" className="btn btn-blue btn-lg text-white fs-header-9 btn-login " >
                  Cerrar sesión
                  <svg className="ms-2" width="11" height="16" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.688628 14.82L6.50863 8.99997L0.688628 3.17997C0.103628 2.59497 0.103628 1.64997 0.688628 1.06497C1.27363 0.479971 2.21863 0.479971 2.80363 1.06497L9.68863 7.94997C10.2736 8.53497 10.2736 9.47997 9.68863 10.065L2.80363 16.95C2.21863 17.535 1.27363 17.535 0.688628 16.95C0.118628 16.365 0.103628 15.405 0.688628 14.82Z" fill="#F7F7F7"/>
                  </svg>
                </NavLink>
              )
            }
        </li>
        <li className='nav-item d-block d-lg-none text-end margin-button-menu pt-2 justify-content-between'>
            <button className="collapsed border-0 bg-white me-5 text-end" type="button"  data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                <svg width="28" height="19" viewBox="0 0 38 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="38" height="4.36364" rx="2.18182" fill="#361FF5"/>
                    <rect x="10" y="10" width="28" height="4" rx="2" fill="#361FF5"/>
                    <rect y="20" width="32" height="4" rx="2" fill="#361FF5"/>
                </svg>
            </button>
        </li>                     
      </ul> 
    </>
  )
}