import React from 'react';
import '../../css/firma/firma.css';
import { NavLink } from 'react-router-dom';
import ButtonBlue from '../../components/buttons/button_blue.js';
import Mifiel from 'mifiel-react';
import Header from '../../components/header/header.jsx';

const Firma = () => {
    return ( 
    <>
    <Header />
    <section id = "firma" >
        <div className = 'text-center'>
            <h4 className = "preptitle mb-3" > Firma de <span className = 'color-blue' > documentos </span> </h4> 
            <p className = "preap-subtitle" > Ayúdanos a firmar los contratos para poder finalizar la operación. </p> 
        </div> 

        <Mifiel widgetId="57b7b34c-1991-4bb6-9100-ee870e92d926" />
       
        <div className="row mb-5 pb-0">
            <div className="col-12 center">
                <NavLink to="/revition">
                    <ButtonBlue styleClass={'btn btn-blue btn-lg text-white fs-header-9 btn-login width-button-validar'} spanClass={'span_icon'} iconColor={'icon-button-blue'} value={'Continuar'} />
                </NavLink>
            </div>
        </div>

    </section>
    </>
    )
}

export default Firma;