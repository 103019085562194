import React from 'react';
import { Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';

import star from '../../img/star.svg';


const MUICard = (props) => {
    const {image, name, job, description, alt, descriptionSmall} = props;
    return(
        <Card className="mx-historias card-border">
            <div className="profile">
                <img src={image} alt={alt} width={80} height={80} />
            </div>
            <br/>
            <div className="star-content">
                <i className='mx-2'><img src={star} alt="star_icon" /></i>
                <i className='mx-2'><img src={star} alt="star_icon" /></i>
                <i className='mx-2'><img src={star} alt="star_icon" /></i>
                <i className='mx-2'><img src={star} alt="star_icon" /></i>
                <i className='mx-2'><img src={star} alt="star_icon" /></i>
            </div>
            <CardContent >
                <div className="card-container">
                    <div className="descr-height">
                    {descriptionSmall  ? 
                        (<p className="card-description-small">{description}</p>) 
                            : 
                        (<p className="card-description">{description}</p>)
                    }
                    </div>
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6.5" cy="6.5" r="6.5" fill="#361FF5"/>
                    </svg>

                    <h3 className='card-person-name'>{name}</h3>

                    <p className='card-person-job'>{job}</p>
                </div>
            </CardContent>
        </Card>
        
    )
}

MUICard.defaultProps = {
    image: '',
    name: '',
    job: '',
    description: '',
    alt: '',
    descriptionSmall: false,
};

MUICard.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    job: PropTypes.string,
    description: PropTypes.string,
    alt: PropTypes.string,
    descriptionSmall: PropTypes.bool,
};

export default MUICard;