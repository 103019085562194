import * as Yup from 'yup';

export const addClabeSchema = Yup
  .object()
  .shape({
    nif: Yup
      .string()
      .trim()
      .uppercase()
      .min(12, 'El NIF debe tener al menos 12 caracteres')
      .max(13, 'El NIF no debe exceder los 13 caracteres')
      .required('Este campo es requerido'),
    name: Yup
      .string()
      .required('Este campo es requerido'),
    email: Yup
      .string()
      .email('El correo electrónico no es válido')
      .trim()
      .lowercase()
      .required('Este campo es requerido'),
    phone: Yup
      .string()
      .matches(/^[0-9]+$/, 'El teléfono solo debe contener dígitos numéricos')
      .length(10, 'El número de teléfono debe tener exactamente 10 dígitos')
      .required('Este campo es requerido'),
    account: Yup
      .string()
      .required('Este campo es requerido'),
  });