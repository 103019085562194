import React from 'react';
import { useState, useEffect } from 'react';
import '../../../css/financiamiento.css';
import { Slider } from '@mui/material';
import LiquidezCard from '../../../components/cards/liquidez_card';
import { useNavigate } from 'react-router-dom';
import { formatNumber, numberPrefix, getMonto, validateAnualValue, 
    validateHealthValue, validateCapacidadValue, getDays, getNewTasa} from './functions.js';
import { useDispatch } from 'react-redux';
import { setFinanciamiento } from '../../../redux/reducers/dataFinanciamiento/dataFinanciamiento.js';

const Financiamiento = () =>{

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getText = (value) => `${'$'+value}`;
    const getTextPercent = (value) => `${value+'%'}`;
    const getTextPlazo = (value) => `${value}`;
    const getTextAnual = (value) => `${value}`;

    const [success, setSuccess] = useState(false);

    //const {factura, porcentaje, plazo, anual, salud} = useSelector(state => state.financiamiento);

    const [input, setInput] = useState({
        factura: '',
        porcentage: '',
        plazo: 0,
        anual: '',
        salud: ''
    });

    const [error, setError] = useState({
        factura: '',
        porcentage: '',
        plazo: '',
        anual: '',
        salud: ''
    })

    const onInputChange = e => {
        const { name, value } = e.target;
        setInput(prev => (
                {
                    ...prev,
                    [name]: value
                }
            )
        );
        validateInput(e);
        setSuccess(false);
    }

    const validateInput = e => {
        let { name, value } = e.target;
        setError(prev => {
          const stateObj = { ...prev, [name]: "" };

          switch (name) {

            case "factura":
                if (!value) {
                    stateObj[name] = "Este campo es requerido";
                } else if (input.factura === "") {
                    stateObj[name] = "Seleccione un valor";
                }
            break;

            case "porcentage":
                if (!value) {
                    stateObj[name] = "Este campo es requerido";
                } else if (input.porcentage === "") {
                    stateObj[name] = "Seleccione un valor";
                }
            break;

            case "plazo":
                if (!value) {
                    stateObj[name] = "Este campo es requerido";
                } else if (input.plazo === "") {
                    stateObj[name] = "Seleccione un plazo";
                }
            break;

            case "anual":
                if (!value) {
                    stateObj[name] = "Este campo es requerido";
                } else if (input.anual === "") {
                    stateObj[name] = "Seleccione un valor anual";
                }
            break;

            case "salud":
                if (!value) {
                    stateObj[name] = "Este campo es requerido";
                } else if (input.salud === "") {
                    stateObj[name] = "Seleccione una opción";
                }
            break;

            default:
              break;
          }

          return stateObj;
        });
    }

    const monto = getMonto(input.porcentage, input.factura); // $1,400,000 * 0.8 = $1,120,000
    
    const validateResult = monto ? numberPrefix(monto) : '0.00';

    const fac_anual = validateAnualValue(input.anual);

    const valorSalud = validateHealthValue(input.salud);

    const capacidadPago = validateCapacidadValue(valorSalud, fac_anual, input.factura);

    const days = getDays(input.plazo);
    const saludFinanciera = 1/valorSalud;

    //Declaramos los 3 valores de 'Peso', son constantes que se calcularon en base a una formula
    //Estos valores se quedan igual siempre
    const capPagoPeso = 0.692;
    const diasPeso = 0.077;
    const saludPeso = 0.231;

    //Declaramos los 3 valores de los 'producto'
    const capProducto = (capacidadPago * capPagoPeso).toFixed(2);

    const capProdNumber = Number(capProducto);

    const diasProducto = (days * diasPeso).toFixed(2);

    const diasProdNumber = Number(diasProducto);

    const saludProducto = (saludFinanciera * saludPeso).toFixed(2);

    const saludProdNumber = Number(saludProducto);

    //Con estos valores calculamos la Calificación
    const sum = capProdNumber + diasProdNumber + saludProdNumber;

    const calificacion = Number(sum.toFixed(3));

    const newTasa = parseFloat(getNewTasa(calificacion))

    //const tasa = getTasa(capacidadPago);
    //pasar el monto y la tasa dentro del array de input
    //Este objeto se crea para meter el monto y la tasa al nuevo objeto de abajo
    const pasarMontoyTasa = {
        monto: monto,
        tasa: newTasa,
    }
    const dataFactura = Object.assign(input, pasarMontoyTasa);

    const validation = (e) => {
      e.preventDefault();
      if (newTasa !== undefined && newTasa !== 0) {
        e.preventDefault();
        dispatch(setFinanciamiento({
          anual: dataFactura.anual,
          factura: dataFactura.factura,
          monto: dataFactura.monto,
          porcentage: dataFactura.porcentage,
          plazo: dataFactura.plazo,
          salud: dataFactura.salud,
          tasa: dataFactura.tasa,
        }));
        navigate('/how_work', {state: {dataFactura}});

      } else if (newTasa === undefined || newTasa === 0) {
        setSuccess(true);
        return false;
      }
    };

    useEffect(() => {
      // Cargar el estado anterior del componente desde localStorage cuando se monta el componente
      const miComponenteState = localStorage.getItem('miComponenteState');
      if (miComponenteState) {
        setInput(JSON.parse(miComponenteState));
      }
    }, []);

    useEffect(() => {
      // Guardar el estado actual del componente en localStorage cuando se actualiza el estado
      localStorage.setItem('miComponenteState', JSON.stringify(input));
    }, [input]);

    return(
      <section id='section_f' title="section_f">
        <form onSubmit={validation}>
          <div className='container row ps-2 pe-1 ms-auto me-auto '>
            <div className='col-12 col-lg-6 offset-lg-1 ps-lg-5 pe-lg-5 mb-md-4'>
                <h4 className="finan-title mb-2">Calcula <span className='color-blue'>tu financiamiento</span></h4>
                <p className='sub_titulo fs-fiananciemiento-11 fw-normal'>Y conoce la tasa estimada mensual.</p>
                <div className='pe-4'>
                  <div className='row justify-content-between mb-0 margin-barras'>
                    <div className="col-7">
                      <label className="fs-6">Valor de la factura</label>
                    </div>
                    <div className="col-4 col-md-3 bg-gray px-2 pb-2 pt-1 rounded h-financiamiento">
                      <p className='rigth_price bg-gray fs-financiamiento-13 p-0 m-0 h-financiamiento' >{formatNumber(input.factura)}</p>
                      <p className='p-0 m-0 fs-financiamiento-12'>MXN</p>
                    </div>
                  </div>
                  <div className="barras mb-0">
                    <Slider
                      className='border-slider custom-slider'
                      min={0}
                      step={100000}
                      max={5000000}
                      sx={{color: '#361FF5'}}
                      defaultValue={1500000}
                      aria-label="Default"
                      getAriaValueText={getText}
                      valueLabelDisplay="auto"
                      name='factura'
                      value={input.factura}
                      onChange={onInputChange}
                    />
                  </div>
                  {error.factura && <span className='err red-text mt-1'>{error.factura}</span>}
                  <div className='row justify-content-between mb-0 margin-barras'>
                    <div className="col-8 col-md-8 margin-barrras">
                      <label className="fs-6">% a Financiar</label>
                      <p className="text-muted fs-7 p-0 m-0 mb-2">*Nota. Financiamos hasta el 90% de la factura</p>
                    </div>
                    <div className="col-4 col-md-3 bg-gray px-2 pb-2 pt-1 rounded">
                      <p className='rigth_price bg-gray fs-financiamiento-13 p-0 m-0 h-financiamiento'>{input.porcentage}% </p>
                      <p className='p-0 m-0 fs-financiamiento-12'>a financiar</p>
                    </div>
                  </div>
                  <div className="barras mb-0">
                    <Slider
                      min={10}
                      step={1}
                      max={90}
                      sx={{ color: '#361FF5'}}
                      defaultValue={50}
                      aria-label="Default"
                      className="custom-slider"
                      valueLabelDisplay="auto"
                      getAriaValueText={getTextPercent}
                      //value={percent}
                      //onChange={changePercent}
                      name='porcentage'
                      value={input.porcentage}
                      //onBlur={validateInput}
                      onChange={onInputChange}
                    />
                  </div>
                  {error.porcentage && <span className='err red-text mt-1'>{error.porcentage}</span>}
                  <div className='row justify-content-between mb-0 margin-barras'>
                    <div className="col-7">
                      <label className="label_title">Plazo a financiar</label>
                    </div>
                    <div className="col-4 col-md-3 bg-gray px-2 pb-2 pt-1  rounded">
                      <p className='rigth_price bg-gray fs-financiamiento-13 p-0 m-0 h-financiamiento'>{input.plazo}</p>
                      <p className='p-0 m-0 fs-financiamiento-12'>Dias</p>
                    </div>
                  </div>
                  <div className="barras mb-0">
                    <Slider
                      min={15}
                      step={1}
                      max={120}
                      sx={{ color: '#361FF5'}}
                      defaultValue={1}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                      className="custom-slider"
                      getAriaValueText={getTextPlazo}
                      //value={plazo}
                      //onChange={changePlazo}
                      name='plazo'
                      value={input.plazo}
                      //onBlur={validateInput}
                      onChange={onInputChange}
                    />
                  </div>
                  {error.plazo && <span className='err red-text mt-1'>{error.plazo}</span>}
                  <div className='row justify-content-between mb-0 margin-barras'>
                    <div className="col-7">
                      <label className="label_title">Facturación Anual Estimada</label>
                    </div>
                    <div className="col-4 col-md-3 bg-gray px-2 pb-2 pt-1 rounded">
                      <p className='rigth_price bg-gray fs-financiamiento-13 p-0 m-0 h-financiamiento'>{formatNumber(input.anual)} </p>
                      <p className='p-0 m-0 fs-financiamiento-12'>MXN</p>
                    </div>
                  </div>
                  <div className="barras mb-0">
                    <Slider
                      className="border-slider custom-slider"
                      min={0}
                      step={100000}
                      max={100000000}
                      sx={{ color: '#361FF5'}}
                      defaultValue={0}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                      getAriaValueText={getTextAnual}
                      name='anual'
                      value={input.anual}
                      onChange={onInputChange}
                    />
                  </div>
                  {error.anual && <span className='err red-text mt-1'>{error.anual}</span>}
                  <div className='labels seleccion mt-2'>
                    <label className="fs-financiamiento-14 mb-1 margin-barras">¿Cómo esta tu salud financiera?</label>
                    <br />
                    <select
                      name="salud"
                      value={input.salud}
                      //onBlur={validateInput}
                      onChange={onInputChange}
                      className="form-select">
                      <option>Seleccionar</option>
                      <option value='1'>Excelente</option>
                      <option value='2'>Buena</option>
                      <option value='3'>Puede Mejorar</option>
                    </select>
                  </div>
                </div>
            </div>
            <div className="col-12 col-lg-5 d-none d-md-block ps-5 mt-0 mt-md-4 mt-lg-0 pt-2">
              <LiquidezCard total={'$'+validateResult} tasa={newTasa} onSubmit={() => validation()} error={success} />
            </div>
            <div className="col-12 d-block d-md-none mt-3 text-center">
              <LiquidezCard total={'$'+validateResult} tasa={newTasa} onSubmit={() => validation()} error={success} />
            </div>
          </div>
        </form>
      </section>
    )
}

export default Financiamiento;
