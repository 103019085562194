import React from 'react';
import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles.js';
import DashHeader from '../../components/header/dash_header.jsx';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

function LoanResultView({ title }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const classes = useStyles();

  return(
    <>
    <DashHeader />
    <>
      <div className="row align-items-center text-center">
        <div className='col-sm-12 col-md-12 col-lg-12'>
          <div className="text-center align-items-center ps-md-3 pe-md-3 ps-lg-5 pe-lg-5 w-75 mx-auto">
              <h4 className="rechazado">¡Nos falta <span className='color-blue'>poco!</span></h4>
              <p className="sub_titulo text-center">
                  Tu proceso de solicitud de factoraje está en revisión. Validaremos tu información para ofrecerte una mejor tasa.
              </p>
              <p className="sub_titulo text-center">
                  Una vez validado, te haremos llegar el link para firma de contrato y pagaré al correo que nos proporcionaste.
              </p>
          </div>
          <div className='text-center align-items-center ps-md-3 pe-md-3 ps-lg-5 pe-lg-5 w-75 mx-auto'>
            <div className={classes.consult}>
            <CircularProgress
              className={classes.circularProgress}
              variant="indeterminate"
              disableShrink
              sx={{
                color: '#361ff5',
                animationDuration: '850ms',
                position: 'absolute',
                marginTop: '12px',
                left: 'calc(50% - 35px) ',
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: 'round',
                },
              }}
              thickness={4}	
            />
            </div>
            <div className={classes.consultText}>
              <span className={classes.subtitleOpacity}>En unos instantes se le notificará sobre el status de su solicitud.</span>
            </div>
          </div>
        </div>
      </div>
      </>
    </>
  );
};

LoanResultView.defaultProps = {
  title: 'Nos falta poco',
};

LoanResultView.propTypes = {
  title: PropTypes.string,
};

export default LoanResultView;

