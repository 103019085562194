/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

//import imagotipoHorizontal from 'assets/svg/imagotipo-horizontal.svg';
//import isotipo from 'assets/svg/isotipo.svg';
import Page from '../../components/Page.jsx';
import useWindowDimensions from '../../hooks/useWindowDimensions.js';
//import { APP_VERSION, BUILD_VERSION } from 'settings';
import authenticationStyles from '../../theme/styles/authentication';

const LayoutAuth = ({ children, title, logoDesk = true }) => {
  const classes = authenticationStyles();
  const { height, width } = useWindowDimensions();

  return (
    <div className={classes.root} id="root">
      <Page maxWidth="lg" title={title} className={classes.bg} id="bg-fff">
        <div className={classes.bgFullHeight}>

          {width > 1024 && height < 780
            ? (<div className={classes.back_footer} />)
            : null
          }
          {/*
          <div className={classes.container_icons}>
            <div className={classes.icon_1} />
            <div className={classes.icon_2} />
            <div className={classes.icon_3} />
            <div className={classes.icon_4} />
            <div className={classes.icon_5} />
            <div className={classes.icon_square_1} />
            <div className={classes.icon_square_2} />
            <div className={classes.icon_square_3} />
            <div className={classes.icon_square_4} />
            <div className={classes.icon_square_5} />
            <div className={classes.icon_square_6} />
            <div className={classes.icon_square_7} />
            <div className={classes.icon_square_8} />
          </div>
          */}
          {/*}
          <Box mb={8} display="flex" alignItems="center" className={classes.header_imagotipo}>
            {logoDesk && (<img src={imagotipoHorizontal} alt="DynamiCore" />)}
          </Box>
          <Box mb={8} display="flex" alignItems="center" className={classes.header_isotipo}>
            <img src={isotipo} alt="DynamiCore" />
          </Box>
          */}
          {children}

        </div>
      </Page>

    </div>
  );
};

LayoutAuth.propTypes = {
  children: PropTypes.node,
  logoDesk: PropTypes.bool,
  title: PropTypes.string,
};

export default LayoutAuth;
