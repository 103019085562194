import PropTypes from 'prop-types';
import {
  CardHeader,
  CardContent,
  Grid,
  TableCell,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import useStyles from './styles';
import { numberFormat } from '../../../../utils/formater.js';

function CellSkeleton({ value, children }) {
  const hasValue = value || value === 0;

  return (<TableCell>
    {hasValue
      ? <Typography variant="body2" color="textSecondary">
        {children || value}
      </Typography>
      : <Skeleton animation="wave" variant="text" width={250} />
    }
  </TableCell>);
};

function EspecificationsView({ properties }) {
  const classes = useStyles();

  console.log('properties=>', properties);
  return (
    <>
    <Grid container spacing={4} className={classes.container_amount} id='especifications_container'>
      <Grid item md={3} xs={12} id='grid-mensual-day' className={classes.cont_databox}>
        <CardHeader
          title={(<Typography
            children="Fecha de desembolso"
            variant="caption"
          />)}
          className={classes.cont_header_amount_title}
        />
        <CardContent
          className={classes.amount_pos_card}
          children={<Typography
          className={classes.amount_pos}
          children={properties?.disbursed_date}
          variant="h3"
          />}
        />
      </Grid>
      <Grid item md={3} xs={12} id='grid-desembolso' className={classes.cont_databox}>
        <CardHeader
          title={(<Typography
            children="Monto Desembolsado"
            variant="caption"
          />)}
          className={classes.cont_header_amount_title}
        />
        <CardContent
          className={classes.amount_pos_card}
          children={<Typography
            className={classes.amount_pos}
            children={numberFormat(properties?.principal_disbursed, '$')}
            variant="h3"
          />}
        />
      </Grid>
      <Grid item md={3} xs={12} id='grid-date' className={classes.cont_databox} >
        <CardHeader
          title={(<Typography
            children="Comisión por apertura"
            variant="caption"
          />)}
          className={classes.cont_header_amount_title}
        />
        <CardContent
          className={classes.amount_pos_card}
          children={<Typography
            className={classes.amount_pos}
            children={numberFormat(properties?.amount_commission_opening, '$')}
            variant="h3"
          />}
        />
      </Grid>
    </Grid>
    <Grid container spacing={4} className={classes.container_amount} id='second_container'>
      <Grid item md={3} xs={12} id='grid-comision' className={classes.cont_databox}>
        <CardHeader
          title={(<Typography
            children="Interes retenido"
            variant="caption"
          />)}
          className={classes.cont_header_amount_title}
        />
        <CardContent
          className={classes.amount_pos_card}
          children={<Typography
            className={classes.amount_pos}
            children={0}
            variant="h3"
          />}
        />
      </Grid>
      {/*
      <Grid item md={3} xs={12} id='grid-date' className={classes.cont_databox} >
        <CardHeader
          title={(<Typography
            children="IVA cobrado"
            variant="caption"
          />)}
          className={classes.cont_header_amount_title}
        />
        <CardContent
          className={classes.amount_pos_card}
          children={<Typography
            className={classes.amount_pos}
            children='$15,000.00'
          //  children={state.nextAmountPayment === 0 ? '-' : numberFormat(state.nextAmountPayment, '$')}
            variant="h3"
          />}
        />
      </Grid>
      
      <Grid item md={3} xs={12} id='grid-status' className={classes.cont_databox}>
        <CardHeader
          title={(<Typography
            children="Comisión por apertura"
            variant="caption"
          />)}
          className={classes.cont_header_amount_title}
        />
        <CardContent
          className={classes.amount_pos_card}
          children={<Typography
            className={classes.amount_pos}
            children='$ 0.00'
            variant="h3"
          />}
        />
      </Grid>*/}
    </Grid>
    </>
  );
}

CellSkeleton.propTypes = {
  value: PropTypes.any,
  children: PropTypes.node,
};

EspecificationsView.propTypes = {
  account: PropTypes.object.isRequired,
};

export default EspecificationsView;
