import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../img/Logo_liberfin.png';
import { Link, animateScroll as scroll } from "react-scroll";

import DashHeader from './menudash.jsx';

const Headerb = () =>{
    return(
        <div className="header">
            <header>
                <nav class="navbar navbar-expand-md navbar-dark fixed-top justify-content-center bg-white p-0 m-0 " >
                    <div class="d-flex flex-wrap justify-content-between pt-3 m-navbar">
                        <NavLink to="/" className="d-flex align-items-between mb-3 mb-md-0 me-lg-1 me-xl-5 text-dark text-decoration-none">
                            <img src={Logo} alt="logo" className='mt-1 img-logo ms-lg-3' />
                        </NavLink>

                        <DashHeader />  
                    </div>
                </nav>
            </header>
            
            {/*<div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header">
                    <img src={Logo} width="45%" height="auto" alt="logo" className='offcanvas-title' />
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body bg-side">
                    <ul className="nav nav-pills pt-2 ul-header">
                        <div class="nav-item w-100 text-center d-block">
                        <li className="nav-item w-100 text-center li-header">
                            <NavLink to='/' 
                                className="btn pe-4 mobile-menu-font border-0 box-shadow-0 shadow-none mb-3"
                                //style={({ isActive }) => ({
                                //    color: isActive ? '#FFFFFF' : '#FFFFFF'
                                //    })}
                            >
                                Inicio
                            </NavLink>
                            <hr className="hr-menu"></hr>
                        </li>
                        <li className='nav-item w-100 text-center'>
                            <NavLink to="/about" 
                                className="btn pe-4 mobile-menu-font border-0 box-shadow-0 shadow-none mb-3"
                                //style={({ isActive }) => ({
                                //    color: isActive ? '#FFFFFF' : '#FFFFFF'
                                //    })}
                            >
                                ¿Quiénes somos? 
                            </NavLink>
                            <hr className="hr-menu"></hr>
                        </li>
                        <li className='nav-item w-100 text-center'>
                            <NavLink to="/how_work" 
                                className="btn pe-4 mobile-menu-font border-0 box-shadow-0 shadow-none mb-3"
                                //style={({ isActive }) => ({
                                //    color: isActive ? '#FFFFFF' : '#FFFFFF'
                                //    })}
                            >
                                ¿Cómo funciona?
                            </NavLink>
                            <hr className="hr-menu"></hr>
                        </li>
                        <li className='nav-item w-100 '>
                            <NavLink to="/asks" 
                                className="btn pe-4 mobile-menu-font border-0 box-shadow-0 shadow-none mb-3"
                                //style={({ isActive }) => ({
                                //    color: isActive ? '#FFFFFF' : '#FFFFFF'
                                //    })}
                            >
                                Preguntas Frecuentes
                            </NavLink>
                            <hr className="hr-menu"></hr>
                        </li>
                        <li className='nav-item w-100 mb-3 '>
                            <NavLink to="/about" 
                                className="btn pe-4 mobile-menu-font border-0 box-shadow-0 shadow-none mb-3"
                                //style={({ isActive }) => ({
                                //    color: isActive ? '#FFFFFF' : '#FFFFFF',
                                //    textAlign: 'center'
                                //    })
                                //}
                            >
                                Servicios para Empresas
                            </NavLink>
                            <hr className="hr-menu"></hr>
                        </li>
                        </div>
                    </ul>
                    </div>
                </div>
            */}
        </div> 
    )
}

export default Headerb;