import { useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  Tab,
  Tabs,
} from '@material-ui/core';

import Page from '../../components/page/Page.jsx';
import { getUserInfo } from '../../functions/index.js';
import getPeople from '../../services/dynamicore/processes/getPeople.js';
import { numberFormat } from '../../utils/formater.js';
import useStyles from './style';
import DashHeader from '../../components/header/dash_header.jsx';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function ProfileView({title}) {

  const classes = useStyles();
  //const [currentTab, setCurrentTab] = useState();
  const [value, setValue] = useState(0);
  const [data, setData] = useState({
    correo:'',
    razon_social: '',
    rfc: '',
    codigo_postal: '',
    telefono: '',
    ciudad: '',
    colonia: '',
    municipio: '',
    calle: '',
    no_exterior: '',
    no_interior: '',
    valor_factura: '',
    plazo: '',
    tasa: '',
  });

  const getData = async function () {
    try {
      const userInfo = await getUserInfo();
      //console.log('userInfo: ', userInfo);
      //const accountId = userInfo.account;
      const [clientId] = userInfo.objects.clients;
      const people = await getPeople(clientId);
      //console.log('people: ', people);
      //const accounts = await Dynamicore(SERVICES.ACCOUNTS).get({id: accountId, client: clientId});

      //Asignar valores
      setData({
        correo: people.pii.email,
        razon_social: people.pii.nombre_razon_social,
        rfc: people.pii.rfc,
        codigo_postal: people.pii.zipcode,
        municipio: people.pii.municipio,
        ciudad: people.pii.city,
        calle: people.pii.street,
        no_exterior: people.pii.no_ext,
        no_interior: people.pii.no_interior,

        valor_factura: people.pii.valor_de_la_factura,
        plazo: people.pii.plazo_a_financiar,
        tasa: people.pii.tasa,
      });

    } catch (error) {
      console.log('error :->', error);
    }
  } 
  {/*const getData = async function () {
    const userInfo = await getUserInfo();
    const [clientId] = userInfo.objects.clients;
    const people = await Dynamicore(SERVICES.PEOPLE)
      .get({
        id: clientId,
      });

    if (!people?.status || people.status === 'Pending') {
      history.push('/loan/request');
    }

    const peopleType = await Dynamicore(SERVICES.PEOPLE_TYPES)
      .get({
        id: people?.client_type,
      });

    setCurrentTab([...peopleType?.tabs].shift()?.value);
    setState({
      people,
      peopleType,
      userInfo,
    });
  };
*/}


const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //console.log('entra a handle change');
  setValue(newValue);
  //console.log('valor de value', value);
};

  useEffect(() => {
    getData();
  }, []);

  //if (!state) {
  //  return null;
  //}

  return (
  <>
  <DashHeader />
  <div className={classes.contPage}>
    <div className="container ps-lx-4 pe-xl-4 ps-lg-4 pe-lg-4 ps-md-1 pe-md-1">
      <Page title={title} id={'Page'}>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
            <CardHeader
            action={
              <RouterLink to="/dashboard" className={classes.detalles}>
                Regresar
              </RouterLink>
            }
            title={(<Typography
              children="Perfil"
              variant="caption"
            />)}
            className={classes.container_title}
            />
          </Grid>
        </Grid>
              
        <Box mt={2}>
        <Tabs value={value} onChange={handleChange} 
          className={classes.tabs_colors}
          indicatorColor='primary'
          aria-label="basic tabs example" 
          scrollButtons="auto" 
          variant="scrollable"
          id={'Tabs-component'}>
          {/*}
          <Tabs
            onChange={(event, value) => setCurrentTab('1')}
            scrollButtons="auto"
            textColor="secondary"
            value={currentTab}
            variant="scrollable"
          >*/}
            <Tab label="General" {...a11yProps(0)} />
            <Tab label="Solicitud" {...a11yProps(1)} />
            <Tab label="Documentos" {...a11yProps(2)} />
          {/*}
            {(state?.peopleType?.tabs || [])
              .filter(tab => tab.value !== 'circulo_de_credito_1')
              .sort(dynamicSort('order'))
              .map(({ name, value }, index) => (
                <Tab key={index} label={name} value={value} />
              ))}
          */}
          </Tabs>
          <Divider />
          {/*
          {((state?.peopleType?.tabs || [])
            .find(item => item.value === currentTab)
            ?.groups || [])
            .map((item, index) => (
              <Box key={index} mt={2} className={classes.container_accordion}>
                <Card>
                  <CardHeader title={item.name} />
                  <CardContent>
                    <Grid container spacing={1}>
                      {(state?.peopleType?.template || [])
                        .filter(field => field.tab === currentTab && field.group === item.value)
                        .sort(dynamicSort('sort'))
                        .map((field) => {
                          let value = state?.people?.pii[field.fieldname];
                          if (field.options) {
                            value = convertToObject(field.options)[value]?.name;
                          }
                        
                          return (<Grid
                            children={field.displaytype === 'document'
                              ? (<DynamicForm
                                fields={{
                                  label: field.displayname,
                                  settings: {
                                    readOnly: true,
                                  },
                                  type: 'document',
                                  value: value,
                                }}
                              />)
                              : (<>
                                <Typography
                                  className={classes.field_name}
                                  children={`${field.displayname}: `}
                                  variant="subtitle2"
                                />
                                <Typography
                                  className={classes.field_value}
                                  children={value}
                                  variant="subtitle1"
                                />
                              </>)}
                            className={classes.container_field}
                            item
                            key={field.field}
                            md={field.displaytype === 'document' ? 6 : 6}
                            xs={12}
                          />);
                        })}
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            ))}
          */}
          <TabPanel value={value} index={0}>
            <Box mt={2} className={classes.container_accordion}>
              <Card>
                <CardHeader title={'Información personal'} className={classes.container_accordion_title} />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item md={3} xs={6} >
                      <Grid item className={classes.container_field} xs={12}>
                        <Typography
                          className={classes.field_name}
                          children={'Correo: '}
                          variant="subtitle2"
                        />
                        <Typography
                          className={classes.field_value}
                          children={data.correo}
                          variant="subtitle1"
                        />
                      </Grid>
                      <Grid item className={classes.container_field} xs={12}>
                        <Typography
                          className={classes.field_name}
                          children={'Razon social'}
                          variant="subtitle2"
                        />
                        <Typography
                          className={classes.field_value}
                          children={data.razon_social}
                          variant="subtitle1"
                        />
                      </Grid>
                      <Grid item className={classes.container_field} xs={12}>
                        <Typography
                          className={classes.field_name}
                          children={'RFC'}
                          variant="subtitle2"
                        />
                        <Typography
                          className={classes.field_value}
                          children={data.rfc}
                          variant="subtitle1"
                        />
                      </Grid>
                      <Grid item className={classes.container_field} xs={12}>
                        <Typography
                          className={classes.field_name}
                          children={'Codigo Postal'}
                          variant="subtitle2"
                        />
                        <Typography
                          className={classes.field_value}
                          children={data.codigo_postal}
                          variant="subtitle1"
                        />
                      </Grid>
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <Grid item className={classes.container_field} xs={12}>
                        <Typography
                          className={classes.field_name}
                          children={'Calle'}
                          variant="subtitle2"
                        />
                        <Typography
                          className={classes.field_value}
                          children={data.calle}
                          variant="subtitle1"
                        />
                      </Grid>
                      <Grid item className={classes.container_field} xs={12}>
                        <Typography
                          className={classes.field_name}
                          children={'No Exterior'}
                          variant="subtitle2"
                        />
                        <Typography
                          className={classes.field_value}
                          children={data.no_exterior}
                          variant="subtitle1"
                        />
                      </Grid>
                      <Grid item className={classes.container_field} xs={12}>
                        <Typography
                          className={classes.field_name}
                          children={'No interior'}
                          variant="subtitle2"
                        />
                        <Typography
                          className={classes.field_value}
                          children={data.no_interior}
                          variant="subtitle1"
                        />
                      </Grid>
                      <Grid item className={classes.container_field} xs={12}>
                        <Typography
                          className={classes.field_name}
                          children={'Numero de teléfono'}
                          variant="subtitle2"
                        />
                        <Typography
                          className={classes.field_value}
                          children={'+525563002121'}
                          variant="subtitle1"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>  
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box mt={2} className={classes.container_accordion}>
              <Card>
                <CardHeader title={'Información del crédito'} className={classes.container_accordion_title} />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item className={classes.container_field} xs={12}>
                      <Typography
                        className={classes.field_name}
                        children={'Valor de la factura: '}
                        variant="subtitle2"
                      />
                      <Typography
                        className={classes.field_value}
                        children={numberFormat(data.valor_factura, '$')}
                        variant="subtitle1"
                      />
                    </Grid>
                    <Grid item className={classes.container_field} xs={12}>
                      <Typography
                        className={classes.field_name}
                        children={'Plazo a financiar: '}
                        variant="subtitle2"
                      />
                      <Typography
                        className={classes.field_value}
                        children={data.plazo + ' dias'}
                        variant="subtitle1"
                      />
                    </Grid>
                    <Grid item className={classes.container_field} xs={12}>
                      <Typography
                        className={classes.field_name}
                        children={'Tasa: '}
                        variant="subtitle2"
                      />
                      <Typography
                        className={classes.field_value}
                        children={numberFormat((data.tasa/100), '%')}
                        variant="subtitle1"
                      />
                    </Grid>
                    <Grid item className={classes.container_field} xs={12}>
                      <Typography
                        className={classes.field_name}
                        children={'Nombre del producto: '}
                        variant="subtitle2"
                      />
                      <Typography
                        className={classes.field_value}
                        children={''}
                        variant="subtitle1"
                      />
                    </Grid>
                    <Grid item className={classes.container_field} xs={12}>
                      <Typography
                        className={classes.field_name}
                        children={'Descripción del producto'}
                        variant="subtitle2"
                      />
                      <Typography
                        className={classes.field_value}
                        children={''}
                        variant="subtitle1"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Box mt={2} className={classes.container_accordion}>
              <Card>
                <CardHeader title={'Documentos'} className={classes.container_accordion_title} />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item className={classes.container_field} xs={12}>
                    
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </TabPanel>
        </Box>
      </Page>
    </div>
  </div>
  </>
  );
}

ProfileView.defaultProps = {
  title: 'Perfil',
};

ProfileView.propTypes = {
  title: PropTypes.string,
};

export default ProfileView;
