import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import {
  Box,
  Card,
  CardContent,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { DynamicFormRegister } from '../../components';
import imgMan from '../../img/blueImgMan.png'
import LayoutAuth from '../../layouts/LayoutAuth';
import { EMAIL_REGEXP, PHONE_REGEXP } from '../../constants/index';
import authenticationStyles from '../../theme/styles/authentication.js';
import { signInSchema } from '../../validations/schema/forms';
import Header from '../../components/header/header.jsx';

function AuhtenticationSignInView({ title }) {
  const [error, setError] = useState();
  const [isProcessing, setIsProcessing] = useState();
  const [values, setValues] = useState({});
  const [requireNewPassword, setRequireNewPassword] = useState();
  const [requireVerification, setRequireVerification] = useState();

  const { t } = useTranslation();

  const classes = authenticationStyles();

  const handleSubmitData = async function (values) {
    setIsProcessing(true);

    const e = new Error();
    const attributes = {};
    const { password, newPassword, code } = values;
    let { username } = values;

    if (PHONE_REGEXP.test(username)) {
      username = `+52${username}`;
      attributes.phone_number = username;
    } else if (EMAIL_REGEXP.test(username)) {
      attributes.email = username;
    } else {
      e.code = 'UserTypeInvalid';
      throw e;
    }

    try {
      if (requireVerification) {
        await Auth.confirmSignUp(username, code);
      }

      const user = await Auth.signIn(username, password);

      if (requireNewPassword) {
        await Auth.completeNewPassword(
          user,
          newPassword,
          attributes,
        );
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setRequireNewPassword(true);

        e.code = 'NewPasswordRequired';
        throw e;
      }

      //console.log("HIstory: ", history.location);
      //const query = new URLSearchParams(history.location.search);
      //const redirect = query.get('redirect');

      //const { values = {} } = await Dynamicore(SERVICES.COMPANY).get();
      //const [company = {}] = values;
      //localStorage.setItem('company', JSON.stringify(company));

      window.location.replace('/#/dashboard');

    } catch ({ code, message = 'Error al iniciar sesión' }) {
      setError(code ? `auth.${code}` : message);

      if (code === 'UserNotConfirmedException') {
        setRequireVerification(true);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const handleFieldChange = function ({ name, value }) {
    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    console.log("useEffect de AuthenticationSignInView");
    localStorage.clear();
  }, []);

  return (
  <>
  <Header />
  <LayoutAuth title={title} className={classes.root} logoDesk={false} id={"LayoutAuth"}>
    <Box className={classes.contentBox} id={'ContentBox'}>
    <Card className={classes.card} id={"Card"}>
      <CardContent className={[classes.content, classes.content_1]}>
        <div className={classes.container_title}>
          <span className={classes.container_title_black}>Ingresa a <span className={classes.container_title_blue}>tu cuenta</span></span>
        </div>
        <img
          alt="DynamiCore"
          src={imgMan}
          className={classes.logoInLogin}
        />
      </CardContent>

      <CardContent className={classes.content} id={'content-card-login'}>
        <CardContent className={[classes.content, classes.content_2, classes.contentForm]}>

          <Box mt={3}>
            <DynamicFormRegister
              actions={[
                {
                  color: 'primary',
                  label: requireNewPassword ? 'Cambiar contraseña' : (requireVerification ? 'Verificar usuario' : 'Ingresar'),
                  processing: isProcessing,
                  type: 'submit',
                  typeButton: 'login',
                },
              ]}
              fields={[
                {
                  type: !requireNewPassword ? 'space' : 'hidden',
                  height: 30,
                },
                {
                  disabled: requireNewPassword || requireVerification,
                  display: {
                    breakpoints: {
                      md: 12,
                      sm: 12,
                      xs: 12,
                    },
                  },
                  events: {
                    onChange: handleFieldChange,
                  },
                  label: 'Teléfono o correo electrónico',
                  name: 'username',
                  type: 'text',
                },
                {
                  type: !requireNewPassword ? 'space' : 'hidden',
                  height: 15,
                },
                {
                  disabled: requireNewPassword || requireVerification,
                  display: {
                    breakpoints: {
                      md: 12,
                      sm: 12,
                      xs: 12,
                    },
                  },
                  events: {
                    onChange: handleFieldChange,
                  },
                  label: 'Contraseña',
                  name: 'password',
                  settings: {
                    canReveal: true,
                  },
                  type: 'password',
                },
                {
                  label: 'Código de verificación',
                  name: 'code',
                  settings: {
                    format: 'auth_code',
                  },
                  type: requireVerification
                    ? 'number'
                    : 'hidden',
                },
                {
                  label: 'Nueva contraseña',
                  name: 'newPassword',
                  type: requireNewPassword
                    ? 'password'
                    : 'hidden',
                  display: {
                    breakpoints: {
                      md: 12,
                      sm: 12,
                      xs: 12,
                    },
                  },
                },
                {
                  color: 'primary',
                  label: requireVerification
                    ? 'Reenviar código de verificación'
                    : '¿Olvidaste tu contraseña?',
                  type: 'link',
                  to: requireVerification
                    ? async () => {
                      let { username } = values;

                      if (PHONE_REGEXP.test(username)) {
                        username = `+52${username}`;
                      } else if (EMAIL_REGEXP.test(username)) {
                        username = username.toLowerCase().trim();
                      } else {
                        const e = new Error();
                        e.code = 'UserTypeInvalid';
                        throw e;
                      }

                      await Auth.resendSignUp(username);
                    }
                    : '/auth/recovery',
                },
                {
                  type: !requireNewPassword ? 'space' : 'hidden',
                },
              ]}
              handleSubmitData={handleSubmitData}
              size="medium"
              validationSchema={signInSchema}
              id="signInForm"
            />
          </Box>

          {error && (<Box mt={2}>
            <Alert
              children={<div children={t(error)} />}
              severity="error"
            />
          </Box>)}

          {/* <Box children={null} my={2} />
          <Typography variant="body2" color="textSecondary" className={classes.center}>
            ¿No tienes cuenta?{' '}
            <Link
              children="Regístrate"
              color="primary"
              component={RouterLink}
              to="/auth/register"
              variant="body2"
            />
          </Typography>
          */}

          <div style={{
            height: '20px',
          }} />

        </CardContent>
      </CardContent>

    </Card>
    </Box>
  </LayoutAuth>
  </>);
}

AuhtenticationSignInView.defaultProps = {
  title: '',
};

export default AuhtenticationSignInView;
