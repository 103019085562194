import PropTypes from 'prop-types';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import arrowRight from './images/arrow-right.svg';

const useStyles = makeStyles((theme) => ({
  btn: {
    // backgroundColor: '#0B6BDD',
    backgroundColor: '#361ff5',
    color: '#ffffff',
  },
}));

function ButtonControl(props) {
  const {
    label,
    color,
    processing,
    type,
  } = props;

  const classes = useStyles();

  return (
    <Button
      color={color}
      disabled={processing}
      fullWidth
      size="large"
      type={type}
      className={classes.btn}
      // style={{backgroundColor: '#0b6bdd', color: '#ffffff', }}
      variant="contained"
    >
      {processing && (<CircularProgress
        size={18}
        style={{ marginRight: 5 }}
      />)}
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ textAlign: 'left' }}>{label}</div>
        <div><img src={arrowRight} alt={label}/></div>
      </div>
    </Button>

  );
}

ButtonControl.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  processing: PropTypes.string,
  type: PropTypes.string,
};

export default ButtonControl;
