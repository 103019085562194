import { makeStyles } from '@material-ui/core';

const colorPrincipal = '#361FF5 !important';

export default makeStyles((theme) => ({
  contPage: {
    height: '100vh',
  },
  accordion_title: {
    color: '#00154B',
    fontFamily: 'Montserrat, Roboto, Helvetica, Arial, sans-serif !important',
    fontSize: '24px !important',
    fontStyle: 'normal',
    fontWeight: 'bold !important',
    lineHeight: '150%',
  },
  container_accordion: {
    background: '#FFFFFF',
    borderRadius: '16px',
    boxShadow: '0px 0px 8px rgba(5, 152, 255, 0.25)',
  },
  container_accordion_title: {
    '& .MuiTypography-h5': {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '24px !important',
    fontStyle: 'normal',
    fontWeight: '700 !important',
    color: ({color})=> colorPrincipal,
    },
  },
  container_accordion_inner: {
    border: 'none',
    boxShadow: 'none',
  },
  container_field: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'start',
    margin: '5px 0',
  },
  container_title: {
    '& span': {
      color: ({color})=> colorPrincipal,
      fontFamily: 'Poppins, sans-serif !important',
      fontSize: '36px !important',
      fontStyle: 'normal',
      fontWeight: 'bold !important',
      lineHeight: '39px',
    },
    padding: '5px 0px 15px',
  },
  detalles: {
    width: '100%',
    textDecoration: 'none !important',
    cursor: 'pointer',
    '& > *': {
      textDecoration: 'none',
      cursor: 'pointer',
      textAlign: 'rigth',
      justifySelf: 'end',
    }
  },
  field_name: {
    color: '#000000',
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '14px !important',
    fontStyle: 'normal',
    fontWeight: '400 !important',
    lineHeight: '150%',
    marginRight: '5px',
  },
  field_value: {
    color: '#000000',
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '14px !important',
    fontStyle: 'bold',
    fontWeight: 'bold !important',
    lineHeight: '150%',
  },
  tabs_colors: {
    color: ({color})=> colorPrincipal,
    '& .MuiTab-wrapper': {
      color: ({color})=> colorPrincipal,
      fontFamily: 'Poppins, sans-serif',
      fontSize: '20px !important',
      fontStyle: 'normal',
      fontWeight: '700 !important',
    },
    '& .PrivateTabIndicator-colorSecondary-17': {
      backgroundColor: ({color})=> colorPrincipal,
    },
    '& .PrivateTabIndicator-colorSecondary-19': {
      backgroundColor: ({color})=> colorPrincipal,
    },
    '& > span': {
      backgroundColor: ({color})=> colorPrincipal,
    }
  }
}));
