import React from 'react';
import { useEffect } from 'react';
import '../../css/validation.css';
import ListConfirm from '../../components/lists/list_confirm.js';
import ButtonBlue from '../../components/buttons/button_blue.js';
import { NavLink } from 'react-router-dom';
import BgImage from '../../img/bg_index.png';
import Header from '../../components/header/header.jsx';


function Confirm() {
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <>
        <Header />
        <div className="bg-index-section prearobado mb-0 pb-0 pb-md-5 h-100" style={{ 
                backgroundImage: `url("`+BgImage+`")` 
            }} >
            <div id="confirmation" className='row'>
                <div className='col-12 col-lg-11 mx-auto px-md-0 '>
                    <div className='text-center w-75 mx-auto'>
                        <h4 className="qtitulo px-3 px-md-0 mb-2 mb-md-0">Tu financiamiento
                        <span className='color-blue'> está preaprobado</span>
                        </h4>
                        <p className='confirm_subtitle text-center px-2 px-md-5 pt-3'>En menos de 24 horas te daremos respuesta y compartiremos el link para firma de contrato y 
                            pagaré. Deberás tener a la mano: Fiel de la empresa y/o representante legal y fiel del aval.
                        </p>
                    </div>
                    <div class="px-md-5">
                        <div class="row px-lg-5 mx-lg-5">
                            <ListConfirm label={'Acta Constitutiva'} size={'5.7MB'} />
                            <ListConfirm label={'Poder de Rep Legal para validadion'} size={'5.7MB'} />
                            <ListConfirm label={'Identificación Rep. Legal'} size={'5.7MB'} />
                            <ListConfirm label={'Comprobante de domiclio no mayor a 3 meses'} size={'5.7MB'}/>
                            <ListConfirm label={'Carátula de Edo. Cuenta bancario para CLABE y/o cuenta'} size={'5.7MB'} />
                            <ListConfirm label={'RFC del accionista mayoritario '} size={'5.7MB'} />
                            <ListConfirm label={'Identificación del  Aval '} size={'5.7MB'} />
                            <ListConfirm label={'Comprobante de domicilio del Aval (No mayor a 2 meses)'} size={'5.7MB'} />
                        </div>
                    </div>
                    <div className='qcenter'>
                        <NavLink to='/down_doc'>
                            <ButtonBlue styleClass={'btn btn-blue btn-lg text-white  fs-header-9 btn-login width-button-validar'} spanClass={'span_icon'} iconColor={'icon-button-blue'} value={'Firmar documentos'} />
                        </NavLink>
                    </div>
                </div>

            </div>
        </div>
        </>
    )
}

export default Confirm;