export const options = [
    {
      value: "AGS",
      name: "Aguascalientes"
    },
    {
      value: "BC",
      name: "Baja California"
    },
    {
      value: "BCS",
      name: "Baja California Sur"
    },
    {
      value: "CAMP",
      name: "Campeche"
    },
    {
      value: "COAH",
      name: "Coahuila"
    },
    {
      value: "COL",
      name: "Colima"
    },
    {
      value: "CHIS",
      name: "Chiapas"
    },
    {
      value: "CHIH",
      name: "Chihuahua"
    },
    {
      value: "CDMX",
      name: "Ciudad de México"
    },
    {
      value: "DGO",
      name: "Durango"
    },
    {
      value: "GTO",
      name: "Guanajuato"
    },
    {
      value: "GRO",
      name: "Guerrero"
    },
    {
      value: "HGO",
      name: "Hidalgo"
    },
    {
      value: "JAL",
      name: "Jalisco"
    },
    {
      value: "EM",
      name: "México"
    },
    {
      value: "MICH",
      name: "Michoacán"
    },
    {
      value: "MOR",
      name: "Morelos"
    },
    {
      value: "NAY",
      name: "Nayarit"
    },
    {
      value: "NL",
      name: "Nuevo León"
    },
    {
      value: "OAX",
      name: "Oaxaca"
    },
    {
      value: "PUE",
      name: "Puebla"
    },
    {
      value: "QRO",
      name: "Querétaro"
    },
    {
      value: "QROO",
      name: "Quintana Roo"
    },
    {
      value: "SLP",
      name: "San Luis Potosí"
    },
    {
      value: "SIN",
      name: "Sinaloa"
    },
    {
      value: "SON",
      name: "Sonora"
    },
    {
      value: "TAB",
      name: "Tabasco"
    },
    {
      value: "TAMP",
      name: "Tamaulipas"
    },
    {
      value: "TLAX",
      name: "Tlaxcala"
    },
    {
      value: "VER",
      name: "Veracruz"
    },
    {
      value: "YUC",
      name: "Yucatán"
    },
    {
      value: "ZAC",
      name: "Zacatecas"
    }
];