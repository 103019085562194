import React from 'react';
import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import '../../css/preaprobado/preaprobado.css';
import ButtonBlue from '../../components/buttons/button_blue.js';
import BgImage from '../../img/bg_index.png';
import DashHeader from '../../components/header/dash_header.jsx';
//import { personFisicaSchema } from '../../validations/CuentanosValidation.js';
import { getUserInfo } from '../../functions/index.js';
import getPeople from '../../services/dynamicore/processes/getPeople.js';
import Dynamicore from '../../services/dynamicore';
import { options } from './options.js';

const FisicPersonView = () =>{
  //const [datos, setDatos] = useState('');
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState({
    person: '',
    lastname: '',
    rfc_fisica: '',
    motherlastname: '',
    nationality: '',
    birthdate: '',
    colony_fisica: '',
    municipality: '',
    postal_code: '',
    street_fisica: '',
    estado_fisica: '',
    city_fisica: '',
    terminos: '',
  });
  const [error, setError] = useState({
    person: '',
    lastname: '',
    rfc_fisica: '',
    motherlastname: '',
    nationality: '',
    birthdate: '',
    colony_fisica: '',
    municipality: '',
    postal_code: '',
    street_fisica: '',
    estado_fisica: '',
    city_fisica: '',
    terminos: '',
  });

  const getData = async () => {
    
    try {
      const userInfo = await getUserInfo();
      //const accountId = userInfo.account;
      const [clientId] = userInfo.objects.clients;
      await getPeople(clientId);

    } catch (error){
      console.log(error)
    }
  };

  const submitData = async (event) => {
    event.preventDefault();
    //console.log('ENTRA ASUBMIT DATA');
    //console.log('event', event);
    const dataFisica = {
      name: event.target[0].value,
      lastname: event.target[1].value,
      motherlastname: event.target[2].value,
      birthdate: event.target[3].value,
      nationality: event.target[4].value,
      estado_fisica: event.target[5].value,
      colony_fisica: event.target[6].value,
      municipality: event.target[7].value,
      city_fisica: event.target[8].value,
      postal_code: event.target[9].value,
      street_fisica: event.target[10].value,
      rfc_fisica: event.target[11].value,
      terms: event.target[12].checked,
      buro: event.target[13].checked,
    };
    
    //const isValid = await personFisicaSchema.isValid(dataFisica);
    //console.log("es valido?",isValid);

    //if ( isValid === true ) {
      //Mandar el pud a pii.
    try {
      const userInfo = await getUserInfo();
      const dataempresa = JSON.parse(localStorage.getItem('dataEmpresa'));
      const dataFactura = JSON.parse(localStorage.getItem('miComponenteState'));
      //console.log('userInfo: ->', userInfo);
      //const accountId = userInfo.account;
      const [clientId] = userInfo.objects.clients;
      //console.log('clientId: ->', clientId);
      const people = await getPeople(clientId);
      //await Dynamicore(SERVICES.PEOPLE).update(
      //  {
      //    ...people,
      //    pii: {
      //      ...people.pii,
      //      ...dataFisica,
      //    },
      //  }
      //);
      await Dynamicore('/internal/connector/3047/flows/54a3c2359d6d43598762bdacfde5ee0f').create(
        {
          id: clientId,
          status: "Pending",
          external_id: "None",
          client_type: 139,
          pd: 100,
          pii: {
            ...dataFisica,
            "email": people.username,
            "_a_financiar": dataFactura.porcentage,
            "tasa": dataFactura.tasa,
            "salud_financiera": dataFactura.salud,
            "plazo_a_financiar": dataFactura.plazo,
            "valor_de_la_factura": dataFactura.factura,
            "facturacion_anual_estimada": dataFactura.anual,
            "persona": dataempresa.person,
            "nombre_razon_social": dataempresa.name,
            "street": dataempresa.adress,
            "no_interior": dataempresa.no_int,
            "no_ext": dataempresa.no_ext,
            "colony": dataempresa.suburb,
            "municipio": dataempresa.municipality,
            "city": dataempresa.city,
            "state": dataempresa.state,
            "country": dataempresa.country,
            "zipcode": dataempresa.zipcode,
            "rfc": dataempresa.rfc.toUpperCase(),
          },
          modules: {"satKeys": {
            "rfc": dataempresa.rfc.toUpperCase(), 
            "ciec": dataempresa.ciec
            }
          }, 
        }
      );
      //console.log('update', update);
      navigate('/factoring');
    } catch (error){
      console.log(error)
    }

      //setIsOpen(true);
      handleOpen();
    //}
  };

  const onInputChange = e => {
    const {name, value} = e.target;
    setInput(prev => ({
      ...prev,
      [name]: value,
    }));
  };
  const validateInput = e => {
    let {name, value} = e.target;
    setError(prev => {
      const stateObj = {...prev, [name]: ''};

      switch ( name ) {

        case 'person':
          if ( !value ) {
            stateObj[name] = 'Debe seleccionar una opción';
          } else if ( input.person === '' ) {
            stateObj[name] = 'Seleccione una opción';
          }
          break;

        case 'lastname':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.lastname === '' ) {
            stateObj[name] = 'Escriba un nombre valido';
          }
          break;
        
        case 'motherlastname':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.motherlastname === '' ) {
            stateObj[name] = 'Escriba una dirección valida';
          }
          break;
        
        case 'nationality':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.nationality === '' ) {
            stateObj[name] = 'Escriba un número valido';
          }
          break;
        
        case 'birthdate':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.birthdate === '' ) {
            stateObj[name] = 'Escriba un número valido';
          }
          break;

        case 'colony_fisica':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.colony_fisica === '' ) {
            stateObj[name] = 'Escriba una colonia valida';
          }
          break;
        
        case 'municipality': 
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.municipality === '' ) {
            stateObj[name] = 'Escriba una delegación o municipio valido';
          }
          break;
        
        case 'postal_code':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.postal_code === '') {
            stateObj[name] = 'Escriba una ciudad valida';
          }
          break;

        case 'street_fisica':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.street_fisica === '') {
            stateObj[name] = 'Escriba un estado valido';
          }
          break;
        
        case 'estado_fisica':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.estado_fisica === '') {
            stateObj[name] = 'Escriba un código valido';
          }
          break;

        case 'city_fisica':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.city_fisica === '') {
            stateObj[name] = 'Escriba una código valida';
          }
          break;
        
        case 'rfc_fisica':
          if ( !value ) {
            stateObj[name] = 'Este campo es requerido';
          } else if ( input.rfc_fisica === '' ) {
            stateObj[name] = 'Escriba un rfc valido';
          }
          break;

      
        case 'terminos':
          if ( !value ) {
            stateObj[name] = 'Debe aceptar los terminos y condiciones';
          } else if ( input.terminos === '' ) {
            stateObj[name] = 'Debe aceptar los terminos';
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
    getData();
    // Cargar el estado anterior del componente desde localStorage cuando se monta el componente
    const dataFisica = localStorage.getItem('dataFisica');
    if (dataFisica) {
      setInput(JSON.parse(dataFisica));
    }
  }, []);

  useEffect(() => {
    // Guardar el estado actual del componente en localStorage cuando se actualiza el estado
    localStorage.setItem('dataFisica', JSON.stringify(input));
  }, [input]);

  return(
    <>
    <DashHeader />
    <div className="bg-index-section prearobado mb-0 pb-0 pb-md-5 h-100" style={{ 
      backgroundImage: `url("`+BgImage+`")` 
    }} >
      <section id="cuentanos">
      <div className="row mt-lg-5 mt-md-3 mt-sm-1 ms-0 me-0">
        <div className="col-lg-8 col-md-10 col-sm-12 px-5 mx-auto">
          <h4 className="cuentanos-tile mt-4 mb-3 px-2 px-md-0">Representante <span className="color-blue">legal</span></h4>
          <p className="company_subtitle">La información y privacidad de la misma es importante para nosotros. Consulta
            nuestro &nbsp;
            <a href="https://terms-privacity.s3.us-west-2.amazonaws.com/Aviso+de+Privacidad+Liberfin+v2.pdf" target="_blank" rel="noopener noreferrer">
              Aviso de Privacidad &nbsp;
            </a>
            &nbsp; para conocer el compromiso de Liberfin con tus datos.
          </p>
          <form className="w-100" onSubmit={submitData}>
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label className="pb-1">Nombre(s)</label>
                  <br/>
                  <input
                    className="form-control input-text form-control-lg"
                    type="text"
                    name="person"
                    required
                    value={input.person}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  {error.person && <span className="err red-text mt-2">{error.person}</span>}
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label className="pb-1">Apellido Paterno</label>
                  <br/>
                  <input
                    className="form-control input-text form-control-lg"
                    type="text"
                    name="lastname"
                    required
                    value={input.lastname}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  {error.lastname && <span className="err red-text mt-2">{error.lastname}</span>}
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label className="pb-1">Apellido Materno</label>
                  <br/>
                  <input
                    className="form-control input-text form-control-lg"
                    type="text"
                    name="motherlastname"
                    required
                    value={input.motherlastname}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  {error.motherlastname && <span className="err red-text mt-2">{error.motherlastname}</span>}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              
              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <label className="pb-1">Fecha de nacimiento</label>
                  <br/>
                  <input
                    className="form-control input-number form-control-lg"
                    type="date"
                    name="birthdate"
                    required
                    value={input.birthdate}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  {error.birthdate && <span className="err red-text mt-2">{error.birthdate}</span>}
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <label className="pb-1">Nacionalidad</label>
                  <br/>
                  <input
                    className="form-control input-number form-control-lg"
                    type="text"
                    name="nationality"
                    required
                    value={input.nationality}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  {error.nationality && <span className="err red-text mt-2">{error.nationality}</span>}
                </div>
              </div>
            </div>
            <div className="row mt-4">
            <div className="col-lg-4 col-md-4 margin-cols">
                <div className="form-group">
                  <label className="pb-1">Estado / Provincia</label>
                  <br/>
                  <select
                    value={input.estado_fisica}
                    onBlur={validateInput}
                    name="estado_fisica"
                    onChange={onInputChange}
                    className="form-select"
                    required
                  >
                    <option value="">Selecciona un estado</option>
                    {options.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {/*
                  <input
                    className="form-control input-text form-control-lg"
                    name="estado_fisica"
                    type="text"
                    value={input.estado_fisica}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  */}
                  {error.estado_fisica && <span className="err red-text mt-2">{error.estado_fisica}</span>}
                </div>
              </div>
              <div className="col-lg-4 col-md-4 margin-cols">
                <div className="form-group">
                  <label className="pb-1">Colonia</label>
                  <br/>
                  <input
                    className="form-control input-text form-control-lg"
                    type="text"
                    name="colony_fisica"
                    required
                    value={input.colony_fisica}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  {error.colony_fisica && <span className="err red-text mt-2">{error.colony_fisica}</span>}
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label className="pb-1">Delegación o Municipio</label>
                  <br/>
                  <input
                    className="form-control input-text form-control-lg"
                    type="text"
                    name="municipality"
                    required
                    value={input.municipality}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  {error.municipality && <span className="err red-text mt-2">{error.municipality}</span>}
                </div>
              </div>
            </div>
            
            <div className="row mt-4">
            <div className="col-lg-4 col-md-4 margin-cols">
              <div className="form-group">
                <label className="pb-1">Ciudad</label>
                <br/>
                <input
                  className="form-control input-text form-control-lg"
                  type="text"
                  name="city_fisica"
                  required
                  value={input.city_fisica}
                  onBlur={validateInput}
                  onChange={onInputChange}
                />
                {/*<input
                  className="form-control input-text form-control-lg"
                  type="text"
                  name="city_fisica"
                  required
                  value={input.city_fisica}
                  onBlur={validateInput}
                  onChange={onInputChange}
                />*/}
                {error.city_fisica && <span className="err red-text mt-2">{error.city_fisica}</span>}
              </div>
            </div>
              <div className="col-lg-4 col-md-4 margin-cols">
                <div className="form-group">
                  <label className="pb-1">Código Postal</label>
                  <br/>
                  <input
                    className="form-control input-text form-control-lg"
                    name="postal_code"
                    type="tel"
                    maxlength={5}
                    value={input.postal_code}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  {error.postal_code && <span className="err red-text mt-2">{error.postal_code}</span>}
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label className="pb-1">Calle</label>
                  <br/>
                  <input
                    className="form-control input-text form-control-lg"
                    type="text"
                    name="street_fisica"
                    required
                    value={input.street_fisica}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  {error.street_fisica && <span className="err red-text mt-2">{error.street_fisica}</span>}
                </div>
              </div>
              
            </div>
            <div className="row">
              <div className="column-12 mt-4">
                <div className="form-group">
                  <label className="pb-1">RFC del representante legal</label>
                  <input
                    className="form-control input-text form-control-lg"
                    type="text"
                    name="rfc_fisica"
                    maxlength={13}
                    required
                    value={input.rfc_fisica}
                    onBlur={validateInput}
                    onChange={onInputChange}
                  />
                  {error.rfc_fisica && <span className="err red-text mt-2">{error.rfc_fisica}</span>}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 col-md-6 text-end mb-3">
                <div class="form-check text-end check-mobile padd-check float-md-end  ms-0 ms-md-5">
                  <input
                    class="form-check-input form-check-input-lg ms-0 check-border"
                    type="checkbox"
                    id="flexCheckCheckedTerminosYcondiciones"
                    required
                    value={input.terminos}
                    onChange={onInputChange}
                    name="terminos"/>
                  <label class="form-check-label  terminos-font ps-2" for="flexCheckCheckedTerminosYcondiciones">
                    Acepto Términos y Condiciones
                  </label>
                  {error.terminos && <span className="err red-text">{error.terminos}</span>}
                </div>
              </div>
              <div className="col-sm-6 col-md-6 ps-3 ps-md-3 mb-3">
                <div class="form-check text-start ms-2 mx-md-4">
                  <input
                    class="form-check-input form-check-input-lg check-border"
                    type="checkbox"
                    value="buro"
                    name="buro"
                    id="flexCheckCheckedBuroDeCredito"
                    required
                  />
                  <label class="form-check-label terminos-font " for="flexCheckCheckedBuroDeCredito">
                    Acepta consulta de buró de crédito
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="column-12 center">

                <ButtonBlue onSumbit={() => submitData()}
                            styleClass={'btn btn-blue btn-lg text-white fs-header-9 btn-login width-btutton'}
                            spanClass={'span_icon ps-2'} iconColor={'icon-button-blue'} value={'  Continuar'}/>

              </div>
            </div>
            <div className="row">
              <div className="column-12 center mt-4">
                <a className="cancel_button" href="/" alt="cancelar"><p className="cancelar">Cancelar</p></a>
              </div>
            </div>
          </form>
        </div>
      </div>

      </section>
    </div>
    </>
  )
}
export default FisicPersonView;