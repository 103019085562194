
import { useEffect, useState } from 'react';
//import { NavLink as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  //Card,
  //CardContent,
  CardHeader,
  Grid,
  Typography,
  //Table,
  //TableBody,
  //TableCell,
  //TableHead,
  //TableRow,
} from '@material-ui/core';
import Dynamicore, { SERVICES } from '../../../services/dynamicore/index.js';
import { getUserInfo } from '../../../functions/index.js';
import getPeople from '../../../services/dynamicore/processes/getPeople.js';
import useStyles from '../styles';
import Page from '../../../components/page/Page.jsx';
import DashHeader from '../../../components/header/dash_header.jsx';
import NoAccount from '../../../img/Panel/unauthorizat.png';

function NotActiveView({ title }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [peopleStatus, setpeopleStatus] = useState();

  const getData = async function () {

    try {
      const userInfo = await getUserInfo();
      //const accountId = userInfo.account;
      const [clientId] = userInfo.objects.clients;
      //console.log('clientId: ->', clientId);
      const people = await getPeople(clientId);

      //const razon = people?.pii?.nombre_razon_social;

      const { json_rol: { Statement = [] } } = userInfo;
      const resources = {};
      Statement.forEach(({ Resource = '', Effect = '' } = {}) => {
        const [, , type, id] = Resource.split(':');

        if (!resources[type]) {
          resources[type] = [];
        }

        if (Effect === 'Allow') {
          resources[type].push(parseInt(id));
        }
      });
      const [accountId = null] = resources.account || [];

      const accounts = await Dynamicore(SERVICES.ACCOUNTS)
      .read({
        id: accountId,
        client: clientId,
      });
      //console.log('accounts: ->', accounts);

      //console.log('clientStatus: ->', people.status);
      //console.log('accountId en Not active: ->', accountId);
      setpeopleStatus(people.status);
      if (people.status === 'Active') {
        //console.log('entra al if en Not active');
        navigate('/dashboard')
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getData();
  }, []);

  if(!peopleStatus) {
    return null;
  }

  return (
    <>
      <DashHeader />
      <div className={classes.contPage} id={'contPageDashboard'}>
        <div className="container ps-lx-5 pe-xl-5 ps-lg-4 pe-lg-4 ps-md-1 pe-md-1">
          <Page title={title} style={{
            backgroundColor: '#ffffff',
          }}>
            <Grid container spacing={4}>
             <Grid item md={12} xs={12} className='text-center'>
               <CardHeader
                 title={(<Typography
                   children="Tu crédito aun no ha sido aprobado, te notificaremos cuando este listo."
                   variant="caption"
                   className={classes.credit_unauthorized}
                 />)}
               />
               <img src={NoAccount} className={classes.imgCentral} alt="Person" />
             </Grid>
            </Grid>
          </Page>
        </div>
      </div>
    </>
  )
}
NotActiveView.defaultProps = {
  title: 'Panel',
};

NotActiveView.propTypes = {
  title: PropTypes.string,
};

export default NotActiveView;
